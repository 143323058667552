export default {
  school_logo: "Logo Sekolah",
  school_name: "Nama Sekolah",
  school_phone: "Telepon Sekolah",
  foundation: "Yayasan",
  school_address: "Alamat Sekolah",
  twitter: "https://twitter.com/namaSekolah",
  youtube: "https://youtube.com/namaChannel",
  facebook: "https://facebook.com/namaSekolah",
  instagram: "https://instagram.com/namaSekolah",
  msg_url: "URL tidak valid"
};
