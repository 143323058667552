<template>
  <div>
    <!-- ################# Sidebar ################# -->
    <ChangePassword />
    <v-navigation-drawer
      v-model="sidebar"
      :mini-variant="sidebar_mini && $vuetify.breakpoint.mdAndUp"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :class="{ 'grey lighten-4': !g_darkmode }"
      mini-variant-width="65"
      app
    >
      <template v-slot:prepend>
        <v-list class="pa-0">
          <v-list-item id="logout-wraper" style="height: 63px" @click="router">
            <v-list-item-icon>
              <v-img
                :src="g_school.about.path_potrait"
                max-height="100%"
                max-width="30.9px"
              ></v-img>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-bold">
              {{ g_school.about.name }}
            </v-list-item-title>
          </v-list-item>
          <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
          <v-list-item
            v-if="$vuetify.breakpoint.xsOnly"
            class="justify-space-around"
            :class="{ white: !g_darkmode }"
          >
            <!-- <v-btn small color="primary" class="elevation-0">
              <v-icon>mdi-comment-question-outline</v-icon>
            </v-btn>
            <v-btn small color="primary" class="elevation-0">
              <v-icon>mdi-map-marker</v-icon>
            </v-btn> -->
            <v-btn small color="primary" class="elevation-0">
              <v-icon @click="$router.push('/homepage')">mdi-home</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item-group v-model="activeMenu">
          <div v-for="(menu, i) in g_route_async" :key="i">
            <Item
              v-if="!menu.hidden && hasOneChild(menu)"
              :sidebarMini="checkSidebarMini"
              :path="getPathItem(menu)"
              :title="menu.children[0].meta.title"
              :icon="menu.meta.icon"
              :g_darkmode="g_darkmode"
            />
            <ItemGroup
              v-if="!menu.hidden && !hasOneChild(menu)"
              :sidebarMini="checkSidebarMini"
              :sidebarMiniFlag="sidebar_mini"
              :path="menu.path"
              :title="menu.meta.title"
              :icon="menu.meta.icon"
              :child="menu.children"
              :g_darkmode="g_darkmode"
            />
          </div>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <v-card outlined style="border-radius: 0px !important">
          <v-row
            class="py-4 caption"
            :class="sidebar_mini ? '' : 'px-4'"
            no-gutters
            align="center"
            :justify="sidebar_mini ? 'center' : 'end'"
          >
            <div class="body-2">
              {{ g_version.frontend }} | {{ g_version.backend }}
            </div>
          </v-row>
        </v-card>
      </template>
    </v-navigation-drawer>
    <!-- ################# Sidebar ################# -->

    <!-- ################# App Bar ################# -->
    <v-app-bar
      class="elevation-0"
      :class="!g_darkmode && 'gradient-primary'"
      height="63px"
      dark
      app
      dense
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndUp"
        @click.stop="sidebar_mini = !sidebar_mini"
      />
      <v-app-bar-nav-icon v-else @click.stop="sidebar = !sidebar" />

      <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp" class="pl-0">
        <!-- <v-btn text small>{{ $t("app.qna") }}</v-btn>
        <v-btn text small>{{ $t("app.contact") }}</v-btn> -->
        <v-btn text small @click="$router.push({ name: 'Homepage' })">{{
          $t("app.homepage")
        }}</v-btn>
      </v-toolbar-title>

      <v-spacer />

      <v-tooltip v-if="isMobile" bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-icon v-if="g_latency <= 100" color="lime accent-3"
              >mdi-signal-cellular-3</v-icon
            >
            <v-icon
              v-else-if="g_latency >= 100 && g_latency <= 150"
              color="orange darken-3"
              >mdi-signal-cellular-2</v-icon
            >
            <v-icon v-else-if="g_latency >= 150" color="red accent-4"
              >mdi-signal-cellular-1</v-icon
            >
          </div>
        </template>
        <span class="caption"> {{ networkQuality }} | {{ g_latency }}ms </span>
      </v-tooltip>

      <div v-else>
        <span class="mr-1">
          <v-icon v-if="g_latency <= 100" color="lime accent-3"
            >mdi-signal-cellular-3</v-icon
          >
          <v-icon
            v-else-if="g_latency >= 100 && g_latency <= 150"
            color="orange darken-3"
            >mdi-signal-cellular-2</v-icon
          >
          <v-icon v-else-if="g_latency >= 150" color="red accent-4"
            >mdi-signal-cellular-1</v-icon
          >
        </span>
        <span class="caption"> {{ networkQuality }} ({{ g_latency }}ms) </span>
      </div>

      <v-row
        no-gutters
        class="logout-wraper"
        justify="end"
        align="center"
        @click="router"
      >
        <v-avatar size="35">
          <v-img v-if="g_user.photo" :src="g_user.photo" />
          <v-icon v-else-if="!g_user.gender" size="36">
            mdi-account-circle
          </v-icon>
          <v-img v-else :src="g_user.gender == 'L' ? boyPhoto : girlPhoto" />
        </v-avatar>
        <template>
          <div
            class="subtitle-2 d-inline-block text-truncate logout-wraper pl-2 pr-4"
            style="max-width: 200px;"
          >
            <strong>{{ g_user.fullname }}</strong>
          </div>
          <div v-if="g_user.role" class="caption text-capitalize">
            {{ g_user.role }}
          </div>
        </template>
      </v-row>

      <!-- Messenger / Chat -->
      <v-btn v-if="g_access.includes(39)" icon @click="goToMessenger">
        <v-badge
          v-if="g_user.all_unread_messages > 0"
          overlap
          color="green"
          :content="g_user.all_unread_messages"
        >
          <v-icon>mdi-message-text</v-icon>
        </v-badge>
        <v-icon v-else>mdi-message-text</v-icon>
      </v-btn>
      <!-- Messenger / Chat -->

      <!-- Notification -->
      <Notification />
      <!-- Notification -->

      <!-- Setting -->
      <v-menu bottom left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="mx-1" icon
            ><v-icon>mdi-settings</v-icon></v-btn
          >
        </template>

        <v-list dense class="elevation-0">
          <v-list-item-group>
            <v-list-item @click.stop.prevent>
              <v-icon class="pr-2" color="primary">mdi-translate</v-icon>
              <v-select
                v-model="currentLang"
                :items="listLang"
                item-text="label"
                item-value="val"
                hide-details
                outlined
                dense
              ></v-select>
            </v-list-item>
            <v-list-item @click="$store.commit('SET_DARKMODE')">
              <v-icon class="pr-2" color="primary">mdi-brightness-3</v-icon>
              <span class="pr-4">{{ $t("app.darkmode") }}</span>
              <v-switch
                :value="g_darkmode"
                hide-details
                dense
                class="mt-0"
              ></v-switch>
            </v-list-item>
            <v-list-item
              style="cursor: pointer"
              @click="$store.commit('TOGGLE_CHANGE_PASSWORD')"
            >
              <v-icon class="pr-2 mdi-rotate-315" color="primary"
                >mdi-key</v-icon
              >
              {{ $t("app.change_password") }}
            </v-list-item>
            <v-list-item style="cursor: pointer" @click="logoutHandler()">
              <v-icon class="pr-2" color="primary">mdi-logout</v-icon>
              {{ $t("app.logout") }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Setting -->
    <!-- ################# App Bar ################# -->

    <!-- ################# Main Route ################# -->
    <v-main style="position: relative">
      <router-view />
    </v-main>
    <!-- ################# Main Route ################# -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isExternal } from "@/utils/validate";
import ChangePassword from "@/components/ChangePassword";
import { api_logout } from "@/api/login";
import Item from "./Item";
import ItemGroup from "./ItemGroup";
import Notification from "./Notification";
// import { VueIdentifyNetwork } from "vue-identify-network";

export default {
  name: "Sidebar",
  components: {
    ChangePassword,
    Item,
    ItemGroup,
    Notification
    // VueIdentifyNetwork
  },
  props: {
    source: String
  },
  data() {
    this.onlyOneChild = null;
    return {
      listLang: [
        {
          val: "id",
          label: "Indonesia"
        },
        {
          val: "en",
          label: "English"
        }
      ],
      sidebar: false,
      sidebar_mini: false,
      mini: true,
      activeMenu: 0,
      notif: 6,
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      speed: 0
    };
  },
  computed: {
    ...mapGetters([
      "g_route_async",
      "g_version",
      "g_school",
      "g_user",
      "g_roles",
      "g_user_id",
      "g_darkmode",
      "g_language",
      "g_access",
      "g_is_duhan",
      "g_latency"
    ]),
    currentLang: {
      get() {
        return this.g_language;
      },
      set(param) {
        this.$store.dispatch("setLanguage", param);
      }
    },
    networkQuality() {
      let result;
      if (this.g_latency <= 100) result = this.$i18n.t("app.good_signal");
      else if (this.g_latency >= 100 && this.g_latency <= 150)
        result = this.$i18n.t("app.not_good_signal");
      else result = this.$i18n.t("app.bad_signal");
      return result;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  watch: {
    g_darkmode(newVal) {
      this.$vuetify.theme.dark = newVal;
    }
  },
  mounted() {
    this.$socket.open();
  },
  methods: {
    // showChatting: () => this.g_access.includes(29),
    goToMessenger() {
      if (this.$route.name !== "Messenger") {
        this.$router.push("/messenger");
      }
    },
    router() {
      if (this.$route.path !== "/profile") this.$router.push("/profile");
    },
    getPathItem(menu) {
      return menu.children[0].path === "dashboard"
        ? menu.path + menu.children[0].path
        : menu.path + "/" + menu.children[0].path;
    },
    applicantUser() {
      return this.g_roles.includes(1) || this.g_roles.includes(2);
    },
    checkSidebarMini() {
      if (this.sidebar_mini) this.sidebar_mini = !this.sidebar_mini;
    },
    hasOneChild(menu) {
      if (menu.children && menu.children.length === 1) {
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      return routePath;
    },
    logoutHandler() {
      if (this.g_is_duhan) {
        this.$store.dispatch("LogOut").then(() => {
          location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
        });
      } else {
        api_logout(this.g_user_id).then(res => {
          if (res.data) {
            if (res.data.code) {
              this.$store.dispatch("LogOut").then(() => {
                this.$socket.disconnect();
                location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
              });
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@mixin active-sidebar() {
  background: white;
  color: black !important;
  box-shadow: 2px 3px 5px #ececec;
}

$checkClass: false;

.logout-wraper {
  cursor: pointer;
}
.icon-sidebar {
  max-width: 24px;
}
.list-bg-sidebar {
  color: black;
}

.v-list-group__header {
  @if $checkClass {
    @include active-sidebar;
  }
}

.v-list-item-group {
  .list-bg-sidebar {
    &.v-list-item--active {
      @include active-sidebar;
    }
  }
  .theme--light {
    &.v-list-item--active:before {
      opacity: 0;
    }
  }
  .v-list-item--active:before {
    opacity: 0;
  }
}
</style>
