import app from "./modules_id/app";
import routes from "./modules_id/routes";
import ppdb from "./modules_id/ppdb";
import login from "./modules_id/login";
import homepage from "./modules_id/homepage";
import components from "./modules_id/components";

// Master Data
import school_year from "./modules_id/admin/academic/school_year";
import master_data from "./modules_id/admin/master_data";
import basic_competencies from "./modules_id/admin/academic/basic_competencies";
import core_competencies from "./modules_id/admin/academic/core_competencies";
import score_rating from "./modules_id/admin/score_rating";
import major from "./modules_id/admin/major";

// Academic
import academic from "./modules_id/admin/academic";
import journal from "./modules_id/admin/academic/journal";
import bk from "./modules_id/admin/academic/bk";
import class_list from "./modules_id/admin/academic/class_list";
import subjects from "./modules_id/admin/academic/subjects";
import schedule from "./modules_id/admin/academic/schedule";
import e_test from "./modules_id/admin/academic/eTest";
import elearning from "./modules_id/admin/academic/elearning";
import score from "./modules_id/admin/academic/score";
import extracuricular from "./modules_id/admin/academic/extracuricular";
import silabus_rpp from "./modules_id/admin/academic/silabus_rpp";
import report_card from "./modules_id/admin/academic/report_card";

// Absent
import absent from "./modules_id/admin/absent/absent";
import personalization from "./modules_id/admin/absent/personalization";
import recap_absent from "./modules_id/admin/absent/recap_absent";
import set_time from "./modules_id/admin/absent/set_time";
import weekly from "./modules_id/admin/absent/weekly";
import permission from "./modules_id/admin/absent/permission";

// Information
import information from "./modules_id/admin/information";
import announcement from "./modules_id/admin/information/announcement";
import news from "./modules_id/admin/information/news";

// Setting
import aboutSchool from "./modules_id/admin/settings/aboutSchool";
import registrationNumber from "./modules_id/admin/settings/registrationNumber";
import imageSlider from "./modules_id/admin/settings/imageSlider";
import userLogin from "./modules_id/admin/settings/userLogin";
import gos from "./modules_id/admin/settings/groupOfSubject";
import format_email from "./modules_id/admin/settings/formatEmail";

// Dashboard
import dashboard from "./modules_id/admin/dashboard";

// Recap
import absencePerSubject from "./modules_id/admin/recap/absencePerSubject";

// Transaction
import dues from "./modules_id/admin/transaction/dues";

const id = {
  app,
  routes,
  ppdb,
  login,
  homepage,
  components,
  // ---------------- Master Data
  school_year,
  master_data,
  basic_competencies,
  core_competencies,
  score_rating,
  major,
  // ---------------- Academic
  academic,
  journal,
  bk,
  class_list,
  subjects,
  schedule,
  e_test,
  elearning,
  score,
  extracuricular,
  silabus_rpp,
  report_card,
  // ---------------- Absent
  absent,
  personalization,
  recap_absent,
  set_time,
  weekly,
  permission,
  // ---------------- information
  information,
  announcement,
  news,
  // ---------------- Settings
  aboutSchool,
  registrationNumber,
  imageSlider,
  userLogin,
  gos,
  format_email,
  // ---------------- Settings
  dashboard,
  // ---------------- Recap
  absencePerSubject,
  // ---------------- Transaction
  dues
};

export default id;
