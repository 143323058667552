import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const MasterData = {
  path: "/master",
  component: Admin,
  redirect: "student",
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.master"),
    icon: "sidebar-icon-master-data.svg",
    roles: [7, 26, 6, 4, 5, 8, 40, 12, 13, 14, 19, 58, 60]
  },
  children: [
    {
      name: "SchoolYear",
      path: "school_year",
      component: () => import("@/views/admin/master/schoolYear"),
      meta: { roles: [7], title: i18n.t("routes.school_year") }
    },
    {
      name: "ProfilingStudent",
      path: "/profiling-student",
      component: () => import("@/views/admin/master/student/ProfilingStudent"),
      hidden: true,
      meta: {
        roles: [26]
      }
    },
    {
      name: "Position",
      path: "position",
      component: () => import("@/views/admin/master/position"),
      meta: { roles: [6], title: i18n.t("routes.position") }
    },
    {
      name: "Teacher",
      path: "teacher",
      component: () => import("@/views/admin/master/teacher"),
      meta: { roles: [4], title: i18n.t("routes.teacher") }
    },
    {
      name: "Staff",
      path: "staff",
      component: () => import("@/views/admin/master/staff"),
      meta: { roles: [5], title: i18n.t("routes.staff") }
    },
    {
      name: "ClassList",
      path: "class_list",
      component: () => import("@/views/admin/master/classList"),
      meta: { roles: [8], title: i18n.t("routes.class_list") }
    },
    {
      name: "SetTime",
      path: "set_time",
      component: () => import("@/views/admin/absent/setTime"),
      meta: { roles: [19], title: i18n.t("routes.set_time") }
    },
    {
      name: "Student",
      path: "student",
      component: () => import("@/views/admin/master/student"),
      meta: {
        roles: [40],
        title: i18n.t("routes.student")
      }
    },
    {
      name: "StudentManual",
      path: "student-manual",
      hidden: true,
      component: () => import("@/views/admin/master/student/AddStudent"),
      meta: { roles: [40] }
    },
    {
      name: "UploadMasterExcel",
      path: "upload-master-data",
      hidden: true,
      component: () =>
        import("@/views/admin/master/components/UploadMasterExcel"),
      meta: { roles: [40] }
    },
    {
      name: "AddTeacherManual",
      path: "teacher-manual",
      hidden: true,
      component: () => import("@/views/admin/master/components/AddManual"),
      meta: { roles: [4] },
      props: { isTeacher: true }
    },
    {
      name: "AddStaffManual",
      path: "staff-manual",
      hidden: true,
      component: () => import("@/views/admin/master/components/AddManual"),
      meta: { roles: [5] },
      props: { isTeacher: false }
    },
    {
      name: "Subjects",
      path: "subjects",
      component: () => import("@/views/admin/master/subjects"),
      meta: { roles: [12], title: i18n.t("routes.subjects") }
    },
    {
      name: "CoreCompetencies",
      path: "core-competencies",
      component: () => import("@/views/admin/master/coreCompetencies"),
      meta: { roles: [13], title: i18n.t("routes.core_competencies") }
    },
    {
      name: "CoreCompetenciesGlobal",
      path: "core-competencies-global",
      hidden: true,
      component: () =>
        import("@/views/admin/master/coreCompetencies/CoreCompetenciesGlobal"),
      meta: { roles: [13], title: i18n.t("routes.core_competencies") }
    },
    {
      name: "BasicCompetencies",
      path: "basic-competencies",
      component: () => import("@/views/admin/master/basicCompetencies"),
      meta: { roles: [14], title: i18n.t("routes.basic_competencies") }
    },
    {
      name: "scoreRating",
      path: "score-rating",
      component: () => import("@/views/admin/master/scoreRating"),
      meta: { roles: [58], title: i18n.t("routes.score_rating") }
    },
    {
      name: "GOS",
      path: "group-of-subjects",
      component: () => import("@/views/admin/master/groupOfSubject"),
      meta: {
        roles: [60],
        title: i18n.t("routes.gos")
      }
    },
    {
      name: "Major",
      path: "major",
      component: () => import("@/views/admin/master/major"),
      meta: {
        roles: [60],
        title: i18n.t("routes.major")
      }
    }
  ]
};

export default MasterData;
