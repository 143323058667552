export default {
  subtitle: "Segera Bergabung dengan Kami",
  registration: "Pendaftaran",
  new_student: "Siswa Baru",
  teacher_staff: "Guru / Staff",
  profile: "Profile",
  purpose: "Tujuan",
  vision: "Visi",
  mission: "Misi",
  calendar: "Kalender",
  today_activity: "Kegiatan hari ini",
  announcement: "Pengumuman",
  announcement_sub: "Selalu Update Pengumuman yang terbaru",
  news: "Berita",
  news_sub: "Selalu Update Berita Terbaru Seputar Sekolah",
  posted_on_date: "Diposting pada tanggal ",
  no_description: "Tidak ada deskripsi",
  read: "Baca",
  view_more: "Lebih Banyak"
};
