import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import user from "./modules/user";
import permission from "./modules/permission";
import ppdbGuru from "./modules/homepage/ppdbGuru";
import ppdbSiswa from "./modules/homepage/ppdbSiswa";
import master from "./modules/admin/master/master";
import tahunAjaran from "./modules/admin/akademik/tahunAjaran";
import masterData from "./modules/masterData";
import classList from "./modules/admin/akademik/classList";
import schedule from "./modules/admin/akademik/schedule";
import stppa from "./modules/admin/akademik/stppa";
import subjects from "./modules/admin/akademik/subjects";
import admin from "./modules/admin/admin";
import extracurricular from "./modules/admin/akademik/extracurricular";
import messenger from "./modules/messenger";
import notification from "./modules/notification";

import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    ppdbGuru,
    ppdbSiswa,
    master,
    tahunAjaran,
    masterData,
    classList,
    schedule,
    stppa,
    subjects,
    admin,
    extracurricular,
    messenger,
    notification
  },
  getters
});

export default store;
