export default {
  choose_time: "Pilih Jam",
  choose_date: "Pilih Tanggal",
  showing: "Menampilkan",
  of: "dari",
  per_page: "Per halaman",
  zoom_in: "Perbesar",
  zoom_out: "Perkecil",
  rotate_left: "Putar kiri",
  rotate_right: "Putar kanan",
  slider_info1: "Scroll untuk memperbesar / memperkecil",
  slider_info2: "Seret untuk menggeser gambar"
};
