export default {
  generate: "Generate",
  clear: "Bersihkan",
  master_hour: "Master Jam",
  master_event: "Master Kegiatan",
  choose_grade: "Pilih Tingkatan",
  master_hour_subjects: "Master Jam Mata Pelajaran",
  master_event_subjects: "Master Jam Kegiatan Mata Pelajaran",
  duration_subjects: "Durasi Mata Pelajaran",
  addressed_to: "Ditujukan kepada",
  subjects: "Mata Pelajaran",
  available_subjects: "Mata Pelajaran Tersedia",
  day: "Hari",
  some: "Beberapa",
  event_name: "Nama Kegiatan",
  hour: "Jam",
  teacher: "Guru",
  from_hour: "Dari jam",
  view_lesson: "Lihat Jadwal",
  view_event: "Lihat Kegiatan",
  to_hour: "Sampai jam",
  msg_dialog_event: "Minimal pilih 1 hari untuk melanjutkan",
  msg_no_room_code: "Terjadi kesalahan, silahkan hubungi admin",
  response: {
    master_hour_subjects: {
      success_edited: "Berhasil ubah data"
    }
  },
  msg_response: {
    input_subjects: "Berhasil memasukan mata pelajaran ke jadwal",
    remove_subjects: "Berhasil mengeluarkan mata pelajaran dari jadwal",
    drag_subjects: "Berhasil memperbarui jadwal",
    success_master_event: "Berhasil memasukan kegiatan ke dalam jadwal",
    success_remove_event: "Berhasil mengeluarkan event dari jadwal",
    success_create_event: "Berhasil memasukan kegiatan ke dalam jadwal",
    success_generate_schedule: "Berhasil buat jadwal",
    success_clear_schedule: "Berhasil clear jadwal",
    success_update_email: "Berhasil update email",
    failed_input_vidcon_link: "Gagal input Link Video Conference",
    vidcon_link_invalid: "Link Video Conference tidak valid"
  },
  msg_merge_class: "Silahkan klik Buat video conference terlebih dahulu",
  close_tab_host:
    "Mohon klik tombol <b>End Meeting For All</b> untuk menyudahi pertemuan",
  close_tab_participant:
    "Mohon klik tombol <b>Leave Meeting</b> untuk menyudahi pertemuan",
  attendance_msg: "Absen Kehadiran Berhasil",
  happy_teaching: "Happy Teaching",
  feedback: "Umpan Balik",
  confirm_generate_title: "Apakah anda yakin ingin melanjutkan?",
  confirm_generate_content:
    "Membuat jadwal secara otomatis dan acak, dan membutuhkan waktu yang cukup lama tergantung dari banyaknya jam pelajaran. Mohon untuk tidak merefresh/reload halaman selama proses berlangsung.",
  confirm_clear_content:
    "Apakah anda yakin ingin membersihkan jadwal kelas {class} ?",
  info_email_dialog:
    "Jika anda tidak memperbarui email, anda tidak bisa menjadi moderator video conference",
  update_email: "Perbarui Email",
  create_video_conference: "Buat video conference",
  start_video_conference: "Mulai Video Conference",
  confirm_force_schedule: ", apakah yakin akan tetap melanjutkan?",
  info_merge_class: "Pilih kelas di bawah, jika ada kelas yang ingin di gabung",
  create_room_information:
    "Cara membatalkan video conference:<br />1. Klik tombol <b>'Mulai video conference'</b><br />2. Klik tombol <b>'End' </b> di kanan bawah<br />3. Klik tombol <b>'End meeting for all'</b><br /><br />atau jika tidak dipakai, biarkan saja nanti room akan terhapus secara otomatis",
  same_hours: "Jam yang sama"
};
