export default {
  section1: "Satu Aplikasi dengan Banyak Fungsi",
  title_a: "Selamat Datang Kembali",
  title_b: "di",
  username_rules: "Username harus di isi",
  password_rules: "Password harus di isi",
  password_rules_minlength: "Minimal 6 karakter",
  forgot_password: "Lupa password?",
  back_to_homepage: "Halaman Utama",
  reset_password: "Atur ulang password",
  reset_password_text:
    "Masukkan email anda dan kami akan mengirim anda sebuat tautan untuk memperbaharui password anda",
  reset_password_title: "Kesulitan untuk masuk?",
  success_send_email:
    "Permintaan perubahan password telah terkirim ke email anda",
  update_password_title: "Silahkan Tulis Password Baru Anda",
  save_password: "Simpan Password",
  update_password_title_success: "Password Baru Selesai",
  update_password_text_success:
    "Pembuatan Password baru selesai, harap tunggu untuk melanjutkan ..",
  sign_in_using: "Atau login menggunakan"
};
