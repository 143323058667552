export default {
  choose_month: "Choose Month",
  class: "Class",
  student_list: "Student List",
  class_list_title: "Class List",
  class_list_data: {
    class_master: "Class Master",
    total_students: "Total Students",
    class_name: "Class Name",
    add_class_list: "Add Class List",
    choose_a_guardian_teacher: "Choose a Guardian Teacher",
    choose_a_counselor: "Choose a Counselor",
    select_the_active_day: "Select the active day",
    class_type: "Class Type",
    select_the_time_of_entry: "Select the time of entry",
    choose_home_hours: "Choose home hours"
  },
  schedule_title: "Schedule",
  schedule_data: {
    type_of_class: "Type of Class",
    choose_subjects: "Choose Subjects",
    add_schedule: "Add Schedule"
  },
  stppa_title: "STPPA",
  stppa_data: {
    age: "Age",
    month: "Month",
    year: "Year",
    master_of_development_programs: "Master of Development Programs",
    name_of_the_development_program: "Name of the Development Program",
    input_name: "Input Name",
    dialog_stppa_title: "Add Child Development Standards Achievement Levels",
    indicator: "indicator",
    form1: "Religious and Moral Values",
    form2: "Motor Value",
    form3: "Cognitive Value",
    form4: "Language Value",
    form5: "Emotional Social Value",
    form6: "Art Value"
  },
  subjects_title: "Subjects",
  master_subjects: "Master Subjects",
  subjects_data: {
    dialog_subjects: {
      tab1: {
        form1: "Enter Sub",
        form2: "Choose Teacher",
        form3: "Select School Year & Semester",
        form4: "Copy Existing Sub?"
      },
      tab2: {
        title: "Aspect"
      },
      tab3: {
        title: "Value Weight"
      }
    },
    dialog_master_subjects: {
      form1: "Subject Name",
      form2: "Choose Level",
      tiers: "Tiers"
    },
    add_subjects: "Add Subjects",
    teacher_name: "Teacher Name"
  }
};
