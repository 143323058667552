export default {
  msg: {
    success: {
      document: {
        add: "Successfully added Document",
        update: "Successfully update Document"
      },
      silabus: {
        add: "Successfully added Syllabus",
        update: "Successfully update Syllabus"
      },
      rpp: {
        add: "Successfully added RPP",
        update: "Successfully update RPP"
      }
    },
    error: {
      rule_upload: "Invalid file format"
    }
  },
  file_supported: "Files Supported",
  dialog_confirm_title: "File Confirmation",
  dialog_confirm_info:
    "The class below already has files, are you sure you want to overwrite the following files?"
};
