export default {
  add_core_competencies: "Tambah Kompetensi Inti",
  edit_core_competencies: "Ubah Kompetensi Inti",
  detail_core_competencies: "Detail Kompetensi Inti",
  choose_school_year: "Pilih Tahun Ajaran",
  choose_subject: "Pilih Mata Pelajaran",
  subject: "Nama Mata Pelajaran",
  sub_chapter: "Sub BAB",
  weighting: "Pembobotan",
  daily_tests: "Ulangan Harian",
  homework: "Pekerjaan Rumah",
  midterm_exam: "UTS",
  final_exams: "UAS",
  remove_selected_competencies: "Hapus Kompetensi Terpilih",
  max_weighting: "Jumlah pembobotan kurang dari 100%",
  max_weighting_uh: "jumlah nilai gabungan kurang dari 100%",
  choose_grade: "Pilih Tingkatan",
  description: "Deskripsi",
  ki: "Kompetensi inti",
  formula1: "Dibagi berkelompok",
  formula2: "Dibagi setara",
  total_formula1: "Total Ulangan Harian dan pekerjaan rumah",
  save_ki_delete_success: "Sukses menghapus kompetensi inti",
  save_ki_edit_success: "Sukses mengubah kompetensi inti",
  confirm_delete: "Apakah kamu yakin akan menghapus kompetensi inti ini?",
  global_weights: "Bobot Global",
  set_global_weights: "Atur Bobot",
  save_weight_success: "Sukses mengatur nilai pembobotan global",
  view_global_weights: "Lihat bobot tingkat",
  table: {
    subject: "Mata Pelajaran",
    total_competencies: "Jumlah Kompetensi",
    action: "Aksi",
    year: "Tahun",
    grade: "Tingkat"
  }
};
