export default {
  section1: "One Application with Many Functions",
  title_a: "Welcome back",
  title_b: "on",
  username_rules: "Username is required",
  password_rules: "Password is required",
  password_rules_minlength: "Min 6 characters",
  forgot_password: "Forgot Password?",
  back_to_homepage: "Homepage",
  reset_password: "Reset Password",
  reset_password_text:
    "Enter your email and we will send you a link to update your password",
  reset_password_title: "difficulty getting in?",
  success_send_email: "Password change request has been sent to your email",
  update_password_title: "Please enter your new password",
  save_password: "Save Password",
  update_password_title_success: "New Password Completed",
  update_password_text_success:
    "Create a new password complete, please wait to continue",
  sign_in_using: "Or Sign In Using"
};
