export default {
  dialog_email: "Are you sure you want to send an email?",
  select_position: "Select Position",
  input_position: "Input Position",
  add_mutation_student: "Add Student Mutation",
  add_mutation_teacher: "Add Teacher Mutation",
  add_mutation_staff: "Add Staff Mutation",
  mutation: "Mutation",
  detail: "Detail",
  cancel_mutation: "Are you sure you want to cancel this data mutation?",
  delete_mutation_confirm:
    "Are you sure you want to delete this data mutation?",
  delete_mutation: "Delete Mutation",
  success_cancel_mutation: "Successful cancel mutation",
  success_mutation: "Successful mutation data",
  student_data: "Student Data",
  download_student_detail: "Download student detail",
  download_student_applicant_detail: "Download student applicant detail",
  download_staff_applicant_detail: "Download staff applicant detail",
  download_teacher_applicant_detail: "Download teacher applicant detail",
  permanent_teacher: "Permanent Teacher",
  committee_honor: "Committee Honor",
  gtt_honor: "GTT Honor",
  foundation_permanent_teacher: "Foundation Permanent Teacher",
  position: {
    headmaster: "Headmaster",
    teacher: "Teacher Attitude Score",
    treasurer: "Treasures",
    counselor: "Counselor",
    add_position: "Add Position",
    edit_position: "Edit Position",
    table_position: "Position",
    confirm_update_content:
      "Are you sure you want to change the job title and access menu?",
    response: {
      success_add_position: "Successful add position",
      success_edit_position: "Successful edit position data",
      success_delete_position: "Successful delete position data"
    }
  },
  table: {
    name: "Name",
    date: "Date of Mutation",
    description: "Description",
    uid: "Card No",
    class: "Class",
    phone: "Phone",
    email: "Email",
    guardian: "Homeroom",
    nis: "Student ID Number",
    detail: "Detail",
    position: "Position",
    registration_number: "Registration Number",
    date_register: "Date Register",
    received_on: "Received on",
    reject_on: "Reject on",
    send_email: "Send Email"
  },
  teacher: {
    tab1_title: "Teacher",
    tab2_title: "Mutation",
    tab3_title: "Teacher Candidate",
    dialog: {
      content_1: "Are you sure you accept this teacher?",
      content_2: "Are you sure you don't accept this teacher?",
      content_3: "Are you sure you cancel this status?"
    },
    dialog_1: {
      title: "Add Teacher",
      content_1: "Add Teacher Manually",
      content_2: "Upload Excel Format Teacher Data"
    },
    mutation: "Mutation",
    change_type: "Change Type",
    response: {
      success_accept_teacher: "Successfully received teacher",
      success_reject_teacher: "The teacher has been rejected",
      success_unprocessed: "Successfully changed the status to unprocessed"
    }
  },
  staff: {
    tab1_title: "Staff",
    tab2_title: "Mutation",
    tab3_title: "Staff Candidate",
    input_status: "Input Status",
    input_nip: "Input Employee ID",
    select_status: "Select Status",
    select_nip: "Select employee ID",
    dialog: {
      content_1: "Are you sure you accept this staff?",
      content_2: "Are you sure you don't accept this staff?",
      content_3: "Are you sure you cancel this status?"
    },
    dialog_1: {
      title: "Add Staff",
      content_1: "Add Staff Manually",
      content_2: "Upload Excel Format Staff Data"
    },
    response: {
      success_accept_staff: "Successfully received staff",
      success_reject_staff: "The staff has been rejected",
      success_unprocessed: "Successfully changed the status to unprocessed"
    }
  },
  student: {
    change_password: "Change password",
    next_year_required: "Next school year does not exist",
    isAccepted: "Student Data Accepted",
    isUnrejected: "Student Data Unprocessed",
    isRejected: "Student Data Rejected",
    input_nis: "Input Student ID",
    school_year: "School Year",
    student: "Student",
    action: "Action",
    accept: "Accept",
    decline: "Decline",
    unprocessed: "Unprocessed",
    accepted: "Accepted",
    not_accepted: "Not Accepted",
    go_to_class: "Go to Class",
    edit_student: "Edit Student",
    move_class: "Move Class",
    mutation: "Mutation Student",
    upgrade: "Promoted",
    graduate: "Student Graduate",
    downgrade: "Stay in grade",
    cancel_mutation: "Cancel mutation",
    cancel_selected_mutation: "Cancel selected mutation",
    delete_selected_mutation: "Delete the selected mutation",
    tab1_title: "Students",
    type: "Type",
    nomor: "Student ID",
    profile_photo: "Profile Photo",
    value_indicator: "Value Indicator",
    average: "Average",
    class_avarage: "Class Avarage",
    last_point: "Last Point",
    value_grafic: "Value Chart",
    select_lesson: "Select Lesson",
    my_avarage: "My Average",
    no_class: "Classes for the next school year haven't been created yet",
    homeroom_teacher: "Homeroom Teacher",
    teaching_class: "Teaching Class",
    indicator_table: {
      very_good: "Very Good",
      good: "Good",
      average: "Average",
      less: "Less",
      very_less: "Very Less"
    },
    cart: {
      student_average_value: "My Average Value",
      student_average_class: "Student Average Class",
      average_value: "Average Value",
      best: "Best",
      bad: "Less",
      above_average: "Above",
      below_average: "Below",
      student_value: "Student Value",
      versus: "Student Average VS Class Average"
    },
    tab1_data: {
      choose_class: "Choose Class",
      name: "Name",
      phone_student: "Phone Number",
      guardian: "Guardian",
      card_number: "Card Number"
    },
    tab2_title: "Mutation",
    tab3_title: "Student Candidate",
    tab3_data: {
      added_students: "Add Student"
    },
    dialog: {
      title_1: "Confirmation",
      content_1: "Are you sure you accept this student?",
      content_2: "Are you sure you don't accept this student?",
      content_3: "Are you sure you cancel this status?"
    },
    dialog_1: {
      title: "Add Student",
      content_1: "Add Student Manually",
      content_2: "Upload Excel Format Student Data"
    },
    dialog_2: {
      title: "Confirm Class Entry"
    },
    response: {
      success_add_student: "Successful add student",
      success_edit_student: "Successful edit student data",
      success_mutation: "Successful student mutation",
      success_cancel_mutation: "Successful cancel student mutation",
      success_delete_mutation: "Successfully deleted mutations",
      success_action: "Successful change student data",
      success_upload: "Successfully uploaded student data",
      success_accept_student: "Successfully received student",
      success_reject_student: "The student has been rejected",
      success_unprocessed: "Successfully changed the status to unprocessed",
      success_entry_class: "Students successfully entered the classroom",
      done_upload_excel: "Done uploaded data from Excel"
    },
    actions: {
      select_grade_from: "From Grade",
      select_grade_to: "To Grade",
      select_class_from: "From Class",
      select_class_to: "To Class"
    },
    upload_excel: {
      row: "Row",
      name: "Name",
      pob: "Place of Birth",
      dob: "Date of Birth",
      add_format_excel: "Add Format Excel",
      upload_excel_file: "Upload Excel File",
      upload_image: "Upload Image",
      error_mandatory:
        "Mandatory data has not been filled in please check the excel file",
      error_response: "Data upload failed!",
      msg: "Reasons for Failure",
      column: "column",
      error_max_data: "Upload data up to 1000 students",
      filename: "Filename"
    },
    note_major:
      "Note: (*) is the priority major that students are most interested in",
    choose_major: "Choose the appropriate major",
    filter_major: "Check if just looking for majors",
    select_major: "Select Major",
    main_major: "Main Major",
    majors: "Majors",
    require_select_major: "Please select a major first"
  }
};
