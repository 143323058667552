export default {
  add_violation_max_point: "Add Violation Max Point",
  max_point: "Max Point",
  add_category: "Add Category",
  category: "Category",
  add_point: "Add Point",
  update_point: "Update Point",
  violation: "Violation",
  date: "Date",
  desc: "Description",
  save_draft: "Save Draft",
  violation_max_point: "Violation Max Point",
  bk_notes: "Counseling Guidance Notes",
  notes: "Notes",
  name: "Name",
  registration_number: "Registration Number",
  this_month_award: "This Month Point Award",
  this_month_violation: "This Month Point Violation",
  violant: "Violant",
  delete_points: "Delete Points",
  award: "Award",
  total_point: "Point Total",
  add_counseling_guidance: "Add Counseling Guidance",
  detail_counseling_guidance: "Counseling Guidance Detail",
  already_published: "Published",
  not_yet_published: "Draft",
  publish: "Publish",
  unpublish: "Unpublish",
  table: {
    desc: "Description",
    type: "Type",
    name: "Name",
    phone: "Phone",
    award: "Award",
    award_points: "Award Point",
    violation: "Violation",
    violation_points: "Violation Point",
    status: "Status",
    date: "Date",
    end_date: "End Date",
    teacher_name: "Teacher Name",
    point: "Point",
    description: "Description"
  },
  msg: {
    publish: "Successfully published",
    unpublish: "Successfully canceled publishing",
    delete_point: "Successfully deleted point",
    added_point: "Successfully added point",
    max_point: "Successfully added maximum points",
    added_conseling: "Successfully added counseling guidance notes",
    delete: "Successfully deleted "
  }
};
