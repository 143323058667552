export default {
  add_basic_competencies: "Tambah Kompetensi Dasar",
  edit_basic_competencies: "Ubah Kompetensi Dasar",
  detail_basic_competencies: "Detail Kompetensi Dasar",
  choose_subject: "Pilih KI Mata Pelajaran",
  basic_competencies: "Kompetensi Dasar",
  weighting: "Pembobotan",
  chapter: "BAB / KD",
  enter_the_chapter_name: "Tulis Nama BAB",
  sub_chapter: "Sub BAB",
  daily_tests: "Ulangan Harian",
  homework: "Pekerjaan Rumah",
  midterm_exam: "UTS",
  final_exams: "UAS",
  choose_grade: "Pilih Tingkatan",
  enter_the_sub_chapter_name: "Tulis Nama Sub BAB",
  no_ki_created: "Kompetensi dasar belum dibuat",
  save_ki_delete_success: "Sukses menghapus kompetensi dasar",
  remove_selected_competencies: "Hapus Kompetensi Terpilih",
  save_ki_edit_success: "Sukses mengubah kompetensi dasar",
  confirm_delete: "Apakah kamu yakin akan menghapus kompetensi dasar ini?",
  table: {
    subject: "Mata Pelajaran",
    total_competencies: "Jumlah Kompetensi",
    action: "Aksi",
    year: "Tahun",
    grade: "Kelas"
  }
};
