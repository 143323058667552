import request from "@/utils/request";

export function getListNotification(data) {
  return request({
    method: "post",
    url: "notification/get",
    data
  });
}

export function getListNotificationCount() {
  return request({
    method: "post",
    url: "notification/unread"
  });
}

export function readNotification(data) {
  console.log(data);
  return request({
    method: "post",
    url: "notification/read",
    data: data
  });
}
