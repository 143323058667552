const subjects = {
  state: {
    isDialogSubjects: false,
    isDialogMasterSubjects: false
  },
  mutations: {
    TOGGLE_DIALOG_SUBJECTS: state => {
      state.isDialogSubjects = !state.isDialogSubjects;
    },
    TOGGLE_DIALOG_MASTER_SUBJECTS: state => {
      state.isDialogMasterSubjects = !state.isDialogMasterSubjects;
    }
  }
};

export default subjects;
