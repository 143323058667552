export default {
  title: "Title",
  subtitle: "Slide Content",
  slider_upload: "Description",
  msg: {
    logo: "Logo is required",
    profile: "Profile is required",
    purpose: "Purpose is required",
    vision_img: "Vision Mision Image is required",
    vision: "Vision is required",
    mission: "Mission is required",
    slider_img: "Image is required"
  }
};
