export default {
  title_update: "Update Card Number",
  uid: "Card Number",
  input_card_number: "Input Card Number",
  old_uid: "Old Card number",
  edit_uid: "Edit Card number",
  uid_already_registered: "Card Number already Registered",
  all_type: "All Type",
  table: {
    nis: "Student ID",
    nip: "Employee ID",
    name: "Name",
    class: "Class Name",
    uid: "Card number",
    action: "action"
  },
  msg: {
    confirm_uid: {
      first: "Has Been Used by",
      second: "Are you sure you still want to change the card number",
      three: "be the number",
      four: "now?",
      five: "If yes, then you have to rewrite the card number",
      six: "too."
    },
    note:
      "Note: By Changing Card Number, Previous User Card Number Will Be Deleted"
  }
};
