import request from "@/utils/request";

export function getMaxGrade() {
  return request({
    method: "post",
    url: "class/max_grade"
  });
}

export function uploadFile(data) {
  return request({
    method: "post",
    url: "aws/upload",
    header: { "Content-Type": "multipart/form-data" },
    data: data
  });
}

export function uploadFileBase64(data) {
  return request({
    method: "post",
    url: "aws/upload_base64",
    data: data
  });
}

export function deleteFile(path, module) {
  return request({
    method: "post",
    url: "aws/delete",
    data: {
      path: path,
      module: module
    }
  });
}

export function update_password(data) {
  return request({
    method: "post",
    url: "user/update_my_password",
    data: data
  });
}

export function uploadVideo(data) {
  return request({
    method: "post",
    url: "aws/transcode_video",
    header: { "Content-Type": "multipart/form-data" },
    data: data
  });
}

export function updatePasswordByAdmin(data) {
  return request({
    method: "post",
    url: "user/update_password_by_admin",
    data: data
  });
}

export function getTeacherByLesson(data) {
  return request({
    method: "post",
    url: "/lesson/teachers_by_lesson",
    data: data
  });
}

export function resendEmail(data) {
  return request({
    method: "post",
    url: "mail/resend",
    data: data
  });
}
