export default {
  group: "Group",
  general: "General",
  add_gos: "Add Group of Subject",
  update_form: "Edit Group of Subject",
  create_form: "Add Group of Subject",
  success_update: "Success updating group of subject",
  success_create: "Success creating group of subject",
  delete_confirm: "Delete Group of Subject",
  move_group: "Move Group of Subject",
  select_gos: "Select group of subject",
  note: "The selected subjects will not appear on the report card",
  select_for_all_grade: "Select for all grade",
  table: {
    gos: "Group of Subject",
    name: "Group Name",
    subject: "Subjects",
    show: "Hide On Report Card"
  },
  form: {
    name_group_subject: "Name Group Subject"
  }
};
