import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const Settings = {
  path: "/settings",
  component: Admin,
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.settings"),
    icon: "sidebar-icon-settings.svg",
    roles: [30, 27, 28, 34, 67]
  },
  children: [
    // {
    //   name: "RegistrationNumber",
    //   path: "registration-number",
    //   component: () => import("@/views/admin/settings/registrationNumber"),
    //   meta: {
    //     roles: [29],
    //     title: i18n.t("routes.registration_number")
    //   }
    // },
    {
      name: "ImageSlider",
      path: "image-slider",
      component: () => import("@/views/admin/settings/imageSlider"),
      meta: {
        roles: [30],
        title: i18n.t("routes.image_slider")
      }
    },
    {
      name: "AboutSchool",
      path: "about-school",
      component: () => import("@/views/admin/settings/aboutSchool"),
      meta: {
        roles: [27],
        title: i18n.t("routes.about_school")
      }
    },
    {
      name: "MainInfo",
      path: "main-info",
      component: () => import("@/views/admin/settings/mainInfo"),
      meta: {
        roles: [28],
        title: i18n.t("routes.main_info")
      }
    },
    {
      name: "UsersSetting",
      path: "user",
      component: () => import("@/views/admin/settings/user"),
      meta: {
        roles: [34],
        title: i18n.t("routes.user_access")
      }
    },
    {
      name: "UserLogin",
      path: "user-login",
      component: () => import("@/views/admin/settings/userLogin"),
      meta: {
        roles: [34],
        title: "User Login"
      }
    },
    {
      name: "FormatEmail",
      path: "format-email",
      component: () => import("@/views/admin/settings/formatEmail"),
      meta: {
        roles: [67],
        title: "Format Email"
      }
    }
  ]
};

export default Settings;
