export default {
  add_violation_max_point: "Tambah Maksimal Poin Pelanggaran",
  max_point: "Poin Maksimal",
  add_category: "Tambah Kategori",
  category: "Kategori",
  add_point: "Tambah Point",
  update_point: "Ubah Poin",
  violation: "Pelanggaran",
  date: "Tanggal",
  desc: "Deskripsi",
  save_draft: "Simpan Draf",
  violation_max_point: "Maksimal Poin Pelanggaran",
  bk_notes: "Catatan BK",
  notes: "Catatan",
  name: "Nama",
  registration_number: "No. Induk",
  this_month_award: "Poin Penghargaan Bulan ini",
  this_month_violation: "Poin Pelanggaran Bulan ini",
  violant: "Pelanggaran",
  award: "Penghargaan",
  total_point: "Total Poin",
  delete_points: "Hapus Poin",
  add_counseling_guidance: "Tambah Catatan BK",
  detail_counseling_guidance: "Detail Catatan BK",
  already_published: "Diterbitkan",
  not_yet_published: "Draf",
  publish: "Terbitkan",
  unpublish: "Batal Terbitkan",
  table: {
    desc: "Deskripsi",
    type: "Tipe",
    name: "Nama",
    phone: "Telepon",
    award: "Penghargaan",
    award_points: "Poin Penghargaan",
    violation: "Pelanggaran",
    violation_points: "Poin Pelanggaran",
    status: "Status",
    date: "Tanggal",
    end_date: "Tanggal Berakhir",
    teacher_name: "Nama Guru",
    point: "Poin",
    description: "Deskripsi"
  },
  msg: {
    publish: "Berhasil menerbitkan",
    unpublish: "Berhasil membatalkan penerbitan",
    delete_point: "Berhasil hapus point",
    added_point: "Berhasil tambah point",
    max_point: "Berhasil tambah maksimal point",
    added_conseling: "Berhasil tambah catatan BK",
    delete: "Berhasil menghapus "
  }
};
