<template>
  <v-dialog
    v-model="modalPendaftaran"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="text-center" outlined>
      <v-row
        justify="center"
        align="center"
        no-gutters
        style="min-height: 100vh"
      >
        <div>
          <img
            class="sistesi-logo my-8"
            src="@/assets/svg/sistesi-with-name.svg"
          />

          <v-row justify="center" no-gutters>
            <v-card
              class="elevation-0 mb-8 rounded-xl"
              width="100%"
              :min-width="$vuetify.breakpoint.xsOnly ? '350px' : '420px'"
            >
              <v-list-item class="gradient-primary-dark text-center" dark>
                <v-list-item-content>
                  <v-list-item-title class="headline my-3">
                    {{ $t("app.register_as") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <v-btn
                  large
                  block
                  dark
                  class="rounded-lg gradient-primary elevation-0"
                  @click="directTo('student')"
                  >{{ $t("app.student") }}</v-btn
                >
                <v-btn
                  large
                  block
                  dark
                  class="my-4 rounded-lg gradient-primary elevation-0"
                  @click="directTo('teacher')"
                  >{{ $t("app.teacher") }} / {{ $t("app.staff") }}</v-btn
                >
                <v-btn large block text @click="modalPendaftaranHandler">{{
                  $t("app.back")
                }}</v-btn>
              </v-card-text>
            </v-card>
          </v-row>
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalPendaftaran: {
      type: Boolean
    },
    modalPendaftaranHandler: {
      type: Function
    }
  },
  methods: {
    directTo(param) {
      this.$router.push("/ppdb/" + param);
      this.modalPendaftaranHandler();
    }
  }
};
</script>
