const extracurricular = {
  state: {
    masterExtracurricular: [],
    listTeacher: [],
    masterPlace: [],
    masterGrade: []
  },
  mutations: {
    GET_MASTER_EXTRACURRICULAR: (state, data) => {
      state.extracurricular = data;
    },
    GET_LIST_TEACHER: (state, data) => {
      state.listTeacher = data;
    },
    GET_MASTER_PLACE: (state, data) => {
      state.masterPlace = data;
    },
    GET_MASTER_GRADE: (state, data) => {
      state.masterGrade = data;
    }
  }
};

export default extracurricular;
