import Vue from "vue";

Vue.directive("numericOnly", {
  bind(el) {
    el.addEventListener("keydown", () => {
      var inputDom = el
        .getElementsByClassName("v-text-field__slot")[0]
        .getElementsByTagName("input");

      if (inputDom) {
        let val = inputDom[0].value;
        let regex = /^[0-9]*$/;

        if (!regex.test(val)) {
          val = val.slice(0, -1);
        }
      }
    });
  }
});
