export default {
  rules: {
    address_rules: "Alamat harus di isi",
    address_valid_rules: "Alamat tidak valid",
    fullname_rules: "Nama Lengkap harus di isi",
    gender_rules: "Jenis Kelamin harus di isi",
    religion_rules: "Agama harus di isi",
    pob_rules: "Tempat Lahir harus di isi",
    nik_rules: "Nomor Induk Kependudukan harus di isi",
    nis_rules: "Nomor Induk Siswa harus di isi",
    nis_valid_rules: "Nomor Induk Siswa harus valid",
    dob_rules: "Tanggal Lahir harus di isi",
    guardian_name_rules: "Nama wali harus di isi",
    email_rules: "Email harus di isi",
    email_valid_rules: "Email tidak valid",
    phone_rules: "Telepon harus di isi",
    phone_valid_rules: "Telepon tidak valid",
    number_rules: "Input harus berupa angka",
    strata_rules: "Perguruan Tinggi harus di isi",
    origin_of_the_institution_rules: "Asal Institusi Pendidikan harus di isi",
    accreditation_rules: "Akreditas harus di isi",
    diploma_number_rules: "Nomor Ijazah harus di isi",
    company_name_rules: "Nama Perusahaan harus di isi",
    last_position_rules: "Posisi Terakhir harus di isi",
    year_of_entry_rules: "Tahun Masuk harus di isi",
    year_of_entry_valid_rules: "Tahun Masuk tidak valid",
    year_of_entry_greater_than_rules:
      "Tahun masuk tidak boleh lebih dari tahun lulus",
    graduation_year_rules: "Tahun Lulus harus di isi",
    graduation_year_valid_rules: "Tahun Lulus tidak valid",
    graduation_year_less_than_rules:
      "Tahun lulus tidak boleh kurang dari tahun masuk",
    year_out_rules: "Tahun Keluar harus di isi",
    year_out_less_than_rules:
      "Tahun keluar tidak boleh kurang dari tahun masuk",
    year_out_greater_than_rules:
      "Tahun keluar tidak boleh kurang dari tahun masuk",
    school_name_rules: "Nama Sekolah harus di isi",
    gpa_value_rules: "Nilai IPK harus di isi",
    gpa_value_valid_rules: "Nilai IPK tidak valid",
    upload_documents_rules: "Unggah Dokumen maksimal 5 file",
    upload_documents_type_rules: "Hanya boleh unggah file .png .jpg .pdf",
    nik_not_valid: "NIK tidak valid",
    nik_not_valid_length: "NIK harus 16 karakter",
    family_card_number_not_valid: "No. KK tidak valid",
    major_primary_rules: "Jurusan utama harus di isi"
  },
  personal: {
    pob: "Tempat Lahir",
    dob: "Tanggal Lahir",
    nationality: "Kewarganegaraan",
    last_education: "Pendidikan Terakhir",
    profession: "Pekerjaan",
    monthly_income: "Pendapatan Bulanan",
    phone_number: "Nomor Handphone",
    email: "Email",
    relation: "Hubungan dengan Anak",
    religion: "Agama",
    others_information: "Informasi lainnya",
    list_gender: [
      { id: "L", name: "Laki - Laki" },
      { id: "P", name: "Perempuan" }
    ]
  },
  address: {
    address: "Alamat",
    province: "Provinsi",
    city: "Kota / Kabupaten",
    sub_district: "Kecamatan",
    village: "Kelurahan",
    rt: "RT",
    rw: "RW",
    postal_code: "Kodepos",
    child_to: "Anak ke",
    telephone: "Telepon Rumah",
    number_of_siblings: "Jumlah Saudara",
    live_with: "Tinggal Bersama",
    country: "Negara"
  },
  student: {
    title: "Pendaftaran Siswa Baru",
    tab1: "Siswa",
    tab2: "Orang Tua",
    tab3: "Wali",
    tab4: "Pernyataan",
    personal_data_sub: "Data Diri",
    add_manual: "Tambah Siswa Manual",
    add_students_excel: "Tambah Siswa dengan Excel",
    personal_data: {
      fullname: "Nama Lengkap",
      nickname: "Nama Panggilan",
      gender: "Jenis Kelamin",
      language: "Bahasa Sehari-hari",
      nik: "Nomor Induk Kependudukan",
      family_card_number: "Nomor Kartu Keluarga"
    },
    major_sub: "Jurusan",
    choose_major: "Pilih Jurusan",
    preferred_major: "Jurusan Utama",
    optional_major: "Jurusan Opsional",
    notes_major: {
      notes: "Catatan :",
      first: "Pastikan Jurusan yang anda pilih sesuai dengan minat anda.",
      two:
        "Jurusan Opsional maksimal memilih 2,sebagai pilihan jika anda tidak diterima di jurusan Utama anda."
    },
    address_sub: "Alamat Siswa",
    family_data_sub: "Data Keluarga",
    family_data: {
      child_to: "Anak ke",
      child_name: "Nama Anak",
      semester: "Sekolah / bekerja",
      school: "Nama Sekolah / Nama Perusahaan",
      add: "Tambah"
    },
    health_data_sub: "Data Kesehatan",
    health_data: {
      weight: "Berat badan (kg)",
      height: "Tinggi Badan (cm)",
      blood: "Golongan Darah",
      medical_history: "Riwayat Kesehatan",
      distance: "Jarak Rumah Kesekolah",
      health_record: "Catatan Kesehatan"
    },
    previous_education_sub: "Pendidikan Sebelumnya",
    previous_education: {
      school_origin: "Asal Sekolah Sebelumnya",
      national_school_principal: "Nomor Pokok Sekolah Nasional",
      school_address: "Alamat Sekolah",
      national_school_parent: "Nomor Induk Siswa Nasional",
      year_graduated: "Tahun Lulus Jenjang Sebelumnya",
      diploma_number: "No. Ijazah (Jenjang Sebelumnya)"
    }
  },
  parents: {
    fathers_sub: "Data Ayah",
    fathers_address: "Alamat Ayah",
    mothers_sub: "Data Ibu",
    mothers_address: "Alamat Ibu",
    copy_address: "Tinggal serumah dengan Ayah",
    copy_address_student: "Salin dari alamat siswa",
    personal: {
      fathers_name: "Nama Ayah",
      mothers_name: "Nama Ibu"
    }
  },
  guardian: {
    question1: "Apakah Orang tua anda juga berperan sebagai wali anda?",
    question2: "Siapakah yang berperan sebagai wali anda",
    father: "Ayah",
    mother: "Ibu",
    guardian_data: "Data Wali Murid",
    guardian_address: "Alamat Wali",
    personal: {
      name: "Nama Wali"
    }
  },
  statement: {
    title: "SURAT PERNYATAAN",
    section1: "Yang bernama dan bertanda tangan di bawah ini :",
    section2:
      "Kami sebagai orangtua/wali menyatakan dengan segenap hati kesediaan untuk :",
    section3:
      "Kami telah membaca, mengerti dan menyetujui isi SURAT PERNYATAAN ini, dengan demikian kami menandatangani dengan segenap hati kami, tanpa paksaan dari pihak manapun juga.",
    child_name: "Nama Anak"
  },
  teacher: {
    title: "Registrasi Guru",
    switch: "Staff",
    tab1: "Guru",
    tab2: "Riwayat Kependidikan",
    teacher_data_sub: "Data Guru",
    staff_data_sub: "Data Staff",
    add_manual: "Tambah Guru Manual",
    edit_manual: "Ubah Data Guru",
    add_teachers_excel: "Tambah Guru Excel",
    add_manual_staff: "Tambah Staff Manual",
    add_staff_excel: "Tambah Staff Excel",
    edit_manual_staff: "Ubah Data Staff",
    teacher_data: {
      fullname: "Nama Lengkap",
      gender: "Jenis Kelamin"
    },
    address_sub: "Alamat Guru",
    address_staff: "Alamat Staff",
    snackbar_msg_teacher: "Anda Memilih Guru",
    snackbar_msg_staff: "Anda Memilih Staff",
    info_switch: "Pastikan anda memilih tipe Guru atau Staff"
  },
  educational_history: {
    school_name: "Nama Sekolah",
    year_of_entry: "Tahun Masuk",
    year_out: "Tahun Keluar",
    year_out_hint: "Kosongkan jika masih status masih bekerja",
    graduation_year: "Tahun Lulus",
    graduation_year_hint: "Kosongkan jika  belum menyelesaikan jenjang",
    upload_document: "Unggah Dokumen",
    upload_certificate: "Unggah Sertifikasi",
    elementary_school_level_sub: "Jenjang SD",
    junior_high_school_level_sub: "Jenjang SMP",
    senior_high_school_level_sub: "Jenjang SMA",
    strata_level_sub: "Perguruan Tinggi",
    strata_level_data: {
      select_strata_level: "Pilih Perguruan Tinggi",
      origin_of_the_institutions: "Asal Institusi Pendidikan",
      accreditation: "Akreditas",
      diploma_number: "No. Ijazah",
      gpa_value: "Nilai IPK",
      add: "Tambah",
      list_accreditation: [
        { id: "A", name: "A" },
        { id: "B", name: "B" },
        { id: "C", name: "C" }
      ]
    },
    work_experience_sub: "Pengalaman Kerja",
    work_experience_data: {
      company_name: "Nama Perusahaan",
      last_position: "Posisi Terakhir",
      year_out: "Tahun Keluar",
      tell_me_about_yourself: "Ceritakan tentang diri anda",
      fresh_graduate: "Saya punya pengalaman kerja"
    }
  },
  register_done: {
    registration_complete: "Pendaftaran Selesai",
    thanks: "Terima kasih telah melakukan pendaftaran secara online",
    please_check: "Silahkan cek email ",
    to_see: "untuk melihat",
    username_password: "username dan password atau",
    please_note: "silahkan catat username dan password berikut :",
    please_contact: "Silahkan menghubungi admin sekolah, jika anda lupa",
    not_received_email: "Belum menerima Email",
    resend_email: "Kirim ulang"
  }
};
