export default {
  schedule_entry_per_level: "Schedule Entry per Level",
  enter_school_schedule: "Enter School Schedule",
  entry_per_class_schedule: "Entry per Class Schedule",
  general: "General",
  tiers: "Grade",
  all_grade: "All Grade",
  save: "Save Schedule",
  class: "Class",
  day_in: "Day In",
  day_off: "Day Off",
  active: "active",
  no_active: "No Active",
  for_student: "<b>all grades and classes</b>",
  for_staff: "<b>all staff</b>",
  for_teacher: "<b>all guru</b>",
  confirm_update_1: "Are you sure will set the schedule for {typeAbsent}",
  confirm_update_2: "Are you sure will set the schedule for all grade",
  confirm_update_3: "Are you sure you want to set the schedule for",
  confirm_update_4: "Are you sure you will set the schedule for the day",
  response: {
    success: "Successful add schedule",
    success_edit: "Successful edit schedule",
    success_delete: "Successful delete schedule"
  },
  table: {
    class: "Class",
    status: "Status",
    day: "Day",
    lesson_hours: "Lesson Hours",
    action: "Action",
    class_type: "Class Type",
    in: "In",
    out: "Out",
    class_name: "Class Name",
    time_off_attendance: "Absent in",
    time_away_home: "Absent out"
  }
};
