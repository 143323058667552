<template>
  <v-list-item :to="path" links @click="sidebarMini">
    <v-list-item-icon>
      <img v-if="icon" class="icon-sidebar" :src="iconPath(icon)" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <strong>{{ title }}</strong>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    sidebarMini: {
      type: Function,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    icon: String
  },
  methods: {
    iconPath(icon) {
      return require(`@/assets/svg/sidebar/${icon}`);
    }
  }
};
</script>
