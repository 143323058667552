import request from "@/utils/request";

export function insert_ppdb_siswa(data) {
  return request({
    method: "post",
    url: "apply_student",
    data: data
  });
}

export function insert_ppdb_guru(data) {
  return request({
    method: "post",
    url: "apply_employee",
    data: data
  });
}

export function get_major_ppdb() {
  return request({
    method: "post",
    url: "major/dropdown"
  });
}

export function uploadFile(dataUpload, path) {
  if (Array.isArray(dataUpload)) {
    if (dataUpload.length > 0) {
      let files = [];

      Promise.all(
        dataUpload.map(d => {
          const file = request({
            method: "POST",
            url: "aws/upload",
            data: { file: d, path: path }
          }).then(res => res.data.data.path);
          files.push(file);
        })
      );
      return files;
    } else {
      return [];
    }
  } else {
    return request({
      method: "POST",
      url: "aws/upload",
      data: { file: dataUpload, path: path }
    }).then(res => res.data.data.path);
  }
}
