import request from "@/utils/request";
import { detect } from "detect-browser";
const browser = detect();

export function api_login(username, password) {
  const d = `${browser.type};${browser.name};${browser.os}`;
  const name = browser.name.charAt(0).toUpperCase() + browser.name.slice(1);

  return request({
    method: "post",
    url: "login",
    data: {
      username: username,
      password: password,
      device: "web",
      device_detail: `${browser.os} - ${name}`,
      imei: d,
      platform: browser.version,
      institution: window.id_school
    }
  });
}

export function api_login_with_google(token) {
  const name = browser.name.charAt(0).toUpperCase() + browser.name.slice(1);

  return request({
    method: "post",
    url: "login-gmail",
    data: {
      access_token: token,
      device: "web",
      device_detail: `${browser.os} - ${name}`,
      institution: window.id_school
    }
  });
}

export function api_logout_device(id_devices) {
  const name = browser.name.charAt(0).toUpperCase() + browser.name.slice(1);
  return request({
    method: "post",
    url: "logout_device",
    data: { id: id_devices, device_detail: `${browser.os} - ${name}` }
  });
}

export function api_logout(id_user) {
  return request({
    method: "post",
    url: "logout",
    data: { akun: id_user }
  });
}

export function user_profile(token) {
  return request({
    method: "post",
    url: "profile",
    headers: { Authorization: "bearer " + token }
  });
}

export function req_forgot_password(data) {
  return request({
    method: "post",
    url: "user/request_forgot_password",
    data: data
  });
}

export function user_update_password(data) {
  return request({
    method: "post",
    url: "user/update_password",
    data: data
  });
}

export function validate_update_password(data) {
  return request({
    method: "post",
    url: "user/validate_forgot_password_token",
    data: data
  });
}
