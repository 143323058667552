import {
  getPersonalList,
  getListAdminChat,
  getListEmployeeChat,
  getListStudentChat
} from "@/api/messenger";
import store from "@/store";

const app = {
  state: {
    admin_list: [],
    employee_list: [],
    student_list: [],
    limitMessage: 0,
    selected_user: null,
    page: 1,
    total: null,
    lastPage: 0,
    chat_list: [],
    trigger_notif_msg: 0,
    chat_type: "",
    chat_bulk_id: [],
    hasMessage: {
      admin: false,
      teacher: false,
      student: false
    }
  },
  mutations: {
    TRIGGER_NOTIF_MSG: state => {
      state.trigger_notif_msg = state.trigger_notif_msg + 1;
    },
    SET_CHAT_TYPE: (state, chatType) => {
      state.chat_type = chatType;
    },
    SET_CHAT_BULK_ID: (state, group) => {
      state.chat_bulk_id = group;
    },
    SET_CHAT_LIST: (state, chat) => {
      if (state.selected_user && state.selected_user.person == chat.from.person)
        state.chat_list.push(chat);
      else if (
        state.selected_user &&
        state.selected_user.person == chat.to.person
      )
        state.chat_list.push(chat);

      const cond =
        state.selected_user && state.selected_user.person != chat.from.person;

      if (state.admin_list.length > 0) {
        const idx = state.admin_list
          .map(r => r.person)
          .indexOf(chat.from.person);

        if (idx != -1) {
          if (cond) {
            state.admin_list[idx].unread_messages =
              state.admin_list[idx].unread_messages + 1;
            state.hasMessage.admin = true;
            state.trigger_notif_msg = state.trigger_notif_msg + 1;
          } else if (!state.selected_user) {
            state.admin_list[idx].unread_messages =
              state.admin_list[idx].unread_messages + 1;
            state.hasMessage.admin = true;
            state.trigger_notif_msg = state.trigger_notif_msg + 1;
          }
        }
      }

      if (state.employee_list.length > 0) {
        const idx = state.employee_list
          .map(r => r.person)
          .indexOf(chat.from.person);
        if (idx != -1) {
          if (cond) {
            state.employee_list[idx].unread_messages =
              state.employee_list[idx].unread_messages + 1;
            state.hasMessage.teacher = true;
            state.trigger_notif_msg = state.trigger_notif_msg + 1;
          } else if (!state.selected_user) {
            state.employee_list[idx].unread_messages =
              state.employee_list[idx].unread_messages + 1;
            state.hasMessage.teacher = true;
            state.trigger_notif_msg = state.trigger_notif_msg + 1;
          }
        }
      }

      if (state.student_list.length > 0) {
        state.student_list.map(d => {
          const idx = d.students.map(r => r.person).indexOf(chat.from.person);
          if (idx != -1) {
            if (cond) {
              d.students[idx].unread_messages =
                d.students[idx].unread_messages + 1;
              state.hasMessage.student = true;
              state.trigger_notif_msg = state.trigger_notif_msg + 1;
            } else if (!state.selected_user) {
              d.students[idx].unread_messages =
                d.students[idx].unread_messages + 1;
              state.hasMessage.student = true;
              state.trigger_notif_msg = state.trigger_notif_msg + 1;
            }
          }
        });
      }
    },
    SET_ADMIN_LIST: (state, admin_list) => {
      state.admin_list = admin_list;
    },
    SET_EMPLOYEE_LIST: (state, employee_list) => {
      state.employee_list = employee_list;
    },
    RESET_TOTAL_CHAT: (state, isTab) => {
      if (isTab) state.limitMessage = 15;
      else state.limitMessage = 0;
      state.total = 0;
      state.lastPage = 0;
      state.chat_list = 0;
    },
    SET_STUDENT_LIST: (state, student_list) => {
      state.student_list = student_list;
    },
    SET_SELECTED_USER: (state, user) => {
      state.selected_user = user;
    },
    SET_LIMIT_MESSAGE: state => {
      if (state.total != 0 && state.limitMessage > state.total) {
        state.limitMessage = state.total;
      } else {
        state.limitMessage = state.limitMessage + 15;
      }
    },
    SET_HAS_MESSAGE: (state, data) => {
      state.hasMessage[data.type] = data.val;
    },
    REMOVE_SELECTED_USER: state => {
      state.chat_list = [];
      state.selected_user = null;
    }
  },
  actions: {
    getAdminListChat({ commit }, search) {
      return new Promise(resolve => {
        getListAdminChat(search).then(res => {
          if (res.data.code) {
            const data = [];
            res.data.data.map(r => {
              r.type = "ADMIN";
              data.push({ ...r });
            });
            commit("SET_ADMIN_LIST", data);
          }
        });
        resolve(true);
      });
    },
    getEmployeeListChat({ commit }, search) {
      return new Promise(resolve => {
        getListEmployeeChat(search).then(res => {
          if (res.data.code) {
            const data = [];
            res.data.data.map(r => {
              r.type = "TEACHER";
              data.push({ ...r });
            });
            commit("SET_EMPLOYEE_LIST", data);
            resolve(true);
          }
        });
      });
    },
    getStudentListChat({ commit }, search) {
      return new Promise(resolve => {
        getListStudentChat(search).then(res => {
          if (res.data.code) {
            commit("SET_STUDENT_LIST", res.data.data);
            resolve(true);
          }
        });
      });
    },
    getPersonalListChat({ commit, state }) {
      return new Promise((resolve, reject) => {
        // if (state.total == 0 || state.total != state.chat_list.length) {
        // state.chat_list = [];
        commit("SET_LIMIT_MESSAGE");
        getPersonalList({
          limit: state.limitMessage,
          from: store.getters.g_user_id,
          to: state.selected_user.person
        })
          .then(res => {
            if (res.data.code) {
              state.total = res.data.data.total;
              state.lastPage = res.data.data.to;
              const data = res.data.data.data.reverse();
              const tempData = [];
              data.map(d => {
                tempData.push(d);
              });
              state.chat_list = tempData;
            }
            resolve(true);
          })
          .catch(err => {
            reject(err);
          });
        // }
      });
    },
    checkHasMessage({ commit, state }) {
      if (state.admin_list) {
        const totalUnreadA = [];
        state.admin_list.map(r => {
          if (r.unread_messages) totalUnreadA.push(r.unread_messages);
        });
        if (totalUnreadA.length > 0)
          commit("SET_HAS_MESSAGE", { type: "admin", val: true });
        else commit("SET_HAS_MESSAGE", { type: "admin", val: false });
      }

      if (state.employee_list) {
        const totalUnreadT = [];
        state.employee_list.map(r => {
          if (r.unread_messages > 0) totalUnreadT.push(r.unread_messages);
        });
        if (totalUnreadT.length > 0)
          commit("SET_HAS_MESSAGE", { type: "teacher", val: true });
        else commit("SET_HAS_MESSAGE", { type: "teacher", val: false });
      }

      if (state.student_list.length > 0) {
        const totalUnreadS = [];
        state.student_list.map(r => {
          r.students.map(s => {
            if (s.unread_messages) totalUnreadS.push(s.unread_messages);
          });
        });
        if (totalUnreadS.length > 0)
          commit("SET_HAS_MESSAGE", { type: "student", val: true });
        else commit("SET_HAS_MESSAGE", { type: "student", val: false });
      }
    }
  }
};

export default app;
