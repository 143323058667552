<template>
  <v-snackbar
    :value="g_snackbar.status"
    :bottom="g_snackbar.positiony === 'bottom'"
    :left="g_snackbar.positionx === 'left'"
    :right="g_snackbar.positionx === 'right'"
    :top="g_snackbar.positiony === 'top'"
    :color="g_snackbar.color"
    :multi-line="g_snackbar.mode === 'multi-line'"
    :timeout="g_snackbar.timeout"
  >
    <div class="d-flex align-center">
      <span v-html="g_snackbar.msg" />
      <v-spacer />
      <v-btn icon dark text small @click="closeSnackbar">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "snackbar",
  computed: {
    ...mapGetters(["g_snackbar"])
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("CALL_SNACKBAR");
    }
  }
};
</script>

<style>
.v-snack__wrapper {
  margin-bottom: 30px;
}
</style>
