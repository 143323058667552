export default {
  form_title_edit: "Change ​Expertise / Major",
  form_title_add: "Add ​Expertise / Major",
  major: "Major",
  expertise_program: "Expertise Program",
  expertise_competency: "Expertise Competency",
  select_all_major:
    "Activate the '{allMajor}' button, if the school has all majors below.",
  all_major: "All Major",
  major_not_found: "No major yet!",
  success_setmajor: "Successfully set major",
  success_add: "Successfully add new major",
  success_edit: "Successfully edit major",
  success_delete: "Successfully delete major"
};
