export default {
  absent: "Absensi",
  prospective_students: "Calon Siswa",
  active_students: "Siswa Aktif",
  students_graduate: "Siswa Lulus",
  violation: "Pelanggaran",
  prospective_teachers: "Calon Guru",
  teacher_mutation: "Mutasi Guru",
  active_teacher: "Guru Aktif",
  prospective_staff: "Calon Staff",
  active_staff: "Staff Aktif",
  staff_mutation: "Mutasi Staff",
  announcement: "Pengumuman",
  news: "Berita",
  weekly: "Mingguan",
  annual: "Tahunan",
  total_students: "Jumlah Siswa",
  absent_students: "Siswa Absen Hari Ini",
  absent_teacher: "Guru Absen Hari Ini",
  absent_staff: "Staff Absen Hari Ini",
  information_chart: "Pengumuman & Berita",
  monthly: "Bulanan",
  address: "Alamat",
  homeroom: "Wali Siswa",
  phone: "Telepon",
  date: "Tanggal",
  today: "Hari ini",
  hour_activity: "Jam Masuk",
  activity: "Kegiatan hari ini",
  extracuricular_today: "Eskul hari ini",
  attendance_recap: "Rekap Absensi",
  present: "Masuk",
  not_present: "Tidak Masuk",
  leave: "Izin",
  sick: "Sakit",
  day: "Hari",
  homeroom_teacher: "Wali Kelas"
};
