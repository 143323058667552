import Vue from "vue";
import VueRouter from "vue-router";
import Admin from "../views/layout/admin/index.vue";
import Homepage from "../views/layout/homepage/index.vue";

import i18n from "../i18n";
Vue.use(VueRouter);

// Modules
import MasterData from "./modules/masterData";
import Academic from "./modules/academic";
import Recap from "./modules/recap";
import Absent from "./modules/absent";
import Information from "./modules/information";
import Settings from "./modules/settings";
import Student from "./modules/student";
import Transaction from "./modules/transaction";

export const constantRoute = [
  {
    path: "/login/etest",
    name: "loginEtest",
    component: () => import("@/views/loginEtest"),
    hidden: true
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
    hidden: true
  },
  {
    path: "/inspiration",
    component: Homepage,
    hidden: true,
    children: [
      {
        name: "Inspiration",
        path: "/inspiration",
        component: () => import("@/views/homepage/Inspiration")
      }
    ]
  },
  {
    path: "/ebook",
    component: Homepage,
    hidden: true,
    children: [
      {
        name: "Ebook",
        path: "/ebook",
        component: () => import("@/views/homepage/Ebook")
      }
    ]
  },
  {
    path: "/update_password/:token",
    name: "updatePassword",
    component: () => import("@/views/login/updatePassword"),
    hidden: true
  },
  {
    path: "/information",
    name: "Information",
    component: Homepage,
    hidden: true,
    children: [
      {
        path: ":type",
        name: "InformationAll",
        component: () => import("@/views/homepage/ViewAll"),
        hidden: true
      },
      {
        path: ":type/detail",
        name: "InformationDetail",
        component: () => import("@/views/homepage/Detail"),
        hidden: true
      }
    ]
  },
  {
    path: "/homepage",
    component: Homepage,
    hidden: true,
    meta: {
      title: "meta bos",
      icon: "sidebar-icon-dashboard.svg"
    },
    children: [
      {
        name: "Homepage",
        path: "/homepage",
        component: () => import("@/views/homepage")
      }
    ]
  },
  {
    path: "/ppdb",
    component: Homepage,
    hidden: true,
    children: [
      {
        name: "ppdbSiswa",
        path: "student",
        component: () => import("@/views/ppdb/Students")
      },
      {
        name: "ppdbGuru",
        path: "teacher",
        component: () => import("@/views/ppdb/Teachers")
      }
    ]
  },
  {
    path: "/404",
    component: () => import("@/views/errorpage/404"),
    hidden: true
  },
  {
    path: "/changelog",
    component: () => import("@/views/ChangeLog/index"),
    hidden: true
  },
  {
    name: "Zoom",
    path: "/vidcon-zoom",
    component: () => import("@/components/VidConZoom"),
    meta: { title: "Vidcon Zoom" },
    hidden: true
  }
];

export default new VueRouter({
  mode: "history",
  base: window.base_folder,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoute
});

export const asyncRoute = [
  {
    path: "/",
    component: Admin,
    redirect: "dashboard",
    meta: {
      title: i18n.t("routes.dashboard"),
      icon: "sidebar-icon-dashboard.svg",
      roles: [1, 3]
    },
    children: [
      {
        name: "Dashboard",
        path: "dashboard",
        component: () => import("@/views/admin/dashboard/DashboardAdmin"),
        meta: {
          title: i18n.t("routes.dashboard"),
          roles: [1, 3]
        }
      }
    ]
  },
  {
    path: "/",
    component: Admin,
    redirect: "profile",
    hidden: true,
    meta: {
      roles: [25]
    }
  },
  {
    path: "/profile",
    component: Admin,
    hidden: true,
    meta: {
      roles: [25]
    },
    children: [
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/views/admin/dashboard/Profile"),
        hidden: true,
        roles: [25]
      }
    ]
  },
  {
    path: "/messenger",
    component: Admin,
    hidden: true,
    meta: {
      title: i18n.t("routes.dashboard"),
      icon: "sidebar-icon-dashboard.svg",
      roles: [39]
    },
    children: [
      {
        name: "Messenger",
        path: "/messenger",
        component: () => import("@/views/admin/messenger"),
        meta: {
          title: i18n.t("routes.messenger"),
          roles: [39],
          icon: "sidebar-icon-dashboard.svg"
        }
      }
    ]
  },
  Student,
  MasterData,
  Academic,
  Absent,
  Information,
  Transaction,
  Recap,
  Settings,
  { path: "*", hidden: true, redirect: "/404" }
];
