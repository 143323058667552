export default {
  dues_type: "Jenis Tagihan",
  month: "Bulan",
  monthly: "Bulanan",
  master: "Master Tagihan",
  master_type: "Jenis Tagihan",
  search_dues: "Cari jenis tagihan",
  discount: "Potongan",
  pay: "Bayar",
  pay_dues: "Bayar Tagihan",
  history: "Riwayat",
  dues: "Tagihan",
  cancel: "Batal",
  daily_details: "Rincian Harian",
  monthly_details: "Detail Tagihan Bulanan",
  payment_history: "Riwayat Pembayaran Bulan",
  payment_global: "Pembayaran Tagihan Global",
  payment: "Pembayaran Bulan",
  discount_title: "Atur Diskon untuk Bulan",
  not_yet_paid_off: "Dibayar Sebagian",
  not_yet_paid: "Belum dibayar",
  pay_all_bills: "Bayar Semua Tagihan",
  pay_bills: "Bayar Tagihan",
  level_name: "Nama Level",
  no_transaction: "Transaksi tidak ditemukan",
  bill_amout: "Jumlah Tagihan",
  unpaid: "Belum Terbayar",
  paid_off: "Lunas",
  pay_nominal: "Isikan Nominal Pembayaran",
  upload_proff: "Upload bukti pembayaran",
  dialog: {
    confirm_status: "Ubah Status Tagihan",
    confirm_delete: "Anda yakin akan merubah status Tagihan ini?",
    confirm_delete_type: "Hapus Jenis Tagihan",
    confirm_delete_history: "Hapus Riwayat Pembayaran Tagihan"
  },
  table: {
    name_or_nis: "Nama / NIS",
    fullname: "Nama Lengkap",
    name: "Nama",
    virtual_account: "Virtual Account",
    class: "Kelas",
    dues_type: "Jenis Tagihan",
    due_date: "Jatuh Tempo",
    paid: "Pembayaran",
    payment_tolerance: "Toleransi",
    payment_tolerance_day: "Toleransi Jatuh Tempo (Hari)",
    applied_to: "Diterapkan Kepada",
    can_be_repaid: "Boleh Cicil",
    frequency: "Frekuensi",
    level: "Level",
    level_name: "Nama Level",
    global: "Global",
    monthly: "Bulanan",
    dues_name: "Nama Tagihan",
    total: "Jumlah",
    edit: "Ubah Tagihan",
    pay_date: "Tanggal Jatuh Tempo",
    payment_date: "Tanggal Pembayaran",
    margin_date: "Batas Pembayaran",
    unpaid: "Belum Dibayar",
    bill: "Tagihan",
    status: "Status",
    non_active: "Status Tagihan",
    after_discount: "Setelah Potongan",
    paid_off: "Terbayarkan",
    not_paid_yet: "Belum terbayar",
    update: "Ubah Tagihan",
    payment: "Pembayaran",
    no_dues: "Tidak ada Tagihan",
    proof: "Bukti",
    reference: "No. Referensi"
  },
  master_dues: {
    dialog: {
      title: "Tambah Jenis Tagihan",
      title_edit: "Ubah Jenis Tagihan",
      add_dues: "Tambah Tagihan",
      edit_dues: "Ubah Tagihan",
      title_type: "Kelola Jenis Tagihan",
      select_dues_type: "Jenis Tagihan"
    }
  }
};
