export default {
  choose_time: "Choose hour",
  choose_date: "Choose date",
  showing: "Showing",
  of: "of",
  per_page: "Per page",
  zoom_in: "Zoom in",
  zoom_out: "Zoom out",
  rotate_left: "Rotate Left",
  rotate_right: "Rotate Right",
  slider_info1: "Scroll to zoom in / out",
  slider_info2: "Drag to shift the image"
};
