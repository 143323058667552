export default {
  title: "Make Student ID Number / Employee ID Number as User Login",
  student: "Student User Login",
  teacher: "Teacher & Staff User Login",
  student_hint:
    "* If the button is ON then log in all students using Student ID (NIS)",
  teacher_hint:
    "* If the button is ON then login all teachers & staff using Employee ID (NIP)",
  attention: "Attention",
  attention_1: "Admin can specify the student / teacher login user",
  attention_2:
    "Make sure to make the right choice, because this is very important"
};
