export default {
  add_basic_competencies: "Add Basic Competencies",
  edit_basic_competencies: "Edit Basic Competencies",
  detail_basic_competencies: "Detail Basic Competencies",
  choose_subject: "Choose Basic Competencies Subject",
  choose_grade: "Choose Grade",
  basic_competencies: "Basic Competencies",
  weighting: "Weighting",
  chapter: "Chapter / Basic Competencies",
  enter_the_chapter_name: "Enter The Chapter Name",
  sub_chapter: "Sub Chapter",
  enter_the_sub_chapter_name: "Enter The Sub Chapter Name",
  daily_tests: "Daily Tests",
  homework: "Homework",
  midterm_exam: "Midterm Exam",
  final_exams: "Final Exams",
  no_ki_created: "Basic Competencies has not been created",
  remove_selected_competencies: "Remove Selected Competencies",
  save_ki_delete_success: "Success in removing basic competencies",
  save_ki_edit_success: "Success changes basic competencies",
  confirm_delete: "Are you sure you want to delete basic competencies?",
  table: {
    subject: "Subject",
    total_competencies: "Total Competencies",
    action: "Action",
    year: "Year",
    grade: "Grade"
  }
};
