export default {
  date_filing: "Created at",
  students_permission: "Students Permission",
  teachers_permission: "Teachers Permission",
  staff_permission: "Staff Permission",
  add_student: "Add Permission Student",
  delete_student: "Delete Permission Student",
  delete_teacher: "Delete Permission Teacher / Staff",
  add_teacher: "Add Permission Teacher",
  permission: "Permission",
  added_my_permission: "Added My Permission",
  delete_my_permission: "Delete My Permission",
  start_date: "Start Date",
  end_date: "End Date",
  my_permission: "My Permission",
  attachment: "Attachment",
  action: "Action",
  permission_list: "Permission List",
  detail_permission: "Permission Detail",
  permission_type: "Permission Type",
  description: "Description",
  add_teacher_permission: "Add Teacher Permission",
  add_staff_permission: "Add Staff Permission",
  msg_minimal_students: "At least add 1 Student",
  select_students: "Select Students",
  second_step: "Students selected",
  msg: {
    delete_permission: "Successfully removed permissions"
  }
};
