import Vue from "vue";
import store from "../store";
import VueSocketIO from "vue-socket.io";

Vue.use(
  new VueSocketIO({
    debug: process.env.DEBUG_SOCKET,
    connection: process.env.VUE_APP_BASE_URL_IO,
    vuex: { store, actionPrefix: "SOCKET_", mutationPrefix: "SOCKET_" },
    options: { autoConnect: false, secure: process.env.HTTPS }
  })
);
