export default {
  add_core_competencies: "Add Core Competencies",
  edit_core_competencies: "Edit Core Competencies",
  detail_competencies: "Detail Core Competencies",
  choose_school_year: "Choose School Year",
  choose_subject: "Choose Subject",
  subject: "Subject Name",
  sub_chapter: "Sub Chapter",
  weighting: "Weighting",
  daily_tests: "Daily Tests",
  homework: "Homework",
  midterm_exam: "Midterm Exam",
  final_exams: "Final Exams",
  remove_selected_competencies: "Remove Selected Competencies",
  max_weighting: "total weighted less than 100%",
  max_weighting_uh: "total of the combined value is less than 100%",
  choose_grade: "Choose Grade",
  description: "Description",
  ki: "Core Competency",
  formula1: "Divided into groups",
  formula2: "Divided equally",
  total_formula1: "Total daily deed and homework",
  save_ki_delete_success: "Success in removing core competencies",
  save_ki_edit_success: "Success changes core competencies",
  confirm_delete: "Are you sure you want to delete core competencies?",
  global_weights: "Global Weights",
  set_global_weights: "Set Weights",
  save_weight_success: "Successfully set global weighting values",
  view_global_weights: "View weighting grade",
  table: {
    subject: "Subject",
    total_competencies: "Total Competencies",
    action: "Action",
    year: "Year",
    grade: "Grade"
  }
};
