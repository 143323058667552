export default {
  dues_type: "Dues Type",
  month: "Month",
  master: "Dues Master",
  monthly: "Monthly",
  master_type: "Master Type",
  discount: "Discount",
  search_dues: "Search dues type",
  pay: "Pay",
  pay_dues: "Pay Dues",
  history: "History",
  dues: "Dues",
  cancel: "Cancel",
  daily_details: "Daily Details",
  monthly_details: "Monthly Fee Details",
  payment_history: "Payment History for",
  payment_global: "Payment Global Dues",
  payment: "Payment for",
  discount_title: "Set up Discount for",
  not_yet_paid_off: "Partially Paid",
  not_yet_paid: "Not Yet Paid",
  no_dues: "No Dues",
  pay_all_bills: "Pay All Bills",
  pay_bills: "Pay Bills",
  level_name: "Level Name",
  no_transaction: "Transaction not found",
  bill_amout: "Bill Amount",
  unpaid: "Unpaid Bill",
  paid_off: "Paid Off",
  pay_nominal: "Fill in the Payment Amount",
  upload_proff: "Upload proof of payment",
  dialog: {
    confirm_status: "Change Dues Status",
    confirm_delete: "Are you sure you want to change the status of this dues?",
    confirm_delete_history: "Delete Dues Payment History",
    confirm_delete_type: "Delete Dues Type"
  },
  table: {
    name_or_nis: "Name / Student ID",
    fullname: "Fullname",
    name: "Name",
    virtual_account: "Virtual Account",
    class: "Class",
    dues_type: "Dues Type",
    due_date: "Due Date",
    paid: "Paid",
    payment_tolerance: "Payment Tolerance",
    payment_tolerance_day: "Payment Tolerance Due Date (Day)",
    applied_to: "Applied to",
    can_be_repaid: "Can Be Repaid",
    frequency: "Frequency",
    level: "Level",
    level_name: "Level Name",
    global: "Global",
    monthly: "Monthly",
    edit: "Edit Dues",
    dues_name: "Dues Name",
    total: "Total",
    unpaid: "Unpaid",
    pay_date: "Pay Date",
    payment_date: "Payment Date",
    margin_date: "Margin Date",
    bill: "Bill",
    status: "Status",
    non_active: "Dues Status",
    delete: "Delete Dues",
    after_discount: "After Discount",
    paid_off: "Paid Off",
    not_paid_yet: "Not Paid Yet",
    update: "Update Tuition",
    payment: "Payment",
    proof: "Proof",
    reference: "Reference Number"
  },
  master_dues: {
    dialog: {
      title: "Create Dues Masters",
      title_edit: "Edit Dues Masters",
      add_dues: "Add Dues",
      edit_dues: "Edit Dues",
      title_type: "Manage Dues Types",
      select_dues_type: "Dues Types"
    }
  }
};
