import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const Absent = {
  path: "/absent",
  component: Admin,
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.absent"),
    icon: "icon_admin absensi.svg",
    roles: [16, 18, 20]
  },
  children: [
    {
      name: "Personalization",
      path: "personalization",
      component: () => import("@/views/admin/absent/personalization"),
      meta: { roles: [18], title: i18n.t("routes.personalization") }
    },
    {
      name: "Absent",
      path: "absent",
      component: () => import("@/views/admin/absent/absent"),
      meta: {
        roles: [20],
        title: i18n.t("routes.absent"),
        icon: "icon_admin absensi.svg"
      }
    },
    {
      name: "DetailAbsentPerSubjects",
      path: "detail-absent-per-subjects/:id",
      hidden: true,
      component: () =>
        import("@/views/admin/absent/absent/DetailAbsentPerSubjects"),
      meta: {
        roles: [20]
      }
    },
    {
      name: "Permission",
      path: "permission",
      component: () => import("@/views/admin/absent/permission"),
      meta: {
        roles: [16],
        title: i18n.t("routes.permission"),
        icon: "sidebar-icon-recap.svg"
      }
    }
    // {
    //   name: "ScheduleTeacher",
    //   path: "schedule",
    //   component: () => import("@/views/admin/academic/schedule/teacher"),
    //   meta: { roles: [32], title: i18n.t("routes.attendance_teaching") }
    // }
  ]
};

export default Absent;
