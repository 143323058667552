export default {
  private_etest: "E-Test is private",
  add_question: "Add question",
  question_bank: "Question bank",
  code_of_conduct: "Rules",
  clarification: "Description",
  download_question: "Download Question",
  question: "Question",
  question_type: "Question Type",
  character: "Type",
  result: "Result",
  view_file: "View File",
  information: "Description",
  view_results: "View results",
  add_to_the_value: "Add to the value",
  showed: "Showed",
  spesification: "Spesification",
  period: "Period",
  description: "Description",
  all_class: "All Class",
  all_grade: "All Grade",
  some_class: "Some Class",
  start_date: "Start Date",
  basic_competencies: "Basic Competencies",
  end_date: "End Date",
  start_hours: "Start Hours",
  write_answers: "Write Answer",
  the_main_question: "The Main Question",
  backup_question: "Backup Question",
  question_for_etest: "Select Questions for the E-Test",
  end_hours: "End Hours",
  total_students: "Total Students",
  open_file: "Open File",
  total_questions: "Total Questions",
  total_weight: "Total Weight",
  answer_student_file: "File Student Answers",
  title_dialog_publish: "Confirm Publish",
  weight: "Weight",
  explain: "Explain",
  title: "Title",
  added_question: "Added Question",
  update_question: "Update Question",
  mark_correct: "Mark as Correct",
  info_answer:
    "Check mark to indicate the correct answer (at least select one)",
  addressed_to: "Addressed to",
  chance: "Chance",
  type_of_test: "Type of test",
  answer_key: "Answer Key",
  copy_to: "Copy to",
  answer: "Answer",
  your_answer: "Your Answer",
  add_questions_manually: "Add Questions Manually",
  upload_excel: "Upload Excel Question Data",
  upload_document: "Upload Document",
  upload_manual: "Upload Manual Question Data",
  multiple_choice: "Multiple Choice",
  select_question_publish: "Select the question to be published",
  rule_duration: "Minimal duration is 1 minute",
  rule_chance: "At least fill in 1 opportunity",
  subject_tags: "Tags",
  publish_etest_result: "Publish the results of Etest",
  msg_publish_etest: "Successfully publish the E-Test",
  msg_unpublish_etest: "Successfully unpublish E-Test",
  msg_success_publish_remedy: "Successfully publish the Remedy",
  msg_unpublish_remedy: "Successfully unpublish Remedy",
  msg_question: "Question is required",
  msg_same_answer: "Answers cannot be the same",
  msg_answer: "At least add 2 answer",
  msg_answer_key: "At least add 1 answer key",
  msg_remedi_create: "At least add 1 student",
  select_question_from_etest: "Select Question from E-Test",
  msg_minimal_question: "At least add 1 question",
  msg_minimal_class: "At least add 1 class",
  msg_answer_essay: "Fill Answer Essay",
  msg_success_update_question_bank: "Successfully changed the question bank",
  msg_success_create_question_bank: "Successfully create the question bank",
  msg_success_create_etest: "Successfully create the E-Test",
  msg_success_delete_etest: "Successfully deleted E-Test",
  msg_success_delete_question_bank: "Successfully canceled using Problem",
  confirm_cancel_question: "Are you sure you cancel this question?",
  cancel_use_question: "Cancel using questions",
  msg_failed_delete_etest: "Failed deleted E-Test",
  msg_date_etest_required: "Date or time is required",
  msg_time: "Hours cannot be the same",
  msg_answer_cannot_empty: "The answer key cannot be empty",
  publish_etest: "Publish E-Test",
  publish_remedy: "Publish Remedy",
  copy_coc: "Copy from the Default Code of Conduct",
  total_result: "Total Result",
  download_results: "Download Results",
  max_score: "Max Score",
  statistics: "Statistics",
  good_signal: "Good Signal",
  test_schedule: "Test Schedule",
  remaining_duration: "Remaining Duration",
  copy_question: "Copy Question",
  msg_done_etest: "Are you sure you want to complete this E-Test?",
  etest_result: "E-Test Result",
  student_answers: "Student Answers",
  explanation: "Explanation",
  question_explanation: "Question Explanation",
  score: "Score",
  drag: "Choose a File or drag it here",
  select_lesson: "Select Lesson",
  question_name: "Question Name",
  added_remedy: "Added Remedy",
  remedy: "Remedy",
  all_students: "All Students",
  certain_students: "Certain Students",
  under: "Under",
  value_filter: "Value Filter",
  max_upload: "Maximum of 3 files",
  msg_rule_max_score: "The value cannot be more than the maximum value",
  delete_remedy: "Delete Remedy",
  success_delete_remedy: "Success Delete Remedy",
  failed_delete_remedy: "Failed Delete Remedy",
  delete_etest: "Delete E-Test",
  msg_delete_remedy: "Are you sure you want to delete remedy ?",
  msg_update_etest: "Successfully update E-Test",
  cancel_edit: "Cancel edit",
  not_checked_yet: "Not checked yet",
  not_checked_yet_dialog: "Not<br/> checked yet",
  delete_answer: "Delete Answer",
  added_answer: "Added Answer",
  used_score: "Used Score",
  value_posts: "Value Posts",
  correction: "Correction",
  value_used: "Value Used",
  choose_test: "Choose Test",
  student_grade_details: "Student Grade Details",
  msg_posts_value: "Are you sure you want to post this rating?",
  msg_posts_etest: "Are you sure you want to post the results of this E-Test?",
  msg_success_posts_etest: "The E-Test results can be seen by students",
  msg_type: "Successfully changed type",
  finished_working_etest: "Finished working E-Test",
  confirm_update_etest: "Are you sure you want to modify the E-Test?",
  e_test_results_published: "E-Test results have been published",
  reason_failed: "Reason failed",
  created_by: "Created By",
  test_time_is_not_over: "E-Test isn't over yet",
  continuation: "Continuation",
  test_time_is_draft: "E-Test Not yet published",
  delete_and_edit_for_creator:
    "delete and edit function only for question creator",
  my_question_bank: "My question bank",
  my_etest: "My E-Test",
  noaccess_information:
    "If the action menu is only statistics it means you are not the author of the E-Test / E-Test is private",
  msg_excel: {
    info_success: "Data uploaded successfully",
    info_failed: "Data upload failed",
    title_dialog_upload: "Data upload is complete",
    info: "Please correct the incorrect data",
    period: "Period column is not available",
    grade: "Grade column is not available",
    semester: "Semester column is not available",
    subjects: "Subjects column is not available",
    question_type: "Question type column is not available",
    question: "Question column is not available",
    answer: "Answer column is not available",
    point: "Point column is not available",
    discuss: "Discuss column is not available",
    keyword: "Keyword column is not available",
    success_upload: "Successfully uploaded the question bank"
  },
  msg: {
    success_create_remedy: "Successfully create remedy",
    validate_end_date: "Expiration Date is required",
    validate_end_hours: "Time Expired is required",
    confirm_continuation: "Are you sure you want to save the E-Test replies",
    success_continuation: "Successfully create susulan E-Test",
    question_bank_delete: "Successfully deleted the question bank",
    validate_weight: "The weight cannot be greater than 100",
    validate_minus: "Weights cannot be minus",
    success_update_kd: "Successfully updated Basic Competencies",
    kd_empty: "Please fill in Basic Competence",
    max_upload_excel: "Maximum upload one file",
    success_copy_questions: "Successfully copy question",
    success_update_weight: "Successfully changed weights",
    success_update_chance: "Successfully changed chance",
    success_update_duration: "Successfully changed duration",
    validate_update_chance:
      "Total chance must be greater than the remaining opportunity",
    success_posting_value: "Successfully posted a value",
    success_add_question_bank:
      "Successfully selected questions from the question bank",
    valid_type_upload:
      "Invalid file selected, valid files are of .xlsx, xls types.",
    date_passed: "The time to do the e-test has passed",
    date_not_passed:
      "Your test opportunity or duration has run out, please contact the test admin/supervisor if you want to increase the opportunity or duration of the test",
    do_e_test: "Do E-Test",
    not_yet_test: "Not time for exams"
  },
  filter_answer: {
    correct: "Correct",
    incorrect: "Incorrect",
    not_doing: "Not Doing",
    less_precise: "Less Precise"
  },
  filter: {
    the_highest_score: "The Highest Score",
    lowest_value: "Lowest Value",
    by_name: "By Name"
  },
  msg_publish: {
    publish: "Are you sure you want to publish this E-Test?",
    unpublish: "Are you sure you want to cancel the publication of this E-Test?"
  },
  msg_publish_remedy: {
    publish: "Are you sure you want to publish this Remedy?",
    unpublish: "Are you sure you want to cancel the publication of this Remedy?"
  },
  name_test: {
    daily_test: "Daily Test",
    homework: "Homework",
    midterm_exam: "Midterm Exam",
    semester_exam: "Semester Exam"
  },
  table_header: {
    detail_subjects: {
      title: "Title",
      create_date: "Create date",
      release_date: "Release date",
      end_date: "End date",
      test_date: "Test date",
      hours: "Hours",
      duration: "Duration (Minute)",
      status: "Status",
      students_work_on: "Students work on"
    },
    statistics: {
      title: "Title",
      status: "Status",
      students_correctly: "Correctly",
      students_are_wrong: "Incorrect",
      not_completing: "Not Completing",
      less_precise: "Less Precise (Essay)",
      status_correctly: "Not checked yet (Essay)"
    },
    chance: {
      name: "Name",
      chance: "Chance",
      opportunity_missed: "Opportunity Missed",
      remaining_opportunity: "Remaining Opportunity"
    }
  },
  tabs: {
    first: "All",
    second: "Take Place",
    third: "Available",
    fourth: "Will Come"
  }
};
