import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import en from "./locales/en";
import id from "./locales/id";

const messages = {
  en: en,
  id: id
};

// function loadLocaleMessages() {
// const locales = require.context(
//   "./locales",
//   true,
//   /[A-Za-z0-9-_,\s]+\.json$/i
// );
// locales.keys().forEach(key => {
//   const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//   if (matched && matched.length > 1) {
//     const locale = matched[1];
//     messages[locale] = locales(key);
//   }
// });
//   return messages;
// }

export default new VueI18n({
  locale: localStorage.getItem("language") || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale:
    localStorage.getItem("language") ||
    process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages
});

// process.env.VUE_APP_I18N_LOCALE
// process.env.VUE_APP_I18N_FALLBACK_LOCALE
