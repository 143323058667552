import { get_master_data } from "@/api/masterData";

const masterData = {
  state: {
    master_data: {}
  },
  mutations: {
    SET_MASTER_DATA: (state, data) => {
      state.master_data = data;
    }
  },
  actions: {
    getMasterData({ commit }) {
      return new Promise(resolve => {
        get_master_data().then(res => {
          commit("SET_MASTER_DATA", res.data.data);
          resolve(res);
        });
      });
    }
  }
};

export default masterData;
