export default {
  content: "Content",
  copy_for_grade: "Copy E-Learning from grade / class",
  copy_elearning: "Copy e-learning",
  add_elearning: "Add E-Learning",
  edit_elearning: "Edit E-Learning",
  add_elearning_content: "Add E-Learning Content",
  edit_elearning_content: "Edit E-Learning Content",
  subject_not_found: "Subjects in the selected class were not found",
  content_not_found: "Content in the selected elearning were not found",
  elearning_not_found: "E-learning were not found",
  title: "Title",
  basic_competencies: "Basic Competencies",
  select_elearning: "Select e-learning to copy",
  copy_content_from: "Copy Content From",
  choose_file_type: "Choose File Type",
  choose_file: "Choose File",
  document: "Document",
  save_unpublised: "Save as Draft",
  save_published: "Save and Published",
  cancel_publish: "Cancel Publish",
  view_others: "Others E-learning",
  view_previous_comment: "View Previous Comment",
  comment: "Comment",
  attention: "Attention",
  my_elearning: "My Elearning",
  attention_text_1:
    ": If you can't play videos in the browser / want to download videos, please download videos using the icon ",
  attention_text_2: "at the bottom right corner in the video",
  like: {
    you: "You like this content",
    you_and: "You and",
    others_people: "others people",
    and: "and",
    others: "others"
  },
  confirm: {
    publish: "Publish E-Learning",
    publish_text: "Are you sure want to publish this selected content?",
    unpublish_text: "Are you sure you want to cancel publishing this content?",
    publish_text_1: "Are you sure want to publish E-Learning with the title",
    publish_text_1_bulk: "Are you sure want to publish selected E-Learning",
    publish_text_2: "All e-Learning content will change to be published.",
    publish_text_3:
      "If there is certain content which is not ready to be published, please click action-> view then select those not ready to be published",
    selected: "selected",
    unpublish: "Unpublish E-Learning",
    unpublish_text_1:
      "Are you sure want to cancel the E-Learning with the title",
    unpublish_text_1_bulk:
      "Are you sure want to cancel the publication of selected E-Learning?",
    unpublish_text_2: "All e-learning content will be changed to unpublished",
    delete: "Delete E-Learning",
    delete_text: "Are you sure want to delete this e-learning?"
  },
  table: {
    subject: "Subject",
    total: "Total",
    created_at: "Created At",
    title: "Title",
    kd: "Basic Competencies",
    status: "Status",
    grade: "Grade",
    class: "Class Name",
    description: "Description",
    file_type: "File Type",
    published: "Published",
    unpublish: "Unpublished"
  }
};
