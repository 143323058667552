const tahunAjaran = {
  state: {
    tahun: [
      "2018 / 2019 - Semester 1",
      "2018 / 2019 - Semester 2",
      "2019 / 2020 - Semester 1",
      "2019 / 2020 - Semester 2"
    ],
    bulan: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "Nopember",
      "Desember"
    ],
    kelas: {
      currentKelas: "",
      Semua: [],
      "10 / EXI 10": ["Semua", "1", "2", "3", "4", "5"],
      "11 / EXI 11": ["Semua", "1", "2", "3", "4"],
      "12 / EXI 12": ["Semua", "1", "2", "3"]
    }
  },
  mutations: {
    GANTI_KELAS: (state, data) => {
      state.currentKelas = data;
    }
  }
};

export default tahunAjaran;
