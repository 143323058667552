import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const Transaction = {
  path: "/transaction",
  component: Admin,
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.transaction"),
    icon: "sidebar-icon-transaction.svg",
    roles: [43, 61]
  },
  children: [
    {
      name: "Dues",
      path: "dues",
      component: () => import("@/views/admin/transactions/dues"),
      meta: { roles: [43, 61], title: i18n.t("routes.dues") }
    },
    {
      name: "DuesDetail",
      path: "dues/detail/:id",
      hidden: true,
      component: () => import("@/views/admin/transactions/dues/DuesDetail"),
      meta: { roles: [43, 61], title: i18n.t("routes.dues") }
    },
    {
      name: "DuesMaster",
      path: "dues/master",
      hidden: true,
      component: () => import("@/views/admin/transactions/dues/DuesMaster"),
      meta: { roles: [43, 61], title: i18n.t("routes.dues") }
    },
    {
      name: "DuesList",
      path: "dues/master/:id",
      hidden: true,
      component: () => import("@/views/admin/transactions/dues/DuesList"),
      meta: { roles: [43, 61], title: i18n.t("routes.dues") }
    }
  ]
};

export default Transaction;
