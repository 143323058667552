var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{style:({
    backgroundColor: _vm.alertOptions.background_color,
    color: _vm.alertOptions.text_color
  }),attrs:{"id":"server-down-dialog-wrapper"}},[_c('v-icon',{staticClass:"close_btn",attrs:{"color":_vm.alertOptions.text_color,"small":""},on:{"click":_vm.closeAlert}},[_vm._v("mdi-close")]),_c('v-card-text',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"server-down-dialog",style:({
        color: _vm.alertOptions.text_color
      })},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":_vm.iconServerDown,"lazy-src":_vm.iconServerDown}}),_c('div',{staticStyle:{"padding-left":"160px"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.alertOptions.note)}})])]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"server-down-dialog-mob",style:({
        color: _vm.alertOptions.text_color
      })},[_c('div',{staticClass:"d-flex justify-center"},[_c('img',{staticStyle:{"width":"125px"},attrs:{"src":_vm.iconServerDown,"lazy-src":_vm.iconServerDown}})]),_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.alertOptions.note)}})])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }