import { getVersion } from "@/api/app";

const app = {
  state: {
    version: {
      frontend: "",
      backend: "",
      institution_id: null
    },
    language:
      localStorage.getItem("language") || process.env.VUE_APP_I18N_LOCALE,
    darkmode: localStorage.getItem("darkmode") == "true" ? true : false,
    snackbar: {
      status: false,
      timeout: 5000,
      color: "blue-grey darken-2",
      msg: "",
      mode: "",
      positionx: "",
      positiony: ""
    },
    full_loading: false,
    modal_pendaftaran: false,
    latency: 0
  },
  mutations: {
    SET_LATENCY: (state, data) => {
      state.latency = data;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      localStorage.setItem("language", language);
    },
    SET_DARKMODE: state => {
      const getDarkModeStatus = localStorage.getItem("darkmode") == "true";
      state.darkmode = !getDarkModeStatus;
      localStorage.setItem("darkmode", state.darkmode);
    },
    SET_VERSION: (state, version) => {
      state.version.backend = "B" + version.backend;
      state.version.frontend = "F" + version.frontend;
      state.version.institution_id = version.institution_id;
    },
    CALL_SNACKBAR: (state, data) => {
      state.snackbar.status = false;

      if (data) {
        setTimeout(() => {
          state.snackbar.msg = data.msg ? data.msg : "";
          state.snackbar.color = data.color ? data.color : "blue-grey darken-2";
          state.snackbar.positionx = data.x ? data.x : "left";
          state.snackbar.positiony = data.y ? data.y : "bottom";
          state.snackbar.status = true;
          state.snackbar.timeout = data.timeout ? data.timeout : 5000;
        }, 200);
      }
    },
    TOGGLE_FULL_LOADING: state => {
      state.full_loading = !state.full_loading;
    },
    TOGGLE_MODAL_PENDAFTARAN: state => {
      state.modal_pendaftaran = !state.modal_pendaftaran;
    }
  },
  actions: {
    setLanguage({ commit }, language) {
      // setTimeout(() => {
      commit("SET_LANGUAGE", language);
      location.reload();
      // }, 1000);
    },
    async getVersion({ commit }) {
      await getVersion().then(res => {
        if (res.data.code) {
          commit("SET_VERSION", res.data.data);
        }
      });
    }
  }
};

export default app;
