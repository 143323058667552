import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const Absent = {
  path: "/information-adm",
  component: Admin,
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.information"),
    icon: "icon_admin_information.svg",
    roles: [37, 38]
  },
  children: [
    {
      name: "Announcement",
      path: "announcement/detail",
      component: () => import("@/views/admin/information/announcement.vue"),
      meta: { roles: [37], title: i18n.t("routes.announcement") }
    },
    {
      name: "News",
      path: "news/detail",
      component: () => import("@/views/admin/information/news.vue"),
      meta: { roles: [38], title: i18n.t("routes.news") }
    }
  ]
};

export default Absent;
