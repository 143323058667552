export default {
  weekly_report_detail: "Weekly Report Detail",
  submit_from_teacher: "Submit from teacher",
  input_reason_reject: "Input the reason why it was rejected or not published",
  name: "Name",
  registration_number: "Registration Number",
  class: "Class",
  type: "Type",
  table: {
    name: "Name",
    approved: "Approved",
    shared: "Shared",
    rejected: "Rejected",
    deleted: "Deleted",
    month: "Month",
    title: "Title",
    status: "Status",
    received: "Received",
    canceled: "Canceled",
    information: "Information"
  }
};
