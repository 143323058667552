const getters = {
  // App Modules
  g_language: state => state.app.language,
  g_version: state => state.app.version,
  g_app: state => state.app.detail,
  g_snackbar: state => state.app.snackbar,
  g_full_loading: state => state.app.full_loading,
  g_darkmode: state => state.app.darkmode,
  g_latency: state => state.app.latency,
  g_master_data: state => state.masterData.master_data,
  // Permission Modules
  g_route_async: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  // User Modules
  g_roles: state => state.user.roles,
  g_is_duhan: state => state.user.isDuhan,
  g_access: state => state.user.access,
  g_privilages_user: state => state.user.privilagesUser,
  g_role_type: state => state.user.user.role_type,
  g_token: state => state.user.token,
  g_modal_school_address: state => state.user.modal_school_address,
  g_user: state => state.user.user,
  g_user_id: state => state.user.id,
  g_school: state => state.user.school,
  g_include_vidcon: state => state.user.school.includeVidcon,
  g_level_school: state => state.user.school.level,
  // Homepage
  g_modal_pendaftaran: state => state.app.modal_pendaftaran,
  g_ppdbsiswa: state => state.ppdbSiswa,
  g_ppdb_siswa: state => state.ppdbSiswa.siswa,
  g_ppdb_ortu: state => state.ppdbSiswa.orang_tua,
  g_ppdb_wali: state => state.ppdbSiswa.wali,
  g_ppdb_pernyataan: state => state.ppdbSiswa.pernyataan,
  g_ppdbguru: state => state.ppdbGuru,
  g_ppdb_guru: state => state.ppdbGuru.guru,
  g_ppdb_riwayat: state => state.ppdbGuru.riwayat,
  // Admin
  g_modal_change_password: state => state.admin.modal_change_password,
  g_master_data_dummy: state => state.master.data_dummy,
  g_master_data_siswa: state => state.master.data_siswa,
  g_master_siswa: state => state.master.data_siswa.siswa,
  g_master_prospective_staff: state => state.staff.prospective,
  g_master_mutasi_keluar: state => state.master.data_siswa.mutasi_keluar,
  g_master_calon_siswa: state => state.master.data_siswa.calon_siswa,
  g_master_data_guru: state => state.master.data_guru,
  g_master_data_staff: state => state.master.data_staff,
  // g_academic_list_class: state => state.classList,
  g_academic_schedule: state => state.schedule,
  g_academic_stppa: state => state.stppa,
  g_academic_subjects: state => state.subjects,
  g_academic_extracurricular: state => state.extracurricular,
  // messenger
  g_messenger: state => state.messenger,
  g_admin_list: state => state.messenger.admin_list,
  g_employee_list: state => state.messenger.employee_list,
  g_student_list: state => state.messenger.student_list,
  g_trigger_notif_msg: state => state.messenger.trigger_notif_msg,
  g_has_message: state => state.messenger.hasMessage,
  g_chat_type: state => state.messenger.chat_type,
  g_chat_bulk_id: state => state.messenger.chat_bulk_id,
  // Notifification
  g_notification_count: state => state.notification.notificationCount,
  g_notification_information: state =>
    state.notification.notificationInformation,
  g_notification_promo: state => state.notification.notificationPromo,
  g_notification_information_count: state =>
    state.notification.notificationInformationCount,
  g_notification_promo_count: state =>
    state.notification.notificationPromoCount,
  g_notification_query: state => state.notification.notificationQuery
};

export default getters;
