export default {
  group: "Kelompok",
  general: "Umum",
  add_gos: "Tambah Kelompok Mapel",
  update_form: "Ubah Kelompok Mata Pelajaran",
  create_form: "Tambah Kelompok Mata Pelajaran",
  success_update: "Berhasil merubah kelompok mata pelajaran",
  success_create: "Berhasil membuat kelompok mata pelajaran",
  delete_confirm: "Hapus Kelompok Mapel",
  move_group: "Pindah kelompok mata pelajaran",
  select_gos: "Pilih Kelompok Mata Pelajaran",
  note: "mata pelajaran yang dipilih tidak akan ditampilkan di rapor",
  select_for_all_grade: "Pilih untuk semua tingkatan",
  table: {
    gos: "Kelompok Mata Pelajaran",
    name: "Nama Kelompok",
    subject: "Mata Pelajaran",
    show: "Sembunyikan Di Rapor"
  },
  form: {
    name_group_subject: "Nama Kelompok Mata Pelajaran"
  }
};
