import i18n from "@/i18n";
import { update_password } from "@/api/admin/admin";

const admin = {
  state: {
    modal_change_password: false
  },
  mutations: {
    TOGGLE_CHANGE_PASSWORD: state => {
      state.modal_change_password = !state.modal_change_password;
    }
  },
  actions: {
    toggleModalChangePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        update_password(data)
          .then(res => {
            if (res.data.code) {
              commit("TOGGLE_CHANGE_PASSWORD");
              commit("CALL_SNACKBAR", {
                msg: i18n.t("app.change_password_success"),
                color: "success"
              });
              resolve(true);
            } else {
              commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
              reject();
            }
          })
          .catch(error => {
            reject(error.response);
          });
      });
    }
  }
};

export default admin;
