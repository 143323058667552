export default {
  absent: "Absent",
  prospective_students: "Prospective Students",
  active_students: "Active Students",
  students_graduate: "Students Graduate",
  violation: "Violation",
  prospective_teachers: "Prospective Teachers",
  teacher_mutation: "Teacher Mutation",
  active_teacher: "Active Teacher",
  prospective_staff: "Prospective Staff",
  active_staff: "Active Staff",
  staff_mutation: "Staff Mutation",
  announcement: "Announcement",
  news: "News",
  weekly: "Weekly",
  annual: "Annual",
  total_students: "Total Students",
  absent_students: "Student Absent Today",
  absent_teacher: "Teacher Absent Today",
  absent_staff: "Staff Absent Today",
  information_chart: "Announcement & News",
  monthly: "Monthly",
  address: "Address",
  homeroom: "Homeroom",
  phone: "Phone",
  date: "Date",
  today: "Today",
  hour_activity: "Hour Activity",
  activity: "Today Activity",
  extracuricular_today: "Extracuricular Today",
  attendance_recap: "Attendance Recap",
  present: "Present",
  not_present: "Not Present",
  leave: "Leave",
  sick: "Sick",
  day: "Days",
  homeroom_teacher: "Homeroom Teacher"
};
