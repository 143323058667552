import { uploadFile, deleteFile } from "@/api/admin/admin";
import { sliceAwsPath } from "@/utils/uploadS3";

function isValidDate(d) {
  return d != "0000-00" || !isNaN(d);
}

function initialState() {
  return {
    employee: {},
    id_sekolah: window.id_school,
    auto_accept: "",
    guruOrStaff: "TEACHER",
    guru: {
      completed: true,
      open_panel: [],
      data_guru: {
        foto: null,
        nama: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        agama: "",
        telepon: "",
        jenis_kelamin: ""
      },
      alamat_guru: {
        alamat: "",
        provinsi: "",
        kode_pos: "",
        telepon_rumah: "",
        kota: "",
        negara: "",
        email: "",
        latlong: ""
      }
    },
    riwayat: {
      completed: true,
      open_panel: [0, 1, 2, 3, 4],
      freshgraduate: false,
      SD: {
        nama_sekolah: "",
        tahun_masuk: "",
        tahun_lulus: "",
        dokumen: []
      },
      SMP: {
        nama_sekolah: "",
        tahun_masuk: "",
        tahun_lulus: "",
        dokumen: []
      },
      SMA: {
        nama_sekolah: "",
        tahun_masuk: "",
        tahun_lulus: "",
        dokumen: []
      },
      strata: [
        {
          jenjang: "",
          asal_institusi: "",
          akreditas: "",
          tahun_masuk: "",
          nomor_ijazah: "",
          nilai_ipk: "",
          tahun_lulus: "",
          dokumen: []
        }
      ],
      pengalaman_kerja: [
        {
          nama_perusahaan: "",
          posisi_terakhir: "",
          tahun_masuk: "",
          tahun_keluar: "",
          dokumen: []
        }
      ],
      non_formal: {
        dokumen: []
      },
      pengalaman_kerja_cerita: ""
    }
  };
}

const ppdbGuru = {
  state: initialState,
  mutations: {
    SET_PANEL_GURU: (state, param) => {
      state[param.tab].open_panel = param.val;
    },
    SET_COMPLETE_TAB_GURU: (state, data) => {
      state[data.param].completed = data.status;
    },
    PPDB_SET_ALAMAT_GURU: (state, data) => {
      state.guru.alamat_guru.alamat = data.Address.Label;
      state.guru.alamat_guru.provinsi = data.Address.County;
      state.guru.alamat_guru.kode_pos = data.Address.PostalCode;
      state.guru.alamat_guru.kota = data.Address.City;
      state.guru.alamat_guru.negara = data.Address.AdditionalData[0].value;
      state.guru.alamat_guru.latlong = data.DisplayPosition;
    },
    PPDB_RESER_ALAMAT_GURU: state => {
      state.guru.alamat_guru.alamat = "";
      state.guru.alamat_guru.provinsi = "";
      state.guru.alamat_guru.kode_pos = "";
      state.guru.alamat_guru.kota = "";
      state.guru.alamat_guru.negara = "";
      state.guru.alamat_guru.latlong = "";
    },
    RESET_PPDB_GURU: state => {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    SWITCH_GURU_OR_STAFF: (state, data) => {
      state.guruOrStaff = data ? "TEACHER" : "STAFF";
    },
    AUTO_ACCEPT_EMPLOYEE: state => {
      state.auto_accept = "TRUE";
    },
    SET_DATA_EMPLOYEE: (state, data) => {
      state.employee = data.employee;
      state.guru = data.guru.guru;
      let riwayat = data.guru.riwayat;
      if (riwayat.strata.length > 0) {
        const strata = [];
        riwayat.strata.map(r => {
          r.tahun_lulus = isValidDate(r.tahun_lulus) ? r.tahun_lulus : null;
          strata.push({ ...r });
        });
        riwayat.strata = strata;
      }

      if (riwayat.pengalaman_kerja.length > 0) {
        const pengalaman_kerja = [];
        riwayat.pengalaman_kerja.map(r => {
          r.tahun_keluar = isValidDate(r.tahun_keluar) ? r.tahun_keluar : null;
          pengalaman_kerja.push({ ...r });
        });
        riwayat.pengalaman_kerja = pengalaman_kerja;
      }

      state.riwayat = riwayat;
    },
    SET_DOCUMENT: (state, data) => {
      switch (data.type) {
        case "SD":
          state.riwayat.SD.dokumen.push(data.file);
          break;
        case "SMP":
          state.riwayat.SMP.dokumen.push(data.file);
          break;
        case "SMA":
          state.riwayat.SMA.dokumen.push(data.file);
          break;
        case "STRATA":
          state.riwayat.strata[data.idx].dokumen.push(data.file);
          break;
        case "WORK_EXPERIENCE":
          state.riwayat.pengalaman_kerja[data.idx].dokumen.push(data.file);
          break;
        default:
          state.riwayat.non_formal.dokumen.push(data.file);
          break;
      }
    },
    SET_DOCUMENT_DELETE: (state, data) => {
      switch (data.type) {
        case "SD":
          state.riwayat.SD.dokumen = [];
          break;
        case "SMP":
          state.riwayat.SMP.dokumen = [];
          break;
        case "SMA":
          state.riwayat.SMA.dokumen = [];
          break;
        case "STRATA":
          state.riwayat.strata[data.idx].dokumen = [];
          break;
        case "WORK_EXPERIENCE":
          state.riwayat.pengalaman_kerja[data.idx].dokumen = [];
          break;
        default:
          state.riwayat.non_formal.dokumen = [];
          break;
      }
    }
  },
  actions: {
    deleteImage({ commit }, data) {
      return new Promise(async resolve => {
        await Promise.all(
          data.file.map(async p => {
            const path = await sliceAwsPath(p);
            await deleteFile(path, "REGISTRATION");
          })
        );

        commit("SET_DOCUMENT_DELETE", {
          idx: data.idx,
          file: [],
          type: data.type
        });
        resolve(true);
      });
    },
    uploadImage({ commit }, data) {
      return new Promise(async (resolve, reject) => {
        if (data.file.length > 0) {
          let resData = {};

          await Promise.all(
            data.file.map(async img => {
              let bodyImage = new FormData();
              bodyImage.set("path", "master_data");
              bodyImage.set("module", "REGISTRATION");
              bodyImage.append("file", img);

              const res = await uploadFile(bodyImage);

              resData = {
                idx: data.idx,
                file: res.data.data.path,
                type: data.type
              };
              commit("SET_DOCUMENT", resData);
            })
          ).catch(() => reject(false));

          resolve(resData);
        }
      });
    }
  }
};

export default ppdbGuru;
