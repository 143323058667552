export default {
  absent_type: "Absent Type",
  clock: "Jam",
  added_manual: "Added Manual Absence",
  delete_manual: "Delete Manual Absence",
  total_students: "Total Student",
  time_attendance: "Time Attendance",
  not_yet_absent: "Not yet Absent / Not leaving",
  freeday: "Freeday",
  absent_in: "Absent In",
  absent_out: "Absent Out",
  manual_absent: "Manual Absence",
  uid_absent: "UID Absence",
  hour_of_entry: "Hour of Entry",
  hour_out: "Hour Out",
  absent_report: "Absent Report",
  absent_student: "Absent Student",
  absent_teacher: "Absent Teacher",
  absent_staff: "Absent Staff",
  added_absent: "Added Absent",
  remove_absent: "Remove Absent",
  search_valid_rules: "Class is required",
  type_absent: "Type Absent",
  late: "Late",
  delete_absent_in: "Delete Absent In",
  delete_absent_out: "Delete Absent Out",
  student: "Student",
  please_add_absent_present: "Please add absent present",
  teacher: "Teacher",
  staff: "Staff",
  select_teacher: "Select Teacher",
  confirm_delete: "Are you sure you want to delete absences?",
  absent_per_day: "Absent per day",
  absent_per_subjects: "Absent per subjects",
  absent_date: "Absent Date",
  approved: "Approved",
  unapproved: "Unapproved",
  approved_absent: "Approved Absent",
  unapproved_absent: "Unapproved Absent",
  teacher_me: "Teacher (Me)",
  absent_has_not_been_approved: "Absent has not been approved",
  msg: {
    error: {
      room_code_invalid: "This class has never held a video conference"
    },
    success: {
      refresh_absent: "Successfully Refresh Absent",
      update_nis: "Successfully Update Student ID",
      approved_absent: "Successfully Approved Absence",
      unapproved_absent: "Successfully Disapproved Absence"
    },
    required_set_hours:
      "Please set the login time first in the Master menu -> Set Hours",
    unapproved_absent: "Are you sure you don't approve of this absence?",
    approved_absent: "Are you sure you agree to this absence?"
  },
  month: [
    {
      id: "1",
      value: "January"
    },
    {
      id: "2",
      value: "February"
    },
    {
      id: "3",
      value: "March"
    },
    {
      id: "4",
      value: "April"
    },
    {
      id: "5",
      value: "May"
    },
    {
      id: "6",
      value: "June"
    },
    {
      id: "7",
      value: "July"
    },
    {
      id: "8",
      value: "August"
    },
    {
      id: "9",
      value: "September"
    },
    {
      id: "10",
      value: "October"
    },
    {
      id: "11",
      value: "November"
    },
    {
      id: "12",
      value: "December"
    }
  ],
  response: {
    success: "Successful add absent",
    success_edit: "Successful edit absent",
    success_delete: "Successful delete absent",
    failed: "Failed to add absent",
    failed_delete: "Failed delete absent",
    failed_approve: "Failed to approve absence"
  },
  table: {
    action: "Action",
    uid: "Card Number"
  }
};
