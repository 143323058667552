export default {
  school_year: "School Year",
  class: "Choose Class",
  class_type: "Class Type",
  date: "Choose Date",
  add_journal: "Add Journal",
  edit_journal: "Edit Journal",
  confirm_delete_title: "Delete journal",
  confirm_delete: "Are you sure you want to delete this journal?",
  table: {
    title: "Title",
    date: "Date",
    hour: "Hour",
    subject: "Subject",
    supporting_teacher: "Supporting Teacher",
    theory: "Theory",
    information: "Information"
  }
};
