<template>
  <v-footer inset padless dense>
    <v-card
      flat
      tile
      :class="{ 'grey lighten-4': !g_darkmode }"
      style="width: 100%"
    >
      <v-container>
        <v-row :class="!$vuetify.breakpoint.xs && 'text-left'" no-gutters>
          <v-col
            cols="12"
            sm="3"
            justify-self="center"
            align-self="center"
            class="pa-4"
          >
            <v-img
              :src="profileSchool.about.path_landscape"
              contain
              class="sistesi-logo mx-auto"
              style="max-width: 300px"
            />
          </v-col>
          <v-col cols="12" sm="4" justify-self="center" class="py-0">
            <v-card-text>
              <div style="margin-bottom: 5px">
                <div class="title font-weight-bold">
                  {{ profileSchool.about.foundation }}
                </div>
                <div class="title font-weight-bold">
                  {{ profileSchool.about.name }}
                </div>
              </div>
              <div class="body-2">
                {{ profileSchool.about.address }}
              </div>
              <div class="body-2">{{ profileSchool.about.phone }}</div>
            </v-card-text>
          </v-col>

          <v-col cols="12" sm="3" class="py-0 d-flex">
            <v-card-text v-if="showFollowUs">
              <strong class="pb-2 pl-1">{{ $t("app.contact_us") }}</strong>
              <div class="d-flex flex-column">
                <div
                  v-if="isPhoneValid(profileSchool.about.whatsapp)"
                  @click="openWhatsapp(profileSchool.about.whatsapp)"
                >
                  <v-btn small text icon color="primary"
                    ><v-icon color="success">mdi-whatsapp</v-icon></v-btn
                  ><span class="cursor">
                    {{ profileSchool.about.whatsapp }}
                  </span>
                </div>
                <div v-if="isPhoneValid(profileSchool.about.phone)">
                  <a :href="`tel:${profileSchool.about.phone}`">
                    <v-btn small text icon color="light-blue"
                      ><v-icon color="primary">mdi-phone</v-icon></v-btn
                    >
                  </a>
                  <span
                    class="cursor"
                    @click="
                      copyText(profileSchool.about.phone, $t('dashboard.phone'))
                    "
                  >
                    {{ profileSchool.about.phone }}
                  </span>
                </div>
                <div v-if="/.+@.+\..+/.test(profileSchool.about.email)">
                  <a :href="`mailto:${profileSchool.about.email}`">
                    <v-btn small text icon color="red"
                      ><v-icon>mdi-gmail</v-icon></v-btn
                    > </a
                  ><span
                    class="cursor"
                    @click="copyText(profileSchool.about.email, 'Email')"
                  >
                    {{ profileSchool.about.email }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="2">
            <v-card-text v-if="showContactUs()">
              <strong class="pl-1">{{ $t("app.follow_us") }}</strong>
              <v-row no-gutters justify="start">
                <v-btn
                  v-if="validateLink(profileSchool.about.facebook)"
                  text
                  icon
                  small
                  color="primary"
                  @click="openLink(profileSchool.about.facebook)"
                  ><v-icon>mdi-facebook-box</v-icon></v-btn
                >
                <v-btn
                  v-if="validateLink(profileSchool.about.twitter)"
                  text
                  icon
                  small
                  color="light-blue"
                  @click="openLink(profileSchool.about.twitter)"
                  ><v-icon>mdi-twitter-box</v-icon></v-btn
                >
                <v-btn
                  v-if="validateLink(profileSchool.about.instagram)"
                  text
                  icon
                  small
                  color="orange darken-4"
                  @click="openLink(profileSchool.about.instagram)"
                  ><v-icon>mdi-instagram</v-icon></v-btn
                >
                <v-btn
                  v-if="validateLink(profileSchool.about.youtube)"
                  text
                  icon
                  small
                  color="red darken-3"
                  @click="openLink(profileSchool.about.youtube)"
                  ><v-icon>mdi-youtube</v-icon></v-btn
                >
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text
        :class="{ 'grey lighten-2': !g_darkmode }"
        class="text-center"
      >
        <strong>
          {{ profileSchool.about.name }} {{ new Date().getFullYear() }}
          <span class="px-1">-</span>
          {{ appVersion.frontend }} | {{ appVersion.backend }}
        </strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  props: ["appVersion", "g_darkmode"],
  computed: {
    profileSchool() {
      return this.$store.getters.g_school;
    }
  },
  methods: {
    showFollowUs() {
      const wa = this.isPhoneValid(this.profileSchool.about.whatsapp);
      const phone = this.isPhoneValid(this.profileSchool.about.phone);
      const email = /.+@.+\..+/.test(this.profileSchool.about.email);
      return wa || phone || email;
    },
    showContactUs() {
      const fb = this.validateLink(this.profileSchool.about.facebook);
      const twitter = this.validateLink(this.profileSchool.about.twitter);
      const ig = this.validateLink(this.profileSchool.about.instagram);
      const yt = this.validateLink(this.profileSchool.about.youtube);
      return fb || twitter || ig || yt;
    },
    copyText(copyData, text) {
      navigator.clipboard.writeText(copyData);
      this.$store.commit("CALL_SNACKBAR", {
        msg: `${text} ${this.$i18n.t("app.copy_clipboard")}`,
        color: "#36424D"
      });
    },
    isPhoneValid: phone =>
      /^(\+62\\d{8}|\+62\d{9}|\+62\d{10}|\+62\d{11}|\+62\d{12}|0\d{8}|0\d{9}|0\d{10}|0\d{11}|0\d{12})$/.test(
        phone
      ),
    validateLink: url => /^(http|https):\/\/[^ "]+$/.test(url),
    openLink: url => window.open(url, "_blank"),
    openWhatsapp: phone =>
      window.open(
        "https://web.whatsapp.com/send?phone=" +
          (phone.substr(0, 1) === "+" ? "" : "62") +
          phone.slice(1),
        "_blank"
      )
  }
};
</script>

<style lang="scss" scoped>
.sistesi-logo {
  width: 105%;
}
a {
  text-decoration: none !important;
}
.cursor {
  cursor: pointer;
}
</style>
