import store from "@/store";

export function checkPrivilages(name) {
  return store.getters.g_privilages_user.includes(name);
}

// Roles
// 1 Applicant Teacher / Staff
// 2 Applicant Student
// 3 Parent
// 4 Admin
// 5 Teacher
// 6 Student

// Aturan penulisan kode
// Kode awal Huruf pertama menu
// kode ke dua dan tiga, singkatan dari sub menu
// kode angka, urutan action

export const adminActions = [
  "AST01",
  "AST02",
  "AST03",
  "AST04",
  "AST05",
  "AST06",
  "ARG01",
  "ARG02",
  "ARG03",
  "ARG04",
  "ARG05",
  "ARG06",
  // Recap Absent
  "RRC01",
  "RRC02",
  "RRC03",
  "RRC04",
  "RRC05",
  "RRC06",
  "RRC07",
  "RRC08",
  "RRC09",
  // Absent
  "AAS01",
  "AAS02",
  "AAS03",
  "AAS04",
  "AAS05",
  "AAS06",
  "AAS07",
  "AAS08",
  // Elearning
  "ELR01",
  "ELR02",
  "ELR03",
  "ELR04",
  "ELR05",
  "ELR06",
  "ELR07", // filter
  // School Year
  "MSY01", // btn filter school year
  "MSY02", // btn add school year
  "MSY03", // btn view school year
  "MSY04", // btn add event
  "MSY05", // btn edit event
  "MSY06", // btn delete event
  // Score
  "ASC001", // input manually,
  "ASC002", // delete
  // BK (Counceling Guidance)
  "CGE001" // publish button
];

export const teacherActions = [
  // Recap Absent
  "RRC04",
  "RRC05",
  "RRC06",
  "RRC07",
  "RRC08",
  "RRC09",
  // Absent
  "AAS04",
  "AAS05",
  "AAS06",
  "AAS07",
  "AAS08",
  // Elearning
  "ELR01",
  "ELR02",
  "ELR03",
  "ELR04",
  "ELR05",
  "ELR06",
  "ELR07", // filter
  // BK (Counceling Guidance)
  "CGE001", // publish button
  // Score
  "ASC001" // input manually,
];

export const studentActions = [
  // E-learning
  "AEL01"
];
