export default {
  choose_subjects: "Pilih Mata Pelajaran",
  kkm: "KKM",
  semester: "Semester",
  grade: "Pilih Tingkatan",
  added_subjects: "Tambah Mata Pelajaran",
  school_year: "Tahun Ajaran",
  choose_class: "Pilih Kelas",
  subjects: "Mata Pelajaran",
  master_subjects: "Master Mata Pelajaran",
  has_been_used: "telah digunakan",
  cannot_delete: "belum berhasil di hapus karena",
  has_been_relation: "data telah terelasi sebanyak",
  confirm_password:
    "Masukan password anda untuk konfirmasi hapus mata pelajaran",
  schedules: "Jadwal",
  teacher_name: "Nama Guru",
  action: "Aksi",
  teacher: "Guru",
  student: "Siswa",
  added: "Tambah",
  for_class: "Untuk Kelas",
  choose_all_class: "Pilih Semua Kelas",
  all_student: "Semua Siswa",
  some_student: "Sebagian Siswa",
  others: "Lainnya",
  class: "Kelas",
  hours_week: "Berapa kali mengajar dalam seminggu",
  update_subjects: "Edit Mata Pelajaran",
  choose_teacher: "Pilih Guru",
  msg_step_two: "Minimal tambahkan 1 guru untuk melanjutkan",
  sub_subjects: "Sub Mata Pelajaran",
  list_class: "Daftar Kelas",
  msg_response: {
    subjects: {
      success_added: "Berhasil tambah Mata Pelajaran",
      success_edited: "Berhasil ubah Mata Pelajaran",
      success_deleted: "Berhasil hapus Mata Pelajaran",
      confirm_force_delete: "Konfirmasi Hapus Mata Pelajaran"
    },
    master_subjects: {
      success_added: "Berhasil tambah Master Mata Pelajaran",
      success_edited: "Berhasil ubah Master Mata Pelajaran",
      success_deleted: "Berhasil hapus Master Mata Pelajaran",
      info_deleted:
        "Semua data mata pelajaran yang terelasi akan ikut terhapus",
      confirm_force_delete: "Konfirmasi Hapus Master Mata Pelajaran"
    }
  }
};
