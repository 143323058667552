export default {
  table: {
    subject: "Subject",
    kkm: "Minimum Completeness Criteria",
    homeroom: "Homeroom"
  },
  download_student: "List Subject Score",
  to_detail: "To Detail",
  description: "Select a menu to display in the table",
  input_exam: "Input manually",
  midterm_exam_exist: "Midterm exams already exist",
  final_exam_exist: "Final exams already exist",
  form: {
    title: "Title",
    type: "Select Type",
    core_competencies: "Select Core Competencies"
  },
  remove: "Cancel posting",
  confirm: "Are you sure you want to cancel posting your chosen exam?"
};
