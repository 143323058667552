<template>
  <v-card
    id="server-down-dialog-wrapper"
    :style="{
      backgroundColor: alertOptions.background_color,
      color: alertOptions.text_color
    }"
  >
    <v-icon
      :color="alertOptions.text_color"
      small
      class="close_btn"
      @click="closeAlert"
      >mdi-close</v-icon
    >
    <v-card-text>
      <div
        v-if="!$vuetify.breakpoint.xsOnly"
        class="server-down-dialog"
        :style="{
          color: alertOptions.text_color
        }"
      >
        <img
          :src="iconServerDown"
          :lazy-src="iconServerDown"
          style="width: 150px"
        />
        <div style="padding-left: 160px">
          <div v-html="alertOptions.note"></div>
        </div>
      </div>
      <div
        v-if="$vuetify.breakpoint.xsOnly"
        class="server-down-dialog-mob"
        :style="{
          color: alertOptions.text_color
        }"
      >
        <div class="d-flex justify-center">
          <img
            :src="iconServerDown"
            :lazy-src="iconServerDown"
            style="width: 125px;"
          />
        </div>
        <div>
          <div v-html="alertOptions.note"></div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    closeAlert: Function,
    alertOptions: Object
  },
  data() {
    return {
      iconServerDown: require("@/assets/svg/icon_server_down.svg")
    };
  }
};
</script>

<style lang="scss">
#server-down-dialog-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  min-width: 100%;
  min-height: 85px;
  .close_btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .server-down-dialog {
    padding: 0 50px;
    position: relative;
    img {
      position: absolute;
      top: -65px;
    }
  }
  .server-down-dialog-mob {
    text-align: center;
    padding-top: 40px;
    img {
      position: absolute;
      top: -65px;
    }
  }
}
</style>
