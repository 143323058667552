import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const Academic = {
  path: "/academic",
  component: Admin,
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.academic"),
    icon: "sidebar-icon-academic.svg",
    roles: [11, 35, 15, 16, 24, 23, 36, 32, 33, 44, 56, 63, 65, 66]
  },
  children: [
    // {
    //   name: "Schedule",
    //   path: "schedule",
    //   component: () => import("@/views/admin/academic/schedule"),
    //   meta: { roles: [64], title: i18n.t("routes.manage_schedule") }
    // },
    // {
    //   name: "ScheduleStudent",
    //   path: "schedule-student",
    //   component: () => import("@/views/admin/academic/schedule"),
    //   meta: { roles: [9], title: i18n.t("routes.schedule") }
    // },
    // {
    //   name: "ScheduleTeacher",
    //   path: "schedule-teacher",
    //   component: () => import("@/views/admin/academic/schedule"),
    //   meta: { roles: [32], title: i18n.t("routes.my_schedule") }
    // },
    {
      name: "ScheduleZoom",
      path: "schedule-zoom",
      component: () => import("@/views/admin/academic/vidconZoom"),
      meta: { roles: [63], title: i18n.t("routes.manage_schedule") }
    },
    {
      name: "ScheduleZoomStudent",
      path: "schedule-zoom-student",
      component: () => import("@/views/admin/academic/vidconZoom"),
      meta: { roles: [66], title: i18n.t("routes.schedule") }
    },
    {
      name: "ScheduleZoomTeacher",
      path: "schedule-zoom-teacher",
      component: () => import("@/views/admin/academic/vidconZoom/teacher"),
      meta: { roles: [65], title: i18n.t("routes.my_schedule") }
    },
    {
      name: "ELearning",
      path: "e-learning",
      component: () => import("@/views/admin/academic/eLearning"),
      meta: { roles: [35], title: i18n.t("routes.elearning") }
    },
    {
      name: "eTest",
      path: "e-test",
      component: () => import("@/views/admin/academic/eTest"),
      meta: { roles: [23], title: "E-Test" }
    },
    {
      name: "EtestStudent",
      path: "etest-student",
      component: () => import("@/views/admin/academic/eTest/student"),
      meta: { roles: [36], title: "E-Test" }
    },
    {
      name: "EtestDetail",
      path: "etest-detail/:id",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eTest/student/detailEtest/Detail"),
      meta: { roles: [36] }
    },
    {
      name: "Score",
      path: "score",
      component: () => import("@/views/admin/academic/score"),
      meta: { roles: [33], title: i18n.t("routes.score") }
    },
    {
      name: "StudentScore",
      path: "student-score",
      component: () => import("@/views/admin/academic/score/studentScore"),
      meta: { roles: [41], title: i18n.t("routes.score") }
    },
    // {
    //   name: "STPPA",
    //   path: "stppa",
    //   component: () => import("@/views/admin/academic/stppa"),
    //   meta: { roles: [4, 5], title: i18n.t("routes.stppa") }
    // },
    {
      name: "Extracurricular",
      path: "extracurricular",
      component: () => import("@/views/admin/academic/extracurricular"),
      meta: { roles: [11], title: i18n.t("routes.extracurricular.long") }
    },
    {
      name: "ExtracurricularMember",
      path: "extracurricular-member",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/extracurricular/ExtracurricularMember"),
      meta: { roles: [11] }
    },
    {
      name: "ExtracurricularDetail",
      path: "extracurricular-detail/:id",
      hidden: true,
      component: () => import("@/views/admin/academic/extracurricular/Detail"),
      meta: { roles: [11] }
    },
    {
      name: "ELearningDetail",
      path: "e-learning/:year/:grade/:class/:id",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eLearning/ELearningDetail"),
      meta: { roles: [35], title: i18n.t("routes.elearning") }
    },
    {
      name: "ELearningDetailContent",
      path: "e-learning/:year/:grade/:class/:id/:elearning",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eLearning/ELearningDetailContent"),
      meta: { roles: [35], title: i18n.t("routes.elearning") }
    },
    {
      name: "ElearningDetailContentDetail",
      path: "e-learning/:elearning/content/:id",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eLearning/ElearningDetailContentDetail"),
      meta: { roles: [35], title: i18n.t("routes.elearning") }
    },
    {
      name: "BK",
      path: "bk",
      component: () => import("@/views/admin/academic/bk"),
      meta: { roles: [15], title: i18n.t("routes.bk") }
    },
    {
      name: "StudentBK",
      path: "student-bk",
      component: () => import("@/views/admin/academic/bk/bkDetail"),
      meta: { roles: [24], title: i18n.t("routes.bk") }
    },
    {
      name: "BkPoints",
      path: "bk-points",
      hidden: true,
      component: () => import("@/views/admin/academic/bk/bkPoints"),
      meta: { roles: [15], title: i18n.t("routes.bk_points") }
    },
    {
      name: "BkDetails",
      path: "bk-detail",
      hidden: true,
      component: () => import("@/views/admin/academic/bk/bkDetail"),
      meta: { roles: [15], title: i18n.t("routes.bk_detail") }
    },
    {
      name: "UploadDocuments",
      path: "upload-documents",
      component: () => import("@/views/admin/academic/uploadDocuments"),
      meta: { roles: [56], title: i18n.t("routes.upload_documents") }
    },
    {
      name: "WeeklyReport",
      path: "weekly-report",
      component: () => import("@/views/admin/academic/weeklyReport"),
      meta: { roles: [4, 5], title: i18n.t("routes.weekly_report") }
    },
    {
      name: "WeeklyReportDetail",
      path: "weekly-report-detail",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/weeklyReport/WeeklyReportDetail"),
      meta: { roles: [4, 5] }
    },
    {
      name: "QuestionBank",
      path: "question-bank",
      hidden: true,
      component: () => import("@/views/admin/academic/eTest/questionBank"),
      meta: { roles: [23] }
    },
    {
      name: "EtestQuestion",
      path: "etest-question/:id",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eTest/subjectsDetail/question"),
      meta: { roles: [23] }
    },
    {
      name: "QuestionBankEtest",
      path: "question-bank-etest/:etestClass/:lesson",
      hidden: true,
      component: () =>
        import(
          "@/views/admin/academic/eTest/subjectsDetail/question/bankQuestion"
        ),
      meta: { roles: [23] }
    },
    {
      name: "SeeResults",
      path: "see-results/:etestClass",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eTest/subjectsDetail/seeResults"),
      meta: { roles: [23] }
    },
    {
      name: "Statistics",
      path: "statistics/:etestClass",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eTest/subjectsDetail/statistics"),
      meta: { roles: [23] }
    },
    {
      name: "StatisticsDetail",
      path: "statistics-detail/:questionId",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eTest/subjectsDetail/statistics/Detail"),
      meta: { roles: [23] }
    },
    {
      name: "ViewChance",
      path: "view-chance/:etestClass",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eTest/subjectsDetail/chance"),
      meta: { roles: [23] }
    },
    {
      name: "SubjectsDetail",
      path: "subjects-detail/:lesson/:idClass",
      hidden: true,
      component: () => import("@/views/admin/academic/eTest/subjectsDetail"),
      meta: { roles: [23] }
    },
    {
      name: "EtestStudentQuestion",
      path: "etest-student-question/:etestClass",
      hidden: true,
      component: () => import("@/views/admin/academic/eTest/student/Etest"),
      meta: { roles: [36] }
    },
    {
      name: "Remidi",
      path: "remidi/:etestClass",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/eTest/subjectsDetail/remidi"),
      meta: { roles: [23] }
    },
    {
      name: "ScoreDetail",
      path: "score/:lesson/:classroom",
      hidden: true,
      component: () => import("@/views/admin/academic/score/scoreDetail"),
      meta: { roles: [33, 41], title: i18n.t("routes.score") }
    }
  ]
};

export default Academic;
