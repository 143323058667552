export default {
  choose_month: "Pilih Bulan",
  class: "Kelas",
  student_list: "Daftar Siswa",
  class_list_title: "Daftar Kelas",
  class_list_data: {
    class_master: "Master Kelas",
    total_students: "Total Siswa",
    class_name: "Nama Kelas",
    add_class_list: "Tambah Daftar Kelas",
    choose_a_guardian_teacher: "Pilih Guru Wali",
    choose_a_counselor: "Pilih Konselor",
    select_the_active_day: "Pilih hari yang aktif",
    class_type: "Tipe Kelas",
    select_the_time_of_entry: "Pilih jam masuk",
    choose_home_hours: "Pilih jam pulang"
  },
  schedule_title: "Jadwal",
  schedule_data: {
    type_of_class: "Jenis Kelas",
    choose_subjects: "Pilih Mapel",
    add_schedule: "Tambah Jadwal"
  },
  stppa_title: "STPPA",
  stppa_data: {
    age: "Umur",
    month: "Bulan",
    year: "Tahun",
    master_of_development_programs: "Master Program Pengembangan",
    name_of_the_development_program: "Nama Program Pengembangan",
    input_name: "Masukan Nama",
    dialog_stppa_title: "Tambah Standar Tingkat Pencapaian Penrkembangan Anak",
    indicator: "Indikator",
    form1: "Nilai Agama dan Moral",
    form2: "Nilai Motorik",
    form3: "Nilai Kognitif",
    form4: "Nilai Bahasa",
    form5: "Nilai Sosial Emosional",
    form6: "Nilai Seni"
  },
  subjects_title: "Mata Pelajaran",
  master_subjects: "Master Mata Pelajaran",
  subjects_data: {
    dialog_subjects: {
      tab1: {
        form1: "Masukan SUb",
        form2: "Pilih Guru",
        form3: "Pilih Tahun Ajaran & Semester",
        fomr4: "Salin Sub yang sudah ada??"
      },
      tab2: {
        title: "Aspek"
      },
      tab3: {
        title: "Bobot Nilai"
      }
    },
    dialog_master_subjects: {
      form1: "Nama Mata Pelajaran",
      form2: "Pilih Tingkatan",
      tiers: "Tingkatan"
    },
    add_subjects: "Tambah Mata Pelajaran",
    teacher_name: "Nama Guru"
  }
};
