const stppa = {
  state: {
    isDialogStppa: false,
    isDialogMasterStppa: false
  },
  mutations: {
    TOGGLE_DIALOG_STPPA: state => {
      state.isDialogStppa = !state.isDialogStppa;
    },
    TOGGLE_DIALOG_MASTER_STPPA: state => {
      state.isDialogMasterStppa = !state.isDialogMasterStppa;
    }
  }
};

export default stppa;
