export default {
  private_etest: "E-Test bersifat privasi",
  add_question: "Tambah Soal",
  question_bank: "Bank Soal",
  code_of_conduct: "Tata Tertib",
  clarification: "Penjelasan",
  download_question: "Unduh Soal",
  question: "Soal",
  question_type: "Jenis Soal",
  character: "Tipe",
  result: "Hasil",
  information: "Keterangan",
  view_results: "Lihat Nilai",
  view_file: "Lihat File",
  add_to_the_value: "Tambah ke Nilai",
  showed: "Ditunjukan",
  spesification: "Spesifikasi",
  period: "Periode",
  some_class: "Kelas Tertentu",
  start_date: "Tanggal Mulai",
  end_date: "Tanggal Berakhir",
  start_hours: "Jam Mulai",
  the_main_question: "Soal Utama",
  backup_question: "Soal Cadangan",
  select_question_publish: "Pilih Soal yang akan di publish",
  end_hours: "Jam Berakhir",
  all_class: "Semua Kelas",
  all_grade: "Semua Tingkatan",
  question_for_etest: "Pilih Soal untuk E-Test",
  title: "Judul",
  description: "Deskripsi",
  chance: "Kesempatan",
  total_questions: "Jumlah Soal",
  title_dialog_publish: "Konfirmasi Publish",
  total_weight: "Total Bobot",
  total_students: "Total Siswa",
  addressed_to: "Ditujukan kepada",
  type_of_test: "Jenis Test",
  cancel_edit: "Batal ubah",
  add_questions_manually: "Tambah Soal secara Manual",
  upload_excel: "Unggah Data Soal Excel",
  upload_document: "Unggah Dokumen",
  explain: "Penjelasan Soal",
  weight: "Bobot",
  upload_manual: "Unggah Data Soal Manual",
  multiple_choice: "Pilihan Ganda",
  answer: "Jawaban",
  your_answer: "Jawaban Anda",
  write_answers: "Tulis Jawaban",
  basic_competencies: "Kompetensi Dasar",
  subject_tags: "Kata Kunci",
  answer_student_file: "Jawaban Siswa File",
  answer_key: "Kunci Jawaban",
  rule_duration: "Durasi minimal 1 menit",
  rule_chance: "Kesempatan minimal 1 kali",
  publish_etest_result: "Publish hasil Etest",
  msg_answer: "Minimal tambahkan 2 jawaban",
  msg_same_answer: "Jawaban tidak boleh sama",
  msg_answer_key: "Minimal tambahkan 1 kunci jawaban",
  msg_remedi_create: "Minimal tambahkan 1 siswa",
  msg_publish_etest: "Berhasil publish E-Test",
  msg_unpublish_etest: "Berhasil batal publish E-Test",
  msg_update_etest: "Berhasil edit E-Test",
  msg_success_publish_remedy: "Berhasil publish Remidi",
  msg_unpublish_remedy: "Berhasil batal publish Remidi",
  added_question: "Tambah Soal",
  update_question: "Ubah Soal",
  msg_question: "Soal wajib diisi",
  open_file: "Buka Berkas",
  info_answer:
    "Centang untuk menandakan jawaban yang benar (minimal pilih salah satu)",
  mark_correct: "Tandai sebagai jawaban yang benar",
  msg_minimal_question: "Minimal tambahkan 1 Soal",
  select_question_from_etest: "Pilih Soal dari E-Test",
  msg_minimal_class: "Minimal tambahkan 1 Kelas",
  msg_answer_essay: "Isikan Jawaban Essay",
  msg_success_update_question_bank: "Berhasil mengubah bank soal",
  msg_success_create_question_bank: "Berhasil membuat bank soal",
  msg_success_create_etest: "Berhasil membuat E-Test",
  msg_success_delete_etest: "Berhasil hapus E-Test",
  msg_success_delete_question_bank: "Berhasil batal menggunakan Soal",
  confirm_cancel_question: "Apakah anda yakin membatalkan soal ini?",
  cancel_use_question: "Batal menggunakan soal",
  msg_failed_delete_etest: "Gagal hapus E-Test",
  msg_date_etest_required: "Tanggal atau jam wajib diisi",
  msg_time: "Jam tidak boleh sama",
  msg_answer_cannot_empty: "Kunci Jawaban tidak boleh kosong",
  publish_etest: "Terbitkan E-Test",
  publish_remedy: "Terbitkan Remidi",
  copy_coc: "Salin dari Tata Tertib Default",
  total_result: "Hasil Total",
  download_results: "Unduh Hasil",
  good_signal: "Sinyal Bagus",
  test_schedule: "Jadwal Tes",
  remaining_duration: "Sisa Durasi",
  copy_question: "Salin Soal",
  msg_done_etest: "Apakah anda yakin ingin menyelesaikan E-Test ini ?",
  etest_result: "Hasil Etest",
  student_answers: "Jawaban Siswa",
  explanation: "Penjelasan Soal",
  score: "Skor",
  drag: "Pilih file atau seret file ke sini",
  select_lesson: "Pilih Mata Pelajaran",
  question_name: "Nama Soal",
  added_remedy: "Tambah Remidi",
  remedy: "Remidi",
  all_students: "Semua Siswa",
  certain_students: "Siswa Tertentu",
  under: "dibawah",
  value_filter: "Filter Nilai",
  max_upload: "Maksimal 3 File",
  msg_rule_max_score: "Nilai tidak boleh lebih dari maksimal nilai",
  max_score: "Maksimal Skor",
  delete_remedy: "Hapus Remidi",
  delete_etest: "Hapus E-Test",
  msg_delete_remedy: "Apakah anda yakin ingin menghapus remidi ?",
  delete_answer: "Hapus Jawaban",
  copy_to: "Salin ke",
  success_delete_remedy: "Berhasil hapus remedi",
  failed_delete_remedy: "Gagal hapus remedi",
  added_answer: "Tambah Jawaban",
  used_score: "Nilai Terpakai",
  value_posts: "Posting Nilai",
  correction: "Koreksi",
  value_used: "Nilai yang dipakai",
  choose_test: "Pilih Test",
  not_checked_yet: "Belum diperiksa",
  not_checked_yet_dialog: "Belum<br/>diperiksa",
  student_grade_details: "Detail Nilai Siswa",
  msg_posts_value: "Apakah anda yakin ingin posting nilai ini?",
  msg_posts_etest: "Apakah anda yakin ingin posting hasil E-Test ini?",
  msg_success_posts_etest: "Hasil E-Test sudah dapat dilihat siswa",
  msg_type: "Berhasil mengubah tipe",
  finished_working_etest: "Selesai mengerjakan E-Test",
  confirm_update_etest: "Apakah anda yakin ingin mengubah E-Test?",
  e_test_results_published: "Hasil E-test sudah diterbitkan",
  reason_failed: "Alasan gagal",
  created_by: "Dibuat Oleh",
  test_time_is_not_over: "E-Test belum berakhir",
  continuation: "Susulan",
  test_time_is_draft: "E-Test Belum dipublish",
  delete_and_edit_for_creator: "Fungsi hapus dan ubah hanya untuk pembuat soal",
  my_question_bank: "Bank soal saya",
  my_etest: "E-Test saya",
  noaccess_information:
    "Jika menu aksi hanya statistik berarti anda bukan pembuat E-Test / E-Test bersifat privasi",
  msg_excel: {
    info_success: "Data berhasil di upload",
    info_failed: "Data gagal di upload",
    title_dialog_upload: "Upload data selesai",
    info: "Silahkan perbaiki data yang tidak sesuai",
    period: "Kolom periode tidak tersedia",
    grade: "Kolom tingkatan tidak tersedia",
    semester: "Kolom semester tidak tersedia",
    subjects: "Kolom mata pelajaran tidak tersedia",
    question_type: "Kolom jenis soal tidak tersedia",
    question: "Kolom soal tidak tersedia",
    answer: "Kolom jawaban tidak tersedia",
    point: "Kolom bobot tidak tersedia",
    discuss: "Kolom pembahasan soal tidak tersedia",
    keyword: "Kolom kata kunci tidak tersedia",
    success_upload: "Berhasil upload bank soal"
  },
  msg: {
    success_create_remedy: "Berhasil membuat remidi",
    validate_end_date: "Tanggal Berakhir harus diisi",
    validate_end_hours: "Jam Berakhir harus diisi",
    confirm_continuation: "Apakah anda yakin ingin menyimpan susulan E-Test",
    success_continuation: "Berhasil membuat susulan E-Test",
    validate_weight: "Bobot tidak boleh lebih besar dari 100",
    validate_minus: "Bobot tidak boleh minus",
    question_bank_delete: "Berhasil hapus bank soal",
    kd_empty: "Silahkan isi Kompetensi Dasar",
    success_update_kd: "Berhasil memperbarui Kompetensi Dasar",
    max_upload_excel: "Maksimal upload satu file",
    success_copy_questions: "Berhasil salin soal",
    success_update_weight: "Berhasil ubah bobot",
    success_update_chance: "Berhasil ubah kesempatan",
    success_update_duration: "Berhasil ubah durasi",
    validate_update_chance:
      "Total kesempatan harus lebih besar dari sisa kesempatan",
    success_posting_value: "Berhasil posting nilai",
    success_add_question_bank: "Berhasil memilih soal dari bank soal",
    valid_type_upload:
      "File yang dipilih tidak valid, file yang valid dari jenis .xlsx, xls.",
    date_passed: "Waktu untuk mengerjakan etest sudah berlalu",
    date_not_passed:
      "Kesempatan atau durasi test anda sudah habis, silahkan hubungi admin/pengawas test jika ingin menambah kesempatan atau durasi test",
    do_e_test: "Kerjakan E-Test",
    not_yet_test: "Belum saatnya test"
  },
  filter_answer: {
    correct: "Benar",
    incorrect: "Salah",
    not_doing: "Tidak Mengerjakan",
    less_precise: "Kurang Tepat"
  },
  filter: {
    the_highest_score: "Nilai Tertinggi",
    lowest_value: "Lowest Value",
    by_name: "Berdasarkan Nama"
  },
  statistics: "Statistik",
  msg_publish: {
    publish: "Apakah anda yakin akan menerbitkan E-Test ini?",
    unpublish: "Apakah anda yakin akan membatalkan penerbitan E-Test ini?"
  },
  msg_publish_remedy: {
    publish: "Apakah anda yakin akan menerbitkan Remidi ini?",
    unpublish: "Apakah anda yakin akan membatalkan penerbitan Remidi ini?"
  },
  name_test: {
    daily_test: "UH",
    homework: "PR",
    midterm_exam: "UTS",
    semester_exam: "UAS"
  },
  table_header: {
    detail_subjects: {
      title: "Judul",
      create_date: "Tanggal Buat",
      release_date: "Tanggal Rilis",
      end_date: "Tanggal Berakhir",
      test_date: "Tanggal Tes",
      hours: "Jam",
      duration: "Durasi (Menit)",
      status: "Status",
      students_work_on: "Siswa mengerjakan"
    },
    statistics: {
      title: "Judul",
      students_correctly: "Benar",
      students_are_wrong: "Salah",
      not_completing: "Tidak Mengerjakan",
      status_correctly: "Belum dikoreksi (Essay)",
      less_precise: "Kurang Tepat (Essay)"
    },
    chance: {
      name: "Nama",
      chance: "Kesempatan",
      opportunity_missed: "Terpakai",
      remaining_opportunity: "Sisa"
    }
  },
  tabs: {
    first: "Semua",
    second: "Berlangsung",
    third: "Tersedia",
    fourth: "Akan Datang"
  }
};
