import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const Recap = {
  path: "/recap",
  component: Admin,
  redirect: "journal",
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.recap"),
    icon: "sidebar-icon-recap.svg",
    roles: [21, 22, 31, 59, 62]
  },
  children: [
    {
      name: "Journal",
      path: "journal",
      component: () => import("@/views/admin/recap/journal"),
      meta: {
        roles: [21],
        icon: "sidebar-icon-recap.svg",
        title: i18n.t("routes.journal")
      }
    },
    {
      name: "JournalDetail",
      path: "journal-detail/:lesson/:school_year/:grade/:class",
      hidden: true,
      component: () => import("@/views/admin/recap/journal/JournalDetail"),
      meta: {
        roles: [21],
        icon: "sidebar-icon-recap.svg",
        title: i18n.t("routes.journal")
      }
    },
    {
      name: "RecapAbsent",
      path: "recap_absent",
      component: () => import("@/views/admin/absent/recapAbsent"),
      meta: {
        roles: [22],
        title: i18n.t("routes.recap_absent"),
        icon: "sidebar-icon-recap.svg"
      }
    },
    {
      name: "DetailRecapAbsencePerSubject",
      path: "detail-recap-absent/:id",
      hidden: true,
      component: () =>
        import("@/views/admin/absent/recapAbsent/DetailRecapAbsent"),
      meta: {
        roles: [22]
      }
    },
    // {
    //   name: "AbsencePerSubject",
    //   path: "absence-per-subject",
    //   component: () => import("@/views/admin/recap/absenceSubject"),
    //   meta: {
    //     roles: [62],
    //     title: i18n.t("routes.absence_per_subject"),
    //     icon: "sidebar-icon-recap.svg"
    //   }
    // },
    {
      name: "ReportCard",
      path: "report-card",
      component: () => import("@/views/admin/academic/reportCard"),
      meta: { roles: [59], title: i18n.t("routes.report_card") }
    },
    {
      name: "ReportCardDetail",
      path: "report-card/:id/:year/:grade/:class",
      hidden: true,
      component: () =>
        import("@/views/admin/academic/reportCard/ReportCardDetails"),
      meta: { roles: [59], title: i18n.t("routes.report_card") }
    }
  ]
};

export default Recap;
