import request from "@/utils/request";

function get_master_data() {
  return request({
    method: "post",
    url: "master_ppdb"
  });
}

const getPosition = () =>
  request({
    method: "post",
    url: "employee/list_position"
  });

const masterPlace = () =>
  request({
    method: "post",
    url: "master_place"
  });

const masterGrade = () =>
  request({
    method: "post",
    url: "master_grade"
  });

export { get_master_data, getPosition, masterPlace, masterGrade };
