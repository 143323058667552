<template>
  <div id="homepage-app">
    <!-- Navbar -->
    <v-navigation-drawer
      v-if="sidebarHome && $vuetify.breakpoint.smAndDown"
      style="position:fixed; top:0; left:0; overflow-y:scroll;"
      v-model="sidebarHome"
      app
      fixed
      temporary
      persistent
    >
      <v-list dense class="py-0">
        <v-list-item-title class="primary pa-5 white--text font-weight-bold">
          {{ g_school.about.name }}
        </v-list-item-title>

        <v-divider />

        <v-list-item
          v-for="item in g_token ? itemsLogin : items"
          :key="item.title"
          link
          @click="item.path ? directTo(item.path) : actionHandler(item.action)"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="item.title" class="font-weight-bold">
                {{ $t(item.title) }}
              </span>
              <span v-else class="font-weight-bold">
                {{ g_user.fullname }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- App Bar -->
    <v-app-bar flat app>
      <v-container>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <v-app-bar-nav-icon
            style="position: absolute; top: 5px; left: 0;"
            @click.stop="sidebarHome = !sidebarHome"
          />
          <img
            :src="g_school.about.path_potrait"
            id="logo-sistesi"
            class="mx-auto"
            style="height:50px; width: auto;"
            @click="toHomePage"
          />
        </v-row>
        <v-row v-else class="align-center">
          <img
            :src="g_school.about.path_potrait"
            id="logo-sistesi"
            style="height:50px; width: auto;"
            @click="toHomePage"
          />
          <div>
            <v-btn
              text
              small
              class="font-weight-bold"
              @click="directTo('#profile')"
            >
              {{ $t("app.profile") }}
            </v-btn>
            <v-btn
              text
              small
              class="font-weight-bold"
              @click="directTo('#calendar')"
            >
              {{ $t("app.calendar") }}
            </v-btn>
            <v-btn
              text
              small
              class="font-weight-bold"
              @click="directTo('#announcement')"
            >
              {{ $t("app.announcement") }}
            </v-btn>
            <v-btn
              text
              small
              class="font-weight-bold"
              @click="directTo('#news')"
            >
              {{ $t("app.news") }}
            </v-btn>
          </div>
          <v-btn
            v-if="g_token"
            text
            small
            class="font-weight-bold"
            @click="$router.push({ name: 'Inspiration' })"
          >
            {{ $t("app.inspiration") }}
          </v-btn>
          <v-btn
            v-if="g_token"
            text
            small
            class="font-weight-bold"
            @click="$router.push({ name: 'Ebook' })"
          >
            {{ $t("app.ebook") }}
          </v-btn>
          <v-spacer />
          <section v-if="g_token">
            <v-btn
              text
              small
              class="font-weight-bold"
              @click="
                g_user.role_type == 'ADMIN'
                  ? $router.push({ name: 'Dashboard' })
                  : $router.push({ path: '/' })
              "
            >
              {{ g_user.fullname }}
            </v-btn>
            <v-btn text small class="font-weight-bold" @click="logoutHandler">
              {{ $t("app.logout") }}
            </v-btn>
          </section>
          <section v-else>
            <v-btn text small class="font-weight-bold" @click="actionHandler">
              {{ $t("app.registration") }}
            </v-btn>
            <v-btn
              text
              small
              class="font-weight-bold"
              @click="$router.push('/login')"
            >
              {{ $t("app.login") }}
            </v-btn>
          </section>
        </v-row>
        <ModalPendaftaran
          :modal-pendaftaran="g_modal_pendaftaran"
          :modal-pendaftaran-handler="actionHandler"
        />
      </v-container>
    </v-app-bar>

    <!-- Main Route -->
    <v-main>
      <router-view />
    </v-main>

    <!-- Footer -->
    <Footer :app-version="g_version" :g_darkmode="g_darkmode" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "./components/Footer";
import ModalPendaftaran from "@/components/ModalPendaftaran";

export default {
  name: "Navbar",
  components: {
    Footer,
    ModalPendaftaran
  },
  computed: {
    ...mapGetters([
      "g_modal_pendaftaran",
      "g_full_loading",
      "g_version",
      "g_user",
      "g_school",
      "g_token",
      "g_darkmode",
      "g_school"
    ])
  },
  created() {
    this.$store.dispatch("SchoolProfile");
  },
  data() {
    return {
      sidebarHome: false,
      items: [
        { title: "app.profile", path: "#profile" },
        { title: "app.calendar", path: "#calendar" },
        { title: "app.announcement", path: "#announcement" },
        { title: "app.news", path: "#news" },

        { title: "app.registration", action: "TOGGLE_MODAL_PENDAFTARAN" },
        { title: "app.login", action: "login" }
      ],
      itemsLogin: [
        { title: "app.profile", path: "#profile" },
        { title: "app.calendar", path: "#calendar" },
        { title: "app.announcement", path: "#announcement" },
        { title: "app.news", path: "#news" },

        { action: "dashboard" },
        { title: "app.inspiration", action: "inspiration" },
        { title: "app.ebook", action: "ebook" },
        { title: "app.logout", action: "logout" }
      ]
    };
  },
  methods: {
    actionHandler(param) {
      switch (param) {
        case "login":
          this.$router.push("/login");
          break;
        case "logout":
          this.logoutHandler();
          break;
        case "dashboard":
          this.$router.push("/profile");
          break;
        case "inspiration":
          this.$router.push("/inspiration");
          break;
        case "ebook":
          this.$router.push("/ebook");
          break;
        default:
          this.$store.commit("TOGGLE_MODAL_PENDAFTARAN");
          break;
      }
    },
    toHomePage() {
      if (this.$router.currentRoute.name !== "Homepage") {
        this.$router.push("/homepage");
      }
      this.$vuetify.goTo(0);
    },
    directTo(param) {
      if (this.$router.currentRoute.name !== "Homepage") {
        this.$router.replace("/homepage");
        setTimeout(() => {
          this.$vuetify.goTo(param);
        }, 200);
      } else {
        this.$vuetify.goTo(param);
      }
    },
    logoutHandler() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#homepage-app {
  height: 100vh;
}

#logo-sistesi {
  width: 60px;
  margin-right: 10px;
}
</style>
