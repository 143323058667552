export default {
  title: "Pilih Lokasi Sekolah",
  title2: "Tambah Tahun Ajaran",
  title3: "Ubah Tahun Ajaran",
  school_address: "Alamat Sekolah",
  select_active_days: "Pilih hari aktif",
  select_school_year: "Pilih Tahun Ajaran",
  semester: "Semester",
  start_semester: "Awal Semester",
  end_semester: "Akhir Semester",
  start_hour: "Pilih jam masuk",
  end_hour: "Pilih jam pulang",
  add_event: "Tambah Kegiatan / Libur",
  edit_event: "Ubah Kegiatan / Libur",
  select_one: "Pilih salah satu",
  school_year_list: "List Tahun Ajaran",
  information: "Keterangan",
  select_one_type: "Pilih jenis Kegiatan",
  confirm_delete_not_lastid:
    "Silahkan hapus tahun ajaran sesuai urutan terbaru",
  response: {
    success: "Berhasil tambah tahun ajaran",
    success_edit: "Berhasil ubah tahun ajaran",
    success_delete: "Berhasil hapus tahun ajaran",
    success_edit_event: "Berhasil ubah kegiatan",
    success_add_event: "Berhasil tambah kegiatan",
    success_delete_event: "Berhasil hapus kegiatan"
  },
  header: {
    school_year: "Tahun Ajaran",
    select_class: "Pilih Tingkatan",
    class_type: "Jenis Kelas",
    select_month: "Pilih Bulan",
    select_elearning: "Pilih Elearning",
    select_type: "Pilih Tipe File"
  },
  event: {
    date_from: "Dari Tanggal",
    date_to: "Sampai Tanggal",
    add_holiday: "Tambah Libur",
    add_event: "Tambah Kegiatan",
    random_color: "Warna Otomatis",
    select_color: "Pilih Warna",
    select_grade: "Pilih Tingkatan",
    class_type: "Jenis Kelas",
    no_event: "Tidak ada kegiatan pada tanggal yang dipilih"
  },
  table_header: {
    start_year: "Tahun Awal",
    end_year: "Tahun Akhir",
    semester: "Semester",
    start_date: "Mulai",
    end_date: "Selesai",
    action: "Aksi"
  },
  upcoming_school_year: "Tahun Ajaran yang akan datang",
  current_school_year: "Tahun Ajaran saat ini"
};
