<template>
  <div>
    <v-dialog v-model="g_modal_change_password" persistent max-width="400">
      <v-card>
        <v-card-title class="headline gradient-primary white--text">{{
          $t("app.change_password")
        }}</v-card-title>

        <v-card-text class="mt-3 pb-2">
          <v-form
            ref="formChangePassword"
            v-model="valid"
            lazy-validation
            class="mt-6"
          >
            <v-text-field
              v-model="password.current_password"
              :label="$t('app.password_old')"
              :append-icon="spOld ? 'mdi-eye' : 'mdi-eye-off'"
              :type="spOld ? 'text' : 'password'"
              :rules="[v => !!v || $t('app.required')]"
              v-on:keyup.enter="validate"
              autocomplete
              outlined
              dense
              @click:append="spOld = !spOld"
            />
            <v-text-field
              v-model="password.new_password"
              :label="$t('app.password_new')"
              :append-icon="spNew ? 'mdi-eye' : 'mdi-eye-off'"
              :type="spNew ? 'text' : 'password'"
              :rules="[v => !!v || $t('app.required')]"
              v-on:keyup.enter="validate"
              autocomplete
              outlined
              dense
              @click:append="spNew = !spNew"
            />
            <v-text-field
              v-model="password.new_password_confirm"
              :label="$t('app.password_confirm')"
              :append-icon="spConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="spConfirm ? 'text' : 'password'"
              :rules="[
                v => !!v || $t('app.required'),
                v =>
                  v == password.new_password || $t('app.password_confirm_rules')
              ]"
              v-on:keyup.enter="validate"
              autocomplete
              outlined
              dense
              @click:append="spConfirm = !spConfirm"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="pr-4 pb-3">
            <v-btn outlined color="primary" @click="close" class="mr-2">{{
              $t("app.cancel")
            }}</v-btn>
            <v-btn
              class="gradient-primary"
              :disabled="!valid"
              :loading="loadingChangePassword"
              dark
              depressed
              @click="validate"
              >{{ $t("app.save") }}</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      valid: true,
      spOld: false,
      spNew: false,
      spConfirm: false,
      error: "",
      loadingChangePassword: false,
      password: {
        current_password: "",
        new_password: "",
        new_password_confirm: ""
      }
    };
  },
  computed: {
    ...mapGetters(["g_modal_change_password"])
  },
  methods: {
    close() {
      this.$store.commit("TOGGLE_CHANGE_PASSWORD");
      this.$refs.formChangePassword.reset();
    },
    validate() {
      if (this.$refs.formChangePassword.validate()) {
        this.loadingChangePassword = true;
        this.$store
          .dispatch("toggleModalChangePassword", this.password)
          .then(r => {
            if (r) {
              this.password = {
                current_password: "",
                new_password: "",
                new_password_confirm: ""
              };
            }
            this.loadingChangePassword = false;
          })
          .catch(() => (this.loadingChangePassword = false));
      }
    }
  }
};
</script>
