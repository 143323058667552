export default {
  choose_subjects: "Choose Subjects",
  kkm: "Minimum Completeness Criteria",
  semester: "Semester",
  grade: "Grade",
  added_subjects: "Add Subjects",
  school_year: "School Year",
  choose_class: "Choose Class",
  subjects: "Subjects",
  master_subjects: "Master Subjects",
  has_been_used: "has been used",
  cannot_delete: "has not been successfully removed because",
  has_been_relation: "data has been related as much",
  schedules: "Schedules",
  confirm_password: "Enter your password to confirm deleting subjects",
  teacher_name: "Teacher Name",
  action: "Action",
  teacher: "Teacher",
  student: "Student",
  added: "Add",
  for_class: "For Class",
  choose_all_class: "Choose All Class",
  others: "others",
  hours_week: "How many times a week teaches",
  update_subjects: "Edit Subjects",
  class: "Class",
  choose_teacher: "Choose Teacher",
  msg_step_two: "At least add 1 teacher to continue",
  all_student: "All Student",
  some_student: "Some Student",
  sub_subjects: "Sub Subjects",
  list_class: "List Class",
  msg_response: {
    subjects: {
      success_added: "Success add subjects",
      success_edited: "Success edit subjects",
      success_deleted: "Success delete subjects",
      confirm_force_delete: "Confirm Delete Subject"
    },
    master_subjects: {
      success_added: "Success add Master subjects",
      success_edited: "Success edit Master subjects",
      success_deleted: "Success delete Master subjects",
      info_deleted: "All related subject data will also be erased",
      confirm_force_delete: "Confirm Delete Master Subject"
    }
  }
};
