export default {
  select_class: "Select Class",
  select_grade: "Select Grade",
  select_months: "Select Months",
  detail_absent: "Detail Absen",
  search_valid_rules: "Classes and Grade must be filled",
  student: "Student",
  teacher: "Teacher",
  staff: "Staff",
  date: "Date",
  present: "Present",
  schedule: "Schedule",
  sick: "Sick",
  permission: "Permission",
  duration: "Duration",
  students_registered: "Students Registered",
  students_not_registered: "Students are not Registered",
  dialog: {
    name: "Name",
    absent_info: "Absent Info",
    hour_in: "Hour in",
    hour_out: "Hour out"
  },
  table: {
    name: "Name",
    sick: "S",
    permission: "P",
    alpha: "A",
    present: "H",
    late: "T"
  },
  info_color: {
    black: "Holiday / Nothing KBM",
    red: "Alpha",
    purple: "Go Home Early",
    purple_darken: "Late Permit",
    orange: "Late",
    green: "Card Absent",
    light_green: "Manual Absent",
    blue: "Permission",
    yellow: "Sick"
  }
};
