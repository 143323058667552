import { insert_ppdb_siswa } from "@/api/homepage/ppdb";
import { uploadFile, deleteFile } from "@/api/admin/admin";

import { sliceAwsPath } from "@/utils/uploadS3";

function initialState() {
  return {
    student: {
      id: 0,
      person: 0,
      institution: "",
      nis: "",
      uid: "",
      distance: "",
      created_at: "",
      updated_at: "",
      deleted_at: ""
    },
    id_sekolah: window.id_school,
    auto_accept: "",
    siswa: {
      completed: true,
      open_panel: [],
      data_diri: {
        foto: null,
        nama_lengkap: "",
        nama_panggilan: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        jenis_kelamin: "",
        agama: "",
        kewarganegaraan: "",
        bahasa: "",
        nik: "",
        no_kk: "",
        no_hp: "",
        email: "",
        informasi_lainnya: "",
        username: ""
      },
      jurusan_siswa: {
        major_primary: null,
        major_secondary: []
      },
      alamat_siswa: {
        latlong: "",
        alamat: "",
        provinsi: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        rt: "",
        rw: "",
        kode_pos: "",
        telepon_rumah: "",
        anak_ke: "",
        jumlah_saudara: "",
        tinggal_bersama: ""
      },
      data_keluarga: [
        {
          nama: "",
          semester: "",
          sekolah: ""
        }
      ],
      data_kesehatan: {
        berat: "",
        tinggi: "",
        golongan_darah: "",
        riwayat: "",
        jarak: "",
        catatan: ""
      },
      pendidikan_sebelumnya: {
        asal: "",
        no_pokok_sekolah: "",
        alamat_sekolah: "",
        no_induk_sekolah: "",
        nisn: "",
        tahun_lulus: "",
        no_ijazah: "",
        dokumen: []
      }
    },
    orang_tua: {
      completed: true,
      open_panel: [],
      data_ayah: {
        nama: "",
        tempat_lahir: "",
        kewarganegaraan: "",
        pekerjaan: "",
        telepon: "",
        hubungan_dengan_anak: "",
        tanggal_lahir: "",
        pendidikan_terakhir: "",
        penghasilan_perbulan: "",
        email: "",
        agama: "",
        informasi_lainnya: ""
      },
      alamat_ayah: {
        latlong: "",
        alamat: "",
        provinsi: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        rt: "",
        rw: "",
        kode_pos: "",
        telepon_rumah: ""
      },
      data_ibu: {
        nama: "",
        tempat_lahir: "",
        kewarganegaraan: "",
        pekerjaan: "",
        telepon: "",
        hubungan_dengan_anak: "",
        tanggal_lahir: "",
        pendidikan_terakhir: "",
        penghasilan_perbulan: "",
        email: "",
        agama: "",
        informasi_lainnya: ""
      },
      alamat_ibu: {
        latlong: "",
        alamat: "",
        provinsi: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        rt: "",
        rw: "",
        kode_pos: "",
        telepon_rumah: ""
      }
    },
    wali: {
      isOrtu: false,
      isFatherOrMother: "",
      completed: true,
      open_panel: [],
      data_wali: {
        nama: "",
        tempat_lahir: "",
        kewarganegaraan: "",
        pekerjaan: "",
        telepon: "",
        hubungan_dengan_anak: "",
        tanggal_lahir: "",
        pendidikan_terakhir: "",
        penghasilan_perbulan: "",
        email: "",
        agama: "",
        informasi_lainnya: ""
      },
      alamat_wali: {
        latlong: "",
        alamat: "",
        provinsi: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        rt: "",
        rw: "",
        kode_pos: "",
        telepon_rumah: ""
      }
    },
    pernyataan: {
      isAgree: false
    }
  };
}

const ppdbSiswa = {
  state: initialState,
  mutations: {
    SET_PANEL: (state, param) => {
      state[param.tab].open_panel = param.val;
    },
    SET_COMPLETE_TAB: (state, data) => {
      state[data.param].completed = data.status;
    },
    SET_DATA_WALI: (state, param) => {
      switch (param) {
        case "ayah":
          state.wali.data_wali = state.orang_tua.data_ayah;
          state.wali.alamat_wali = state.orang_tua.alamat_ayah;
          break;
        default:
          state.wali.data_wali = state.orang_tua.data_ibu;
          state.wali.alamat_wali = state.orang_tua.alamat_ibu;
          break;
      }
    },
    SET_WALI_IS_ORTU: state => {
      if (!state.wali.isOrtu) {
        state.wali.isFatherOrMother = "";
        state.wali.data_wali = {
          nama: "",
          tempat_lahir: "",
          kewarganegaraan: "",
          pekerjaan: "",
          telepon: "",
          hubungan_dengan_anak: "",
          tanggal_lahir: "",
          pendidikan_terakhir: "",
          penghasilan_perbulan: "",
          email: "",
          agama: "",
          informasi_lainnya: ""
        };
        state.wali.alamat_wali = {
          latlong: "",
          alamat: "",
          provinsi: "",
          kota: "",
          kecamatan: "",
          kelurahan: "",
          rt: "",
          rw: "",
          kode_pos: "",
          telepon_rumah: ""
        };
      }
    },
    PPDB_SET_ALAMAT_SISWA: (state, data) => {
      state.siswa.alamat_siswa.alamat = data.Address.Label;
      state.siswa.alamat_siswa.provinsi = data.Address.County;
      state.siswa.alamat_siswa.kode_pos = data.Address.PostalCode;
      state.siswa.alamat_siswa.kota = data.Address.City;
      state.siswa.alamat_siswa.negara = data.Address.AdditionalData[0].value;
      state.siswa.alamat_siswa.kelurahan = data.Address.Subdistrict;
      state.siswa.alamat_siswa.kecamatan = data.Address.District;
      state.siswa.alamat_siswa.latlong = data.DisplayPosition;
    },
    PPDB_RESET_ALAMAT_SISWA: state => {
      state.siswa.alamat_siswa.alamat = "";
      state.siswa.alamat_siswa.provinsi = "";
      state.siswa.alamat_siswa.kode_pos = "";
      state.siswa.alamat_siswa.kota = "";
      state.siswa.alamat_siswa.negara = "";
      state.siswa.alamat_siswa.kelurahan = "";
      state.siswa.alamat_siswa.kecamatan = "";
      state.siswa.alamat_siswa.latlong = "";
    },
    PPDB_SET_ALAMAT_ORTU: (state, data) => {
      switch (data.type) {
        case "ayah":
          state.orang_tua.alamat_ayah.alamat = data.val.Address.Label;
          state.orang_tua.alamat_ayah.provinsi = data.val.Address.County;
          state.orang_tua.alamat_ayah.kode_pos = data.val.Address.PostalCode;
          state.orang_tua.alamat_ayah.kota = data.val.Address.City;
          state.orang_tua.alamat_ayah.kelurahan = data.val.Address.Subdistrict;
          state.orang_tua.alamat_ayah.kecamatan = data.val.Address.District;
          state.orang_tua.alamat_ayah.latlong = data.val.DisplayPosition;
          break;
        default:
          state.orang_tua.alamat_ibu.alamat = data.val.Address.Label;
          state.orang_tua.alamat_ibu.provinsi = data.val.Address.County;
          state.orang_tua.alamat_ibu.kode_pos = data.val.Address.PostalCode;
          state.orang_tua.alamat_ibu.kota = data.val.Address.City;
          state.orang_tua.alamat_ibu.kelurahan = data.val.Address.Subdistrict;
          state.orang_tua.alamat_ibu.kecamatan = data.val.Address.District;
          state.orang_tua.alamat_ibu.latlong = data.val.DisplayPosition;
          break;
      }
    },
    PPDB_RESET_ALAMAT_ORTU: (state, data) => {
      switch (data.type) {
        case "ayah":
          state.orang_tua.alamat_ayah.alamat = "";
          state.orang_tua.alamat_ayah.provinsi = "";
          state.orang_tua.alamat_ayah.kode_pos = "";
          state.orang_tua.alamat_ayah.kota = "";
          state.orang_tua.alamat_ayah.kelurahan = "";
          state.orang_tua.alamat_ayah.kecamatan = "";
          state.orang_tua.alamat_ayah.latlong = "";
          break;
        default:
          state.orang_tua.alamat_ibu.alamat = "";
          state.orang_tua.alamat_ibu.provinsi = "";
          state.orang_tua.alamat_ibu.kode_pos = "";
          state.orang_tua.alamat_ibu.kota = "";
          state.orang_tua.alamat_ibu.kelurahan = "";
          state.orang_tua.alamat_ibu.kecamatan = "";
          state.orang_tua.alamat_ibu.latlong = "";
          break;
      }
    },
    PPDB_SET_ALAMAT_WALI: (state, data) => {
      state.wali.alamat_wali.alamat = data.Address.Label;
      state.wali.alamat_wali.provinsi = data.Address.County;
      state.wali.alamat_wali.kode_pos = data.Address.PostalCode;
      state.wali.alamat_wali.kota = data.Address.City;
      state.wali.alamat_wali.kelurahan = data.Address.Subdistrict;
      state.wali.alamat_wali.kecamatan = data.Address.District;
      state.wali.alamat_wali.latlong = data.DisplayPosition;
    },
    PPDB_RESET_ALAMAT_WALI: state => {
      state.wali.alamat_wali.alamat = "";
      state.wali.alamat_wali.provinsi = "";
      state.wali.alamat_wali.kode_pos = "";
      state.wali.alamat_wali.kota = "";
      state.wali.alamat_wali.kelurahan = "";
      state.wali.alamat_wali.kecamatan = "";
      state.wali.alamat_wali.latlong = "";
    },
    PPDB_CP_ALAMAT_AYAH: (state, isTrue) => {
      switch (isTrue) {
        case true:
          state.orang_tua.alamat_ibu.alamat =
            state.orang_tua.alamat_ayah.alamat;
          state.orang_tua.alamat_ibu.provinsi =
            state.orang_tua.alamat_ayah.provinsi;
          state.orang_tua.alamat_ibu.kode_pos =
            state.orang_tua.alamat_ayah.kode_pos;
          state.orang_tua.alamat_ibu.kota = state.orang_tua.alamat_ayah.kota;
          state.orang_tua.alamat_ibu.rt = state.orang_tua.alamat_ayah.rt;
          state.orang_tua.alamat_ibu.rw = state.orang_tua.alamat_ayah.rw;
          state.orang_tua.alamat_ibu.kelurahan =
            state.orang_tua.alamat_ayah.kelurahan;
          state.orang_tua.alamat_ibu.kecamatan =
            state.orang_tua.alamat_ayah.kecamatan;
          state.orang_tua.alamat_ibu.latlong =
            state.orang_tua.alamat_ayah.latlong;
          state.orang_tua.alamat_ibu.telepon_rumah =
            state.orang_tua.alamat_ayah.telepon_rumah;
          break;
        default:
          state.orang_tua.alamat_ibu.alamat = "";
          state.orang_tua.alamat_ibu.provinsi = "";
          state.orang_tua.alamat_ibu.kode_pos = "";
          state.orang_tua.alamat_ibu.kota = "";
          state.orang_tua.alamat_ibu.rt = "";
          state.orang_tua.alamat_ibu.rw = "";
          state.orang_tua.alamat_ibu.kelurahan = "";
          state.orang_tua.alamat_ibu.kecamatan = "";
          state.orang_tua.alamat_ibu.latlong = "";
          state.orang_tua.alamat_ibu.telepon_rumah = "";
          break;
      }
    },
    PPDB_CP_ALAMAT_SISWA: (state, isTrue) => {
      switch (isTrue) {
        case true:
          state.orang_tua.alamat_ayah.alamat = state.siswa.alamat_siswa.alamat;
          state.orang_tua.alamat_ayah.provinsi =
            state.siswa.alamat_siswa.provinsi;
          state.orang_tua.alamat_ayah.kode_pos =
            state.siswa.alamat_siswa.kode_pos;
          state.orang_tua.alamat_ayah.kota = state.siswa.alamat_siswa.kota;
          state.orang_tua.alamat_ayah.rt = state.siswa.alamat_siswa.rt;
          state.orang_tua.alamat_ayah.rw = state.siswa.alamat_siswa.rw;
          state.orang_tua.alamat_ayah.kelurahan =
            state.siswa.alamat_siswa.kelurahan;
          state.orang_tua.alamat_ayah.kecamatan =
            state.siswa.alamat_siswa.kecamatan;
          state.orang_tua.alamat_ayah.latlong =
            state.siswa.alamat_siswa.latlong;
          state.orang_tua.alamat_ayah.telepon_rumah =
            state.siswa.alamat_siswa.telepon_rumah;
          break;
        default:
          state.orang_tua.alamat_ayah.alamat = "";
          state.orang_tua.alamat_ayah.provinsi = "";
          state.orang_tua.alamat_ayah.kode_pos = "";
          state.orang_tua.alamat_ayah.kota = "";
          state.orang_tua.alamat_ayah.rt = "";
          state.orang_tua.alamat_ayah.rw = "";
          state.orang_tua.alamat_ayah.kelurahan = "";
          state.orang_tua.alamat_ayah.kecamatan = "";
          state.orang_tua.alamat_ayah.latlong = "";
          state.orang_tua.alamat_ayah.telepon_rumah = "";
          break;
      }
    },
    RESET_PPDB_SISWA: state => {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    AUTO_ACCEPT_STUDENT: state => {
      state.auto_accept = "TRUE";
    },
    SET_DATA_STUDENT: (state, data) => {
      const majorPrimary = data.siswa.jurusan_siswa.major_primary;
      const majorSecondary = data.siswa.jurusan_siswa.major_secondary;

      state.student = data.isApplicant
        ? { ...data.applicant, nis: data.applicant.nip_or_nis }
        : data.student;
      // Data Diri
      state.siswa.data_diri = data.siswa.data_diri;
      state.siswa.open_panel = [0, 1, 2, 3, 4];
      state.siswa.alamat_siswa = data.siswa.alamat_siswa;
      state.siswa.data_keluarga =
        data.siswa.data_keluarga.length > 0
          ? data.siswa.data_keluarga
          : state.siswa.data_keluarga;
      state.siswa.data_kesehatan = data.siswa.data_kesehatan;
      state.siswa.jurusan_siswa.major_primary = majorPrimary;
      state.siswa.jurusan_siswa.major_secondary = majorSecondary.map(v => ({
        ...v,
        name: v.label
      }));
      state.siswa.pendidikan_sebelumnya = data.siswa.pendidikan_sebelumnya;
      // handle null data
      state.siswa.pendidikan_sebelumnya.nisn =
        data.siswa.pendidikan_sebelumnya.nisn ?? "";

      // Orang Tua
      state.orang_tua.data_ayah = data.siswa.orang_tua.data_ayah;
      state.orang_tua.open_panel = [0, 1, 2, 3];
      state.orang_tua.alamat_ayah = data.siswa.orang_tua.alamat_ayah;
      state.orang_tua.data_ibu = data.siswa.orang_tua.data_ibu;
      state.orang_tua.alamat_ibu = data.siswa.orang_tua.alamat_ibu;

      // Wali
      state.wali.data_wali = data.siswa.orang_tua.wali.data_wali;
      state.wali.open_panel = [0, 1];
      state.wali.alamat_wali = data.siswa.orang_tua.wali.alamat_wali;
      state.wali.isFatherOrMother = data.siswa.orang_tua.wali.isFatherOrMother;
      state.wali.isOrtu = data.siswa.orang_tua.wali.isOrtu;

      // Jurusan
      if (majorPrimary !== null) {
        state.siswa.jurusan_siswa.major_primary = {
          ...majorPrimary,
          name: majorPrimary.label
        };
      }
    },
    SET_DOCUMENT_STUDENT: (state, data) => {
      state.siswa.pendidikan_sebelumnya.dokumen.push(data);
    },
    SET_DOCUMENT_DELETE_STUDENT: state => {
      state.siswa.pendidikan_sebelumnya.dokumen = [];
    }
  },
  actions: {
    insertPpdbSiswa({ commit }, data) {
      return new Promise((resolve, reject) => {
        insert_ppdb_siswa(data)
          .then(res => {
            if (res.data.code) {
              // commit("RESET_PPDB_SISWA");
              commit("");
            }
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteImageStudent({ commit }, data) {
      return new Promise(async resolve => {
        await Promise.all(
          data.map(async p => {
            const path = await sliceAwsPath(p);
            await deleteFile(path, "REGISTRATION");
          })
        );

        commit("SET_DOCUMENT_DELETE_STUDENT");
        resolve(true);
      });
    },
    uploadImageStudent({ commit }, data) {
      return new Promise(async resolve => {
        if (data.length > 0) {
          await Promise.all(
            data.map(async img => {
              let bodyImage = new FormData();
              bodyImage.set("path", "master_data");
              bodyImage.set("module", "REGISTRATION");
              bodyImage.append("file", img);

              const res = await uploadFile(bodyImage);

              commit("SET_DOCUMENT_STUDENT", res.data.data.path);
            })
          );
          resolve();
        }
      });
    }
  }
};

export default ppdbSiswa;
