export default {
  form_title_edit: "Ubah Bidang Keahlian / Jurusan",
  form_title_add: "Tambah Bidang Keahlian / Jurusan",
  major: "Bidang Keahlian",
  expertise_program: "Program Keahlian",
  expertise_competency: "Kompetensi Keahlian",
  select_all_major:
    "Aktifkan tombol '{allMajor}', Jika disekolah tersebut memiliki semua jurusan dibawah.",
  all_major: "Semua Jurusan",
  major_not_found: "Jurusan Belum ada!",
  success_setmajor: "Berhasil mengatur jurusan",
  success_add: "Berhasil menambahkan jurusan baru",
  success_edit: "Berhasil merubah jurusan",
  success_delete: "Berhasil menghapus jurusan"
};
