export default {
  school_year: "Tahun Ajaran",
  class: "Pilih Kelas",
  class_type: "Jenis Kelas",
  date: "Pilih Tanggal",
  add_journal: "Tambah Jurnal",
  edit_journal: "Ubah Jurnal",
  confirm_delete_title: "Hapus Jurnal",
  confirm_delete: "Apakah kamu yakin akan menghapus jurnal ini?",
  table: {
    title: "Judul",
    date: "Tanggal",
    hour: "Jam Ke",
    subject: "Mata Pelajaran",
    supporting_teacher: "Guru Pengampu",
    theory: "Materi",
    information: "Keterangan"
  }
};
