export default {
  school_logo: "School Logo",
  school_name: "School Name",
  school_phone: "School Phone",
  foundation: "Foundation",
  school_address: "School Address",
  twitter: "https://twitter.com/schoolName",
  youtube: "https://youtube.com/channelName",
  facebook: "https://facebook.com/schoolName",
  instagram: "https://instagram.com/schoolName",
  msg_url: "Invalid URL"
};
