export default {
  dashboard: "Dashboard Admin",
  master: "Master",
  student: "Siswa",
  teacher: "Guru",
  teacher_registration: "Registrasi Guru",
  staff: "Staff",
  academic: "Akademik",
  school_year: "Tahun Ajaran",
  class_list: "Daftar Kelas",
  schedule: "Jadwal",
  my_schedule: "Jadwal Saya",
  manage_schedule: "Kelola Jadwal",
  stppa: "STTPA",
  subjects: "Mata Pelajaran",
  extracurricular: {
    short: "Ekskul",
    long: "Ekstrakurikuler"
  },
  core_competencies: "Kompetensi Inti",
  basic_competencies: "Kompetensi Dasar",
  position: "Jabatan",
  settings: "Pengaturan",
  school: "Sekolah",
  recap: "Rekap",
  journal: "Jurnal",
  bk: "Catatan BK",
  bk_points: "Poin BK",
  bk_detail: "Detail BK",
  absent: "Absensi",
  personalization: "Registrasi Kartu",
  set_time: "Atur Jam",
  recap_absent: "Rekap Absensi",
  weekly_report: "Laporan Mingguan",
  elearning: "E-Learning",
  messenger: "Chat",
  announcement: "Pengumuman",
  news: "Berita",
  information: "Informasi",
  score: "Nilai",
  permission: "Izin",
  about_school: "Tentang Sekolah",
  main_info: "Info Utama",
  registration_number: "Nomor Induk",
  image_slider: "Foto Halaman Utama",
  absence_per_subject: "Absen Per Mata Pelajaran",
  attendance_teaching: "Kehadiran Mengajar",
  transaction: "Transaksi",
  dues: "Tagihan",
  user_access: "User Akses",
  report_card: "Rapor",
  score_rating: "Peringkat Nilai",
  gos: "Kelompok Mata Pelajaran",
  upload_documents: "Unggah Dokumen",
  major: "Jurusan"
};
