export default {
  table: {
    class: "Kelas",
    nip_homeroom_teacher: "NIP Wali Kelas",
    name_homeroom_teacher: "Wali Kelas",
    nip_conselor: "NIP Guru BK",
    name_conselor: "Guru BK",
    total_student: "Jumlah Siswa",
    action: "Aksi"
  },
  delete_force: "Hapus kelas ",
  confirm_password: "Masukan password anda untuk konfirmasi hapus kelas ini",
  master_class: "Master Kelas",
  delete: "Hapus",
  edit: "Edit",
  add: "Tambah",
  select_grade: "Pilih Tingkatan",
  class_name: "Nama Kelas",
  school_year: "Tahun Ajaran",
  name_file: "Daftar Kelas.xls",
  choose_class: "Pilih Kelas",
  choose_guardian: "Pilih Guru Wali",
  choose_conselor: "Pilih Guru BK",
  others: "Lainnya",
  response: {
    success: "Berhasil tambah kelas",
    success_edit: "Berhasil edit kelas",
    success_delete: "Berhasil hapus kelas"
  },
  excel: {
    class: "Kelas",
    nip_homeroom: "NIP Wali Kelas",
    name_homeroom: "Nama Wali Kelas",
    nip_conselor: "NIP Guru BK",
    name_conselor: "Nama Guru BK",
    total_student: "Total Siswa"
  }
};
