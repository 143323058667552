export default {
  attitude_score_title: "Attitude Score",
  attitude_score_attitude: "Attitude",
  attitude_score_rating: "Predicate",
  score_title: "Score",
  extracuricular: "Extracuricular",
  number: "Number",
  extracuricular_activities: "Extracuricular Activities",
  extracuricular_rating: "Predicate",
  achievement: "Achievement",
  achievement_name: "Predicate",
  absence: "Absence",
  field_practice: "Field Practice",
  homeroom_notes: "Homeroom Notes",
  sick: "Sick",
  leave: "Leave",
  alpha: "Alpha",
  absence_type: "Absence Type",
  edit_report: "Edit Report",
  download_perclass:
    "Download all student reports whose grades have been processed",
  getdata_perclass: "Get all student score from this class",
  publish_perclass:
    "Publish all student reports whose grades have been processed",
  unpublish_perclass:
    "Unpublish all student reports whose grades have been processed",
  publish: "Publish",
  unpublish: "Unpublish",
  published_status: "Published",
  unpublished_status: "Unpublished",
  unprocess_status: "Unproccess",
  get_score_data_class: "Get Score Data Class",
  get_score_data: "Get Score Data",
  publish_title: "Publish Report Card",
  unpublish_title: "Unublish Report Card",
  status: "Status",
  generated_at: "Generated At",
  published_at: "Published At",
  publish_text: "Are you sure want to publish this student report card?",
  publish_text_mass: "Are you sure want to publish this selected class?",
  unpublish_text: "Are you sure want to unpublish this student report card?",
  unpublish_text_mass: "Are you sure want to unpublish this selected class?"
};
