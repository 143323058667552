import request from "@/utils/request";

export function create(data) {
  return request({
    method: "post",
    url: "school-profile/create",
    data: data
  });
}

export function list() {
  return request({
    method: "post",
    url: "school-profile/profile"
  });
}
