export default {
  title: "Menjadikan NIS / NIP Sebagai User Login",
  student: "User Login Siswa",
  teacher: "User Login Guru & Staff",
  student_hint:
    "* Jika tombol ON maka user login semua siswa menggunakan Nomor Induk SIswa (NIS)",
  teacher_hint:
    "* Jika tombol ON maka user login semua guru & staff menggunakan Nomor Induk Pegawai (NIP)",
  attention: "Perhatian",
  attention_1: "Admin bisa menentukan user login siswa / guru",
  attention_2:
    "Pastikan tentukan pilihan dengan benar, karena ini sangat penting"
};
