import Cookies from "js-cookie";

export function getToken() {
  return Cookies.get("token");
}

export function setToken(token) {
  return Cookies.set("token", token);
}

export function getAppDomain() {
  return Cookies.get("APPDOMAIN");
}

export function setAppDomain(data) {
  return Cookies.set("APPDOMAIN", data);
}

export function removeToken() {
  return Cookies.remove("token");
}

export function setModalSchoolAddress(param) {
  return Cookies.set("MODAL_SCHOOL_ADDRESS", param);
}

export function getModalSchoolAddress() {
  return Cookies.get("MODAL_SCHOOL_ADDRESS");
}
