export default {
  generate: "Generate",
  clear: "Clear",
  master_hour: "Master Hour",
  master_event: "Master Event",
  choose_grade: "Choose Grade",
  master_hour_subjects: "Master Hour Subjects",
  master_event_subjects: "Master Hour Subjects",
  duration_subjects: "Duration of Subjects",
  subjects: "Subjects",
  available_subjects: "Subjects Available",
  addressed_to: "Addressed to",
  day: "Day",
  some: "Some",
  view_lesson: "View Schedule",
  view_event: "View Event",
  event_name: "Event Name",
  from_hour: "From hour",
  teacher: "Teacher",
  hour: "Hour",
  to_hour: "To hour",
  msg_dialog_event: "Select at least 1 day to continue",
  msg_no_room_code: "An error occurred, please contact the admin",
  response: {
    master_hour_subjects: {
      success_edited: "Success edit data"
    }
  },
  msg_response: {
    input_subjects: "Successfully instilled subjects to schedule",
    remove_subjects: "Successfully took subjects off schedule",
    drag_subjects: "Successfully updated schedule",
    success_master_event: "Successfully entered the event into the schedule",
    success_remove_event: "Successfully removed the event from schedule",
    success_create_event: "Successfully entered the event into the schedule",
    success_generate_schedule: "Success generate schedules",
    success_clear_schedule: "Success clear schedules",
    success_update_email: "Successfully update email",
    failed_input_vidcon_link: "Failed to input Video Conference link",
    vidcon_link_invalid: "Invalid video conference link"
  },
  msg_merge_class: "Please click Create a video conferencing first",
  close_tab_host:
    "Please click the <b>End Meeting For All</b> button to end the meeting",
  close_tab_participant:
    "Please click the <b>Leave Meeting</b> button to end the meeting",
  attendance_msg: "Absent the Presence of Successful",
  happy_teaching: "Happy teaching",
  feedback: "Feedback",
  confirm_generate_title: "Are you sure you want to continue?",
  confirm_generate_content:
    "Generate will create a schedule automatically and randomly, and it takes a long time depending on the number of lessons. Please do not refresh / reload the page during the generation process.",
  confirm_clear_content:
    "Are you sure you want to clear the class schedule {class} ?",
  info_email_dialog:
    "If you don't update your email, you can't become a video conference moderator",
  update_email: "Update Email",
  create_video_conference: "Create a video conference",
  start_video_conference: "Start a Video Conference",
  confirm_force_schedule: ", are you sure you want to continue?",
  info_merge_class:
    "Select a class below, if there is a class that you want to merge",
  create_room_information:
    "How to cancel a video conference:<br />1. Click the <b>'Start a video conference'</b> button<br />2. Click the <b>'End'</b> button at the bottom right<br />3. Click the <b>'End meeting for all'</b> button<br /><br />or if not used, just leave it later the room will be deleted automatically",
  same_hours: "Same Hours"
};
