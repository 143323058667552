export default {
  attitude_score_title: "Nilai Sikap",
  attitude_score_attitude: "Sikap",
  attitude_score_rating: "Predikat",
  score_title: "Nilai",
  extracuricular: "Ekstrakurikuler",
  number: "No.",
  extracuricular_activities: "Kegiatan Ekstrakurikuler",
  extracuricular_rating: "Predikat",
  achievement: "Prestasi",
  achievement_name: "Predikat",
  absence: "Absen",
  field_practice: "Praktek Kerja Lapangan",
  homeroom_notes: "Catatan wali kelas",
  sick: "Sakit",
  leave: "Izin",
  alpha: "Tanpa Keterangan",
  absence_type: "Jenis Absen",
  edit_report: "Ubah Rapor",
  download_perclass: "Unduh semua rapor siswa yang sudah diproses nilainya",
  getdata_perclass: "Ambil semua nilai siswa di kelas ini",
  publish_perclass: "Terbitkan semua rapor siswa yang sudah diproses nilainya",
  unpublish_perclass:
    "Batal Terbitkan semua rapor siswa yang sudah diproses nilainya",
  publish: "Terbitkan",
  unpublish: "Batal Terbitkan",
  published_status: "Diterbitkan",
  unpublished_status: "Belum diterbikan",
  unprocess_status: "Belum diproses",
  get_score_data_class: "Ambil Data Nilai Kelas",
  get_score_data: "Ambil Data Nilai",
  publish_title: "Terbitkan Rapor",
  unpublish_title: "Batal Terbitkan Rapor",
  status: "Status",
  generated_at: "Tanggal Ambil Nilai",
  published_at: "Tanggal diterbitkan",
  publish_text: "Apakah anda yakin akan menerbitkan rapor siswa ini?",
  publish_text_mass: "Apakah anda yakin akan menerbitkan data kelas terpilih?",
  unpublish_text: "Apakah anda yakin akan batal menerbitkan rapor siswa ini?",
  unpublish_text_mass:
    "Apakah anda yakin akan batal menerbitkan data kelas terpilih?"
};
