export default {
  select_class: "Pilih Kelas",
  select_grade: "Pilih Tingkatan",
  select_months: "Pilih Bulan",
  detail_absent: "Detail Absen",
  search_valid_rules: "Kelas dan Tingkatan harus diisi",
  student: "Siswa",
  teacher: "Guru",
  staff: "Staff",
  date: "Tanggal",
  schedule: "Jadwal",
  duration: "Durasi",
  present: "Kehadiran",
  sick: "Sakit",
  permission: "Izin",
  students_registered: "Siswa Terdaftar",
  students_not_registered: "Siswa tidak Terdaftar",
  dialog: {
    name: "Nama",
    absent_info: "Keterangan Absen",
    hour_in: "Keterangan Masuk",
    hour_out: "Keterangan Pulang"
  },
  table: {
    name: "Nama",
    sick: "S",
    permission: "I",
    alpha: "A",
    present: "H",
    late: "T"
  },
  info_color: {
    black: "Libur / Tidak ada KBM",
    red: "Alpha",
    purple: "Pulang Awal",
    purple_darken: "Izin Terlambat",
    orange: "Terlambat",
    green: "Absen Kartu",
    light_green: "Absen Manual",
    blue: "Izin",
    yellow: "Sakit"
  }
};
