export default {
  dashboard: "Dashboard Admin",
  master: "Master",
  student: "Student",
  teacher: "Teacher",
  teacher_registration: "Teacher Registration",
  staff: "Staff",
  academic: "Academic",
  school_year: "School Year",
  class_list: "Class List",
  schedule: "Schedule",
  my_schedule: "My Schedule",
  manage_schedule: "Manage Schedule",
  stppa: "STTPA",
  subjects: "Subjects",
  extracurricular: {
    short: "Extracurricular",
    long: "Extracurricular"
  },
  core_competencies: "Core Competencies",
  basic_competencies: "Basic Competencies",
  position: "Position",
  settings: "Settings",
  school: "School",
  recap: "Recap",
  journal: "Journal",
  bk: "Counseling Guidance",
  bk_points: "Counseling Guidance Point",
  bk_detail: "Counseling Guidance Detail",
  absent: "Absent",
  personalization: "Card Registration",
  set_time: "Set Time",
  recap_absent: "Recap Absent",
  weekly_report: "Weekly Report",
  elearning: "E-Learning",
  messenger: "Chat",
  announcement: "Announcement",
  news: "News",
  information: "Information",
  score: "Score",
  permission: "Permission",
  about_school: "About School",
  main_info: "Main Info",
  registration_number: "Registration Number",
  image_slider: "Main Page Photos",
  absence_per_subject: "Absence Per Subject",
  attendance_teaching: "Attendance Teaching",
  transaction: "Transaction",
  dues: "Dues",
  user_access: "User Access",
  report_card: "Report Card",
  score_rating: "Score Rating",
  gos: "Group of Subjects",
  upload_documents: "Upload Documents",
  major: "Major"
};
