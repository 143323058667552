export default {
  assign_student: "Assign Siswa",
  new_member: "Anggota Baru",
  total_student: "Total Siswa",
  join_extracurricular: "Gabung Ekskul",
  join: "Gabung",
  have_joined: "Sudah bergabung",
  waiting_approval: "Menunggu Persetujuan",
  description: "Keterangan",
  reason_refusal: "Alasan Penolakan",
  day: "Hari",
  hours: "Jam",
  place: "Tempat",
  input_member: "Tambah Anggota",
  delete_member: "Hapus Anggota",
  by_class: "Berdasarkan Kelas",
  filter: {
    new: "Terbaru",
    old: "Terlama",
    most_interest: "Paling banyak diminati",
    least_desirable: "Paling sedikit diminati"
  },
  msg: {
    member_request: "Permohonan bergabung ekskul telah dikirim",
    accept_member: "Berhasil menerima anggota",
    reject_member: "Berhasil menolak anggota",
    input_member: "Berhasil tambah anggota"
  },
  dialog: {
    confirm_request: "Apakah anda yakin ingin bergabung?",
    confirm_decline: "Apakah anda yakin menolak siswa ini?",
    hint_reject: "Tulis alasan mengapa siswa ini ditolak",
    master_extracurricular: "Master Ekstrakurikuler",
    new_extracurricular: "Nama Ekskul Baru",
    extracurricular_available: "Ekskul yang tersedia",
    extracurricular_add: "Tambah Ekskul",
    extracurricular_update: "Edit Ekskul",
    extracurricular_select: "Pilih Ekskul",
    select_day: "Pilih Hari",
    select_place: "Pilih Tempat",
    select_start_time: "Dari Jam",
    select_end_time: "Sampai Jam",
    select_teacher: "Guru Extrakurikuler",
    tuition: "Iuran",
    class: "Tingkatan",
    add_new_schedule: "Tambah Jadwal Baru"
  }
};
