<template>
  <div>
    <v-menu bottom left transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-badge
            v-if="g_notification_count > 0"
            overlap
            color="green"
            :content="g_notification_count"
          >
            <v-icon>mdi-bell-outline</v-icon>
          </v-badge>
          <v-icon v-else>mdi-bell-outline</v-icon>
        </v-btn>
      </template>
      <v-card style="width: 350px;">
        <v-card-title
          :class="g_darkmode ? 'grey darken-4' : 'grey lighten-3'"
          class="font-weight-bold"
        >
          {{ $t("app.notification") }}
        </v-card-title>
        <v-card-text style="padding: 0px !important">
          <v-tabs v-model="notificationTab" fixed-tabs>
            <v-tab
              v-if="g_notification_information_count > 0"
              @click.stop.prevent
            >
              <v-badge
                inline
                color="primary"
                :content="g_notification_information_count"
              >
                {{ $t("app.general") }}
              </v-badge>
            </v-tab>
            <v-tab v-else style="width: 100%" @click.stop.prevent>
              {{ $t("app.general") }}
            </v-tab>

            <v-tab v-if="g_notification_promo_count > 0" @click.stop.prevent>
              <v-badge
                inline
                color="primary"
                :content="g_notification_promo_count"
              >
                Promo
              </v-badge>
            </v-tab>
            <v-tab v-else style="width: 100%" @click.stop.prevent>
              Promo
            </v-tab>
          </v-tabs>

          <v-tabs-items
            id="notif-tab-item-wrapper"
            v-model="notificationTab"
            touchless
          >
            <v-tab-item>
              <div v-if="g_notification_information.length > 0">
                <div
                  v-for="(item, index) in g_notification_information"
                  :key="index"
                  class="notif-item-wrapper"
                  :style="styleHover"
                  @click="action(item, index, 'information')"
                >
                  <v-tooltip
                    :left="$vuetify.breakpoint.smAndUp"
                    :bottom="$vuetify.breakpoint.smAndDown"
                    max-width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex">
                        <v-icon
                          v-text="item.icon"
                          :class="item.color"
                          class="white--text pa-2 rounded-circle notif-item-icon"
                        ></v-icon>
                        <div>
                          <span class="font-weight-bold">
                            <v-badge v-if="item.unread" dot color="primary"
                              >{{ item.title }}
                            </v-badge>
                            <span v-else>{{ item.title }}</span>
                          </span>
                          <span
                            class="caption notif-caption"
                            v-html="item.description"
                          ></span>
                          <span class="caption" v-html="item.created_at"></span>
                        </div>
                      </div>
                    </template>
                    <span
                      class="caption notif-caption"
                      v-html="truncateHtml(item.description)"
                    ></span>
                  </v-tooltip>
                  <div style="width: 100%;" class="mt-2">
                    <v-img :src="item.thumbnail" class="rounded"></v-img>
                  </div>
                </div>
                <div
                  v-if="
                    g_notification_query.page !== g_notification_query.last_page
                  "
                >
                  <v-btn block text @click.stop.prevent @click="viewMore">{{
                    $t("app.view_more")
                  }}</v-btn>
                </div>
              </div>
              <div v-else class="pa-3 d-flex justify-center">
                {{ $t("app.no_data") }}
              </div>
            </v-tab-item>
            <v-tab-item>
              <div v-if="g_notification_promo.length > 0">
                <div
                  v-for="(item, index) in g_notification_promo"
                  :key="index"
                  :style="styleHover"
                  class="notif-item-wrapper"
                  @click="action(item, index, 'promo')"
                >
                  <div class="d-flex">
                    <v-icon
                      v-text="item.icon"
                      :class="item.color"
                      class="white--text pa-2 rounded-circle notif-item-icon"
                    ></v-icon>
                    <div>
                      <span class="font-weight-bold">
                        <v-badge v-if="item.unread" dot color="primary"
                          >{{ item.title }}
                        </v-badge>
                        <span v-else>{{ item.title }}</span>
                      </span>
                      <span
                        class="caption notif-caption"
                        v-html="item.description"
                      ></span>
                      <span class="caption" v-html="item.created_date"></span>
                    </div>
                  </div>
                  <div style="width: 100%;" class="mt-2">
                    <v-img :src="item.thumbnail" class="rounded"></v-img>
                  </div>
                </div>
              </div>
              <div v-else class="pa-3 d-flex justify-center">
                {{ $t("app.no_data") }}
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import truncate from "truncate-html";
import { readNotification } from "@/api/notification";

export default {
  name: "Notification",
  data: () => ({
    notificationTab: 0
  }),
  computed: {
    ...mapGetters([
      "g_notification_count",
      "g_notification_information_count",
      "g_notification_promo_count",
      "g_notification_information",
      "g_notification_promo",
      "g_notification_query",
      "g_role_type",
      "g_darkmode"
    ]),
    styleHover() {
      return {
        "--color-hover": this.g_darkmode ? "#121212" : "#f5f5f5"
      };
    }
  },
  created() {
    store.dispatch("GetListNotification");
    store.dispatch("getListNotificationCount");
  },
  destroyed() {
    store.commit("CHECKPOINT_NOTIF", true);
  },
  methods: {
    truncateHtml: html =>
      truncate(html, 100, { byWords: true, stripTags: true }),
    viewMore() {
      store.commit("CHECKPOINT_NOTIF", false);
      store.dispatch("GetListNotification", true);
    },
    action(item, idx, type) {
      if (type === "information" && item.unread === true) {
        this.g_notification_information[idx].unread = false;
        if (this.g_notification_information_count !== 0) {
          this.$store.commit("DECREMENT_NOTIFICATION", "information");
        }
      }
      if (type === "promo" && item.unread === true) {
        this.g_notification_promo[idx].unread = false;
        if (this.g_notification_promo_count !== 0) {
          this.$store.commit("DECREMENT_NOTIFICATION", "promo");
        }
      }
      readNotification({ notif_participant_id: item.notif_participant_id });

      switch (item.category) {
        case "announcement":
          this.$router.push({
            path: "/information/announcement/detail",
            query: { id: item.id }
          });
          break;
        case "news":
          this.$router.push({
            path: "/information/news/detail",
            query: { id: item.id }
          });
          break;
        case "etest":
          if (this.g_role_type === "STUDENT") {
            this.$router.push({
              path: "/academic/etest-student?tab=2"
            });
          }
          break;
        case "elearning":
          this.$router.push({
            path: `/academic/e-learning/${item.payload.year}/${item.payload.grade}/${item.payload.class}/${item.payload.id}/${item.payload.elearning}`
          });
          break;
        case "conseling":
          this.$router.push({
            path: "/academic/student-bk"
          });
          break;
        case "permission":
          if (this.g_role_type === "STUDENT") {
            this.$router.push({
              path: "/absent/permission"
            });
          } else if (this.g_role_type === "TEACHER") {
            this.$router.replace({
              path: "/absent/permission?tab=0"
            });
          } else {
            this.$router.push({
              path: "/absent/permission"
            });
          }

          break;
        default:
          console.log(item);
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#notif-tab-item-wrapper {
  max-height: 500px;
  overflow: auto;
  .notif-item-wrapper {
    padding: 10px;
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
    .notif-caption {
      height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .notif-item-icon {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    &:hover {
      background: var(--color-hover) !important;
    }
  }
}
</style>
