export default {
  generate_chance: "Generate chance",
  good_signal: "Good Signal",
  not_good_signal: "Not Good Signal",
  bad_signal: "Bad Signal",
  caps_lock_on: "Caps Lock is on",
  success_save: "Successfully save data",
  success_copy: "Successfully copy data",
  success_edit: "Successfully edit data",
  success_delete: "Successfully delete data",
  "404": "The page you were looking for doesn't exist.",
  "404_btn": "Return to the homepage",
  success_login: "Congratulations on your successful login",
  success_login_sub: "Your Registration Is Being Processed",
  success_login_text:
    "Wait up to 2 x 24 to be able to use our features. If deep the specified time, you can not use our features, you can contact the school.",
  no_roles: "You have no access, please contact admin!",
  offline: "You are Offline",
  back_online: "Back Online",
  login_error: "The username or password you entered are incorrect.",
  logout_success: "Logout successfully",
  profile: "School Profile",
  announcement: "Announcement",
  news: "News",
  calendar: "Calendar",
  registration: "Registration",
  login: "Login",
  logout: "Logout",
  change_password: "Change Password",
  password_old: "Old Password",
  password_new: "New Password",
  password_confirm: "New Password Confirmation",
  password_confirm_rules: "New password is incorrect",
  change_password_success: "Change password successfully",
  follow_us: "Follow Us",
  contact_us: "Contact Us",
  photo: "Photo",
  next: "Next",
  previous: "Previous",
  back: "Back",
  save: "Save",
  cancel: "Cancel",
  edit: "Edit",
  open_map: "Open Map",
  close_map: "Close Map",
  close: "Close",
  yes: "Yes",
  no: "No",
  sign_up: "Sign Up",
  required: "Please fill out this field",
  required_array: "At least one item should be selected",
  search: "Search",
  qna: "Q & A",
  contact: "Contact",
  homepage: "Homepage",
  chat: "Chat",
  view: "View",
  others: "others",
  confirm_delete: "Are you sure you want to delete this item?",
  inspiration: "Inspiration",
  ebook: "E-book",
  my_book: "My Book",
  shop: "Shop",
  home: "Home",
  most_popular: "Most Popular",
  category: "Category",
  view_all: "View ALl",
  read: "Read",
  similar: "Similar {item}",
  havent_bought_book: "You haven't bought this book",
  days: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ],
  month: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  success: "Successful",
  error: "Error",
  no_data: "No data found",
  add: "Add",
  done: "Done",
  auth_error_title: "Sistesi account can only be used on 2 devices",
  auth_error_subtitle: "Select the device to log out of your account.",
  success_auth: "Successfully ejected account on another device",
  unauthorized:
    "Your account has been used on another device / your login time has expired",
  download: "Download",
  action: "Action",
  student: "Student",
  approve: "Approve",
  not_approve: "Not Approve",
  publish: "Publish",
  unpublish: "Unpublish",
  delete: "Delete",
  select_all: "Select All",
  settings: "Settings",
  timeout: "There is no response, please check your internet connection",
  no_message_error:
    "Sorry, an error occurred at this time, please try again later",
  general: "General",
  teacher_or_staff: "Teacher or Staff",
  teacher_and_staff: "Teacher and Staff",
  Student: "Student",
  holiday: "Holiday",
  event: "Event",
  class: "Class",
  type: "Type",
  all: "All",
  permanent: "Permanent",
  honorary: "Honorary",
  current_device: "Current Device",
  description: "Description",
  darkmode: "Dark Mode",
  select_language: "Select Language",
  graduate: "Graduate",
  teacher: "Teacher",
  staff: "Staff",
  register_as: "Register As",
  data_not_found: "Data Not Found",
  view_more: "view more ...",
  grade: "Grade",
  subjects: "Subjects",
  school_year: "School Year",
  copy: "Copy",
  copy_clipboard: "has been copied to clipboard",
  image: "Image",
  upload: "Upload",
  invalid_youtube_url: "invalid youtube url",
  select: "Select",
  write: "Write",
  name: "Name",
  hours: "Hours",
  minutes: "Minutes",
  start: "Start",
  result: "Result",
  incorrect: "Incorrect",
  correct: "Correct",
  delete_bulk: "Delete selected data",
  posted_on: "Posted On",
  score: "Score",
  daily_tests: "Daily Test",
  homework: "Homework",
  midterm_exam: "Midterm Exam",
  final_exams: "Final Exam",
  not_received_yet: "Not received yet",
  not_accepted: "Not Accepted",
  username: "Username",
  class_name: "Class Name",
  exam: "Exam",
  subject: "Subject",
  warning: "Warning",
  student_detail: "Student Detail",
  teacher_detail: "Teacher Detail",
  staff_detail: "Staff Detail",
  there_is_an_error: "There is an error",
  mass_message: "Pesan Masal",
  select_contact: "Select Contact",
  write_a_message: "Write a message",
  mass_message_teacher: "Mass Message: All Teachers",
  mass_message_student: "Mass Message: All Student",
  mass_message_student_class: "Mass Message: {class}",
  successfully_sending_bulk_messages: "Successfully sending bulk messages",
  for: "For",
  to: "to",
  at: "at",
  title: "Title",
  date: "Date",
  latest: "Latest",
  oldest: "Oldest",
  most_viewed: "Most Viewed",
  most_liked: "Most Liked",
  login_first: "Please login first",
  from: "From",
  public: "Public",
  kelas_tertentu: "Specific Class",
  internal: "Internal",
  registration_number: "Registration Number",
  skip: "Skip",
  get_chat: "Scroll to top for load older chat",
  document: "Document",
  nip: "Employee ID",
  nis: "Student ID",
  edit_for_creator: "Edit only for author",
  delete_for_creator: "Delete only for author",
  notification: "Notification"
};
