const schedule = {
  state: {
    tableHeaders: [
      { text: "Jam", value: "jam" },
      { text: "Senin", value: "senin" },
      { text: "Selasa", value: "selasa" },
      { text: "Rabu", value: "rabu" },
      { text: "Kamis", value: "kamis" },
      { text: "Jumat", value: "jumat" },
      { text: "Sabtu", value: "sabtu" },
      { text: "Minggu", value: "minggu" }
    ]
  }
};

export default schedule;
