const master = {
  state: {
    data_siswa: {
      tahun_ajaran: ["Semua", "2018/2019", "2019/2020"],
      siswa: {
        search: "",
        selected: [],
        pilih_kelas: ["Kelas VII", "Kelas VIII", "Kelas IX"],
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "Telepon Siswa", value: "telepon_siswa" },
          { text: "Wali", value: "wali" },
          { text: "No.Kartu", value: "no_kartu" },
          { text: "Aksi", value: "aksi" }
        ]
      },
      mutasi_keluar: {
        search: "",
        selected: [],
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "No.Kartu", value: "no_kartu" },
          { text: "Tanggal", value: "tanggal" },
          { text: "Kelas", value: "kelas" },
          { text: "Keterangan", value: "keterangan" },
          { text: "Aksi", value: "aksi" }
        ]
      },
      calon_siswa: {
        dialog: false,
        selected: [],
        radios: "belum_diproses",
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "No. Pendaftaran", value: "no_pendaftaran" },
          { text: "Telepon Siswa", value: "telepon_siswa" },
          { text: "Mendaftar Pada", value: "mendaftar_pada" },
          { text: "Aksi", align: "center", value: "aksi" }
        ],
        search: ""
      }
    },
    data_guru: {
      semua_jenis: ["Semua", "Tetap", "Honorer"],
      radios: "belum_diproses",
      guru: {
        dialog: false,
        selected: [],
        search: "",
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "Telepon", value: "telepon_siswa" },
          { text: "Email", value: "email" },
          { text: "Jabatan", value: "jabatan" },
          { text: "Status", value: "status" },
          { text: "Aksi", align: "center", value: "aksi" }
        ]
      },
      mutasi_keluar: {
        search: "",
        selected: [],
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "Telepon", value: "telepon_siswa" },
          { text: "Email", value: "email" },
          { text: "Jabatan", value: "jabatan" },
          { text: "Status", value: "status" },
          { text: "Aksi", align: "center", value: "aksi" }
        ]
      },
      calon_guru: {
        search: "",
        selected: [],
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "Telepon", value: "telepon_siswa" },
          { text: "Email", value: "email" },
          { text: "Aksi", align: "center", value: "aksi" }
        ]
      }
    },
    data_staff: {
      semua_jenis: ["Semua", "Tetap", "Honorer"],
      radios: "belum_diproses",
      staff: {
        dialog: false,
        selected: [],
        search: "",
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "Telepon", value: "telepon_siswa" },
          { text: "Email", value: "email" },
          { text: "Jabatan", value: "jabatan" },
          { text: "Status", value: "status" },
          { text: "Aksi", align: "center", value: "aksi" }
        ]
      },
      mutasi_keluar: {
        search: "",
        selected: [],
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "Telepon", value: "telepon_siswa" },
          { text: "Email", value: "email" },
          { text: "Tanggal", value: "tanggal" },
          { text: "Status", value: "status" },
          { text: "Aksi", align: "center", value: "aksi" }
        ]
      },
      calon_staff: {
        search: "",
        selected: [],
        headers: [
          {
            text: "Nama",
            align: "left",
            sortable: false,
            value: "nama"
          },
          { text: "Telepon", value: "telepon_siswa" },
          { text: "Email", value: "email" },
          { text: "Aksi", align: "center", value: "aksi" }
        ]
      }
    }
  },
  mutations: {
    TOGGLE_DIALOG_CALON_SISWA: state => {
      state.data_siswa.calon_siswa.dialog = !state.data_siswa.calon_siswa
        .dialog;
    }
  }
};

export default master;
