export default {
  generate_chance: "Generate Kesempatan",
  good_signal: "Sinyal Bagus",
  not_good_signal: "Sinyal Kurang Bagus",
  bad_signal: "Sinyal Buruk",
  caps_lock_on: "Caps Lock aktif",
  success_save: "Berhasil menyimpan data",
  success_copy: "Berhasil salin data",
  success_edit: "Berhasil merubah data",
  success_delete: "Berhasil menghapus data",
  "404": "Halaman yang Anda cari tidak ada.",
  "404_btn": "Kembali ke halaman utama",
  success_login: "Selamat anda berhasil Login",
  success_login_sub: "Registrasi Anda Sedang Kami Proses",
  success_login_text:
    "Tunggu hingga 2 x 24 untuk bisa menggunakan fitur kami. Jika dalam waktu yang di tentukan, anda belum bisa menggunakan fitur kami, anda bisa menghubungi pihak sekolah.",
  no_roles: "Kamu tidak punya akses, silahkan hubungi admin!",
  offline: "Anda sedang Offline",
  back_online: "Kembali Online",
  login_error: "Nama pengguna atau kata sandi yang Anda masukkan tidak benar.",
  logout_success: "Berhasil keluar",
  profile: "Profil Sekolah",
  announcement: "Pengumuman",
  news: "Berita",
  calendar: "Kalender",
  registration: "Pendaftaran",
  login: "Masuk",
  logout: "Keluar",
  change_password: "Ubah Password",
  password_old: "Password Lama",
  password_new: "Password Baru",
  password_confirm: "Konfirmasi password baru",
  password_confirm_rules: "Password baru tidak benar",
  change_password_success: "Berhasil ubah password",
  follow_us: "Ikuti Kami",
  contact_us: "Hubungi Kami",
  photo: "Foto",
  next: "Selanjutnya",
  previous: "Sebelumnya",
  back: "Kembali",
  save: "Simpan",
  cancel: "Batal",
  edit: "Ubah",
  open_map: "Buka Peta",
  close_map: "Tutup Peta",
  close: "Tutup",
  yes_schedule: "Buat Otomatis",
  no_schedule: "Batal",
  yes: "Ya",
  no: "Tidak",
  sign_up: "Daftar",
  required: "Silakan isi kolom ini",
  required_array: "Setidaknya satu item harus dipilih",
  search: "Cari",
  qna: "Q & A",
  contact: "Kontak",
  homepage: "Halaman Utama",
  chat: "Obrolan",
  view: "Lihat",
  others: "lainnya",
  confirm_delete: "Apakah kamu yakin akan menghapus data ini?",
  inspiration: "Inspirasi",
  ebook: "E-book",
  my_book: "Buku Saya",
  shop: "Toko",
  home: "Beranda",
  most_popular: "Paling Populer",
  category: "Kategori",
  view_all: "Lihat Semua",
  read: "Baca",
  similar: "{item} Serupa",
  havent_bought_book: "Anda belum beli buku ini",
  days: ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"],
  month: [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember"
  ],
  success: "Berhasil",
  error: "Gagal",
  no_data: "Tidak ada data",
  add: "Tambah",
  done: "Selesai",
  auth_error_title: "Akun Sistesi hanya bisa digunakan di 2 perangkat",
  auth_error_subtitle: "Pilih Perangkat yang akan dikeluarkan dari akun anda.",
  success_auth: "Berhasil mengeluarkan akun di perangkat lain",
  unauthorized:
    "Akun anda telah dipakai diperangkat lain atau waktu login anda telah habis",
  download: "Unduh",
  action: "Aksi",
  student: "Siswa",
  approve: "Setujui",
  not_approve: "Tidak Setujui",
  publish: "Terbitkan",
  unpublish: "Belum diterbitkan",
  delete: "Hapus",
  select_all: "Pilih Semua",
  settings: "Pengaturan",
  timeout: "Tidak ada respon, silahkan periksa koneksi internet",
  no_message_error:
    "Maaf saat ini sedang terjadi kesalahan, silahkan coba lagi nanti",
  general: "Umum",
  teacher_or_staff: "Guru atau Staff",
  teacher_and_staff: "Guru dan Staff",
  Student: "Siswa",
  holiday: "Libur",
  event: "Kegiatan",
  class: "Kelas",
  type: "Tipe",
  all: "Semua",
  permanent: "Tetap",
  honorary: "Honorer",
  current_device: "Perangkat saat ini",
  description: "Deskripsi",
  darkmode: "Mode Gelap",
  select_language: "Pilih Bahasa",
  graduate: "Lulus",
  teacher: "Guru",
  staff: "Staff",
  register_as: "Daftar sebagai",
  data_not_found: "Data tidak ditemukan",
  view_more: "Lihat lebih",
  grade: "Tingkatan",
  subjects: "Mata Pelajaran",
  school_year: "Tahun Ajaran",
  copy: "Salin",
  copy_clipboard: "telah disalin ke clipboard",
  image: "Gambar",
  upload: "Unggah",
  invalid_youtube_url: "Url youtube tidak valid",
  select: "Pilih",
  write: "Tulis",
  name: "Nama",
  hours: "Jam",
  minutes: "Menit",
  start: "Mulai",
  result: "Hasil",
  incorrect: "Salah",
  correct: "Benar",
  delete_bulk: "Hapus data terpilih",
  posted_on: "Diposting pada",
  score: "Nilai",
  not_received_yet: "Belum diproses",
  not_accepted: "Tidak Diterima",
  username: "Username",
  class_name: "Nama Kelas",
  daily_tests: "Ulangan Harian",
  homework: "Pekerjaan Rumah",
  midterm_exam: "UTS",
  final_exams: "UAS",
  exam: "Ulangan",
  subject: "Mata Pelajaran",
  warning: "Peringatan",
  student_detail: "Detail Siswa",
  teacher_detail: "Detail Guru",
  staff_detail: "Detail Staff",
  there_is_an_error: "Terjadi Kesalahan",
  mass_message: "Pesan Masal",
  select_contact: "Pilih Kontak",
  write_a_message: "Tulis Pesan",
  mass_message_teacher: "Pesan Masal: Semua Guru",
  mass_message_student: "Pesan Masal: Semua Siswa",
  mass_message_student_class: "Pesan Masal: {class}",
  successfully_sending_bulk_messages: "Berhasil Mengirim Pesan Masal",
  for: "Untuk",
  to: "ke",
  at: "di",
  title: "Judul",
  date: "Tanggal",
  latest: "Terbaru",
  oldest: "Terlama",
  most_viewed: "Paling banyak dilihat",
  most_liked: "Paling banyak disukai",
  login_first: "Silahkan login terlebih dahulu",
  from: "dari",
  public: "Umum",
  kelas_tertentu: "Kelas Tertentu",
  internal: "Dalam Sekolah",
  registration_number: "No. Induk",
  skip: "Lewati",
  get_chat: "Gulir ke atas untuk memuat obrolan lama",
  document: "Dokumen",
  nip: "NIP",
  nis: "NIS",
  edit_for_creator: "Ubah hanya untuk penulis",
  delete_for_creator: "Hapus hanya untuk penulis",
  notification: "Notifikasi"
};
