export default {
  master_rating: "Master Rating",
  create_form: "Add Score Rating",
  update_form: "Update Score Rating",
  success_update: "Success apdating score rating",
  success_create: "Success adding score rating",
  success_delete: "Success deleting score rating",
  delete_confirm: "Delete Score Rating",
  form: {
    type: "Select Rating Type",
    min: "From Score",
    max: "To Score",
    code: "Rating",
    alias: "Alias",
    description: "Description"
  },
  table: {
    rating: "Rating",
    score: "Score",
    description: "Description"
  }
};
