import request from "@/utils/request";

export function getVersion() {
  return request({
    method: "post",
    url: `${process.env.VUE_APP_BASE_API}changelog/last_versions`,
    data: { env: process.env.VUE_APP_VERSION }
  });
}

export function getChangeLog(type) {
  return request({
    method: "post",
    url: "/changelog/list",
    data: { type: type }
  });
}

export function addChangeLog(data) {
  return request({
    method: "post",
    url: "/changelog/add",
    data: data
  });
}

export function getWarning() {
  return request({
    method: "post",
    url: "/warning/get"
  });
}
