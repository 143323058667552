export default {
  date_filing: "Dibuat pada",
  students_permission: "Izin Siswa",
  teachers_permission: "Izin Guru",
  staff_permission: "Izin Staff",
  add_student: "Tambah Izin Siswa",
  delete_student: "Hapus Izin Siswa",
  delete_teacher: "Hapus Izin Guru / Staff",
  permission: "Izin",
  start_date: "Tanggal Mulai",
  end_date: "Tanggal Selesai",
  added_my_permission: "Tambah Izin Saya",
  delete_my_permission: "Hapus Izin Saya",
  attachment: "Lampiran",
  action: "Aksi",
  my_permission: "Izin Saya",
  detail_permission: "Detail Izin",
  permission_type: "Jenis Izin",
  description: "Keterangan",
  permission_list: "Daftar Izin",
  add_teacher_permission: "Tambah Izin Guru",
  add_staff_permission: "Tambah Izin Staff",
  msg_minimal_students: "Minimal tambahkan 1 Siswa",
  select_students: "Pilih Siswa",
  second_step: "Siswa dipilih",
  msg: {
    delete_permission: "Berhasil hapus izin"
  }
};
