import Vue from "vue";
import router from "./router";
import store from "./store";
import { getToken } from "./utils/storage";

import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: store.getters.g_darkmode ? "#27ACE2" : "#3e4295",
  failedColor: "#FF0D64",
  thickness: "5px"
});

let newVue = new Vue({});

function hasPermission(roles, permissionRoles) {
  if (roles) return true;
  if (!permissionRoles) return true;
  return roles.some(role => permissionRoles.indexOf(role) >= 0);
}

const whiteList = [
  "/login-etest",
  "/homepage",
  "/login",
  "/ppdb/student",
  "/ppdb/teacher",
  "/404",
  "/information/announcement",
  "/information/news",
  "/information/announcement/detail",
  "/information/news/detail",
  "/vidcon-zoom",
  "/changelog"
]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  newVue.$Progress.start();
  await store.dispatch("getVersion");
  if (getToken()) {
    if (to.path === "/login") {
      next({ path: "/" });
      newVue.$Progress.finish();
    } else {
      if (store.getters.g_access.length === 0) {
        store.dispatch("SchoolProfile");
        store
          .dispatch("GetUserInfo")
          .then(() => {
            const access = store.getters.g_access;
            store.dispatch("GenerateRoutes", { access }).then(() => {
              router.addRoutes(store.getters.addRouters);
              if (from.path === "/login") {
                // dispatch page by role type
                switch (store.getters.g_role_type) {
                  case "ADMIN":
                    next({ path: "/" });
                    break;
                  case "STUDENT":
                  case "TEACHER":
                    next({ path: "/profile" });
                    break;
                  default:
                    next();
                    break;
                }
              } else {
                next({ ...to, replace: true });
              }
            });
          })
          .catch(error => {
            store.dispatch("LogOut").then(() => {
              if (error !== "noroles") {
                location.reload();
              }
            });
          });
      } else {
        if (hasPermission(store.getters.g_access, to.meta.roles)) {
          next();
        } else {
          next({ path: "/401", replace: true, query: { noGoBack: true } });
        }
      }
    }
  } else {
    const getSplitPath = to.path.split("/");
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else if (getSplitPath.indexOf("update_password") != -1) {
      if (getSplitPath[2]) {
        next();
      } else {
        next("/homepage");
      }
    } else {
      next("/homepage");
    }
    newVue.$Progress.finish();
  }
});

router.afterEach(() => {
  newVue.$Progress.finish();
});
