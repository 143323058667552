<template>
  <v-app id="app">
    <v-system-bar
      v-if="!onLine"
      app
      dark
      color="error"
      :height="30"
      class="system-bar text-center"
    >
      <span>{{ $t("app.offline") }}</span>
    </v-system-bar>
    <v-system-bar
      v-if="onLine && showBackOnline"
      app
      dark
      color="success"
      :height="30"
      class="system-bar text-center"
    >
      <span>{{ $t("app.back_online") }}</span>
    </v-system-bar>

    <v-overlay :value="g_full_loading" :opacity="0.7" style="z-index: 10001">
      <Lottie
        :options="lottieOptions"
        :height="200"
        :width="200"
        :animCreated="handleAnimation"
      />
    </v-overlay>

    <Snackbar></Snackbar>
    <UpdateApp></UpdateApp>
    <ServerDownDialog
      v-if="openAlert"
      :alertOptions="alertOptions"
      :closeAlert="() => (openAlert = false)"
    />
    <vue-progress-bar></vue-progress-bar>

    <router-view />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar";
import UpdateApp from "@/components/UpdateApp";
import ServerDownDialog from "@/components/ServerDownDialog";
import Lottie from "vue-lottie";
import loading from "@/assets/animation/loading.json";
import loadingMistar from "@/assets/animation/loadingMistar.json";
// import store from "@/store";

import { getWarning } from "@/api/app.js";

import { getAppDomain } from "@/utils/storage";
import { detect } from "detect-browser";

import katex from "katex";
import "katex/dist/katex.min.css";
const element = document.createElement("link");

export default {
  name: "App",
  components: {
    Snackbar,
    UpdateApp,
    Lottie,
    ServerDownDialog
  },
  data() {
    return {
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      onLine: navigator.onLine,
      showBackOnline: false,
      lottieOptions: {
        animationData:
          process.env.VUE_APP_NAME === "Mistar" ? loadingMistar : loading
      },
      openAlert: false,
      alertOptions: {}
    };
  },
  sockets: {
    connect: function() {
      const _self = this;
      if (this.g_school.profile.institution) {
        console.warn("socket connected", getAppDomain());
        const institution = this.g_school.profile.institution;
        const name =
          detect()
            .name.charAt(0)
            .toUpperCase() + detect().name.slice(1);

        this.$socket.emit("serverSetUserOnline", {
          institution: `${getAppDomain()}_${institution.toUpperCase()}`,
          general: "GENERAL",
          region: `${getAppDomain()}`,
          personId: `${getAppDomain()}_${_self.g_user.person}`,
          roleType: `${getAppDomain()}_${institution}_${
            _self.g_user.role_type
          }`.toUpperCase(),
          classId: _self.g_user.student.class
            ? `${getAppDomain()}_${_self.g_user.student.class}`
            : "",
          device: "web",
          deviceVersion: `${detect().os} - ${name}`
        });

        if (_self.$store.getters.g_latency == 0) {
          let startTime;

          setInterval(function() {
            startTime = Date.now();
            _self.$socket.emit("serverNotif", {
              recipient: [`${getAppDomain()}_${_self.g_user.person}`],
              data: {
                type: "ping",
                time: startTime
              }
            });
          }, 5000);
        }
      }
    },
    clientNotif: function({ data }) {
      const _self = this;
      if (data.type === "ping") {
        const latency = Date.now() - data.time;
        _self.$store.commit("SET_LATENCY", latency);
      } else {
        const { code, notification } = data;

        if (code) {
          const syncData = {
            id: notification.id,
            title: notification.title,
            description: notification.description,
            thumbnail: notification.thumbnail,
            category: notification.category,
            created_at: notification.created_at,
            payload: notification.payload,
            notif_participant_id: notification.notif_participant_id
          };
          _self.$store.commit("SET_NOTIFICATION_COUNT", {
            category: notification.category || ""
          });
          _self.$store.commit("SET_NOTIFICATION", syncData);
        }
      }
    },
    clientMessage: function(response) {
      const _self = this;
      this.askPermission();
      if (response) {
        const msg = JSON.parse(JSON.stringify(response.data));

        _self.$store.commit("SET_UNREAD_MESSAGE", {
          msg: msg,
          user: _self.$store.getters.g_messenger.selected_user
        });
        _self.$store.commit("SET_CHAT_LIST", msg);

        let photo = "";
        if (msg.from.photo) photo = msg.from.photo;
        else {
          if (msg.from.gender == "P") {
            photo = this.girlPhoto;
          } else if (msg.from.gender == "L") {
            photo = this.boyPhoto;
          } else {
            photo = "img/icons/android-chrome-192x192.png";
          }
        }

        if (process.env.NODE_ENV !== "development") {
          if (window.serviceWorker) {
            if (_self.$route.name != "Messenger") {
              window.serviceWorker.showNotification(msg.from.name, {
                url: msg.base_url,
                body: msg.message,
                icon: photo,
                vibrate: [200, 100, 200, 100, 200, 100, 200]
              });
            }
          }
        }

        // _self.$store.commit("TRIGGER_NOTIF_MSG");
        if (_self.$store.getters.g_messenger.selected_user) {
          if (_self.$route.name == "Messenger") {
            _self.$vuetify.goTo(1000);
          }
        }
        _self.$Progress.finish();
      }
    }
  },
  computed: {
    ...mapGetters([
      "g_full_loading",
      "g_darkmode",
      "g_user",
      "g_messenger",
      "g_school"
    ])
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
    g_darkmode(newVal) {
      this.updateDarkmode(newVal);
    }
  },
  async created() {
    await getWarning().then(res => {
      if (res.data.code) {
        const data = res.data.data;
        if (data) {
          if (data.code == "NETWORK_TROUBLE" && data.is_active == "1") {
            this.openAlert = true;
            this.alertOptions = data;
          } else this.openAlert = false;
        }
      }
    });
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("fetchData").remove();
    }, 150);
    // this.$store.dispatch("getVersion");
    window.katex = katex;
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    window.addEventListener("keyup", function(e) {
      var keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode == 44) {
        this.stopPrntScr();
      }
    });

    console.log(
      "%c Hayooo mau ngapain!",
      "color:red;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );

    this.updateDarkmode(this.g_darkmode);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    askPermission() {
      return new Promise(function(resolve, reject) {
        const permissionResult = Notification.requestPermission(function(
          result
        ) {
          resolve(result);
        });

        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      }).then(function(permissionResult) {
        if (permissionResult !== "granted") {
          throw new Error(
            "We weren't granted permission so notification feature is bloked by client"
          );
        }
      });
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    stopPrntScr() {
      var inpFld = document.createElement("input");
      if (inpFld) {
        inpFld.setAttribute("value", ".");
        inpFld.setAttribute("width", "0");
        inpFld.style.height = "0px";
        inpFld.style.width = "0px";
        inpFld.style.border = "0px";
        document.body.appendChild(inpFld);
        inpFld.select();
        document.execCommand("copy");
        inpFld.remove(inpFld);
      }
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    updateDarkmode(param) {
      if (param) {
        element.setAttribute("id", "sistesi_darkmode_head");
        element.setAttribute("rel", "stylesheet");
        element.setAttribute("type", "text/css");
        element.setAttribute(
          "href",
          window.base_folder + "styles/darkmode.css"
        );
        document.getElementsByTagName("head")[0].appendChild(element);
      } else {
        if (document.getElementById("sistesi_darkmode_head"))
          document.getElementById("sistesi_darkmode_head").remove();
        element.setAttribute("id", "sistesi_darkmode_head");
        element.setAttribute("rel", "stylesheet");
        element.setAttribute("type", "text/css");
        element.setAttribute(
          "href",
          window.base_folder + "styles/lightmode.css"
        );
        // document.getElementsByTagName("head")[0].appendChild(element);
      }
    }
  }
};
</script>
