export default {
  form: {
    title: "Title",
    shown_for: "Shown For",
    class: "Class"
  },
  filter: {
    newest: "Newest",
    oldest: "Oldest",
    most_viewed: "Most Viewed",
    most_liked: "Most Liked"
  },
  table: {
    date: "Date",
    title: "Title",
    shown_for: "Shown For",
    content: "Content",
    total: "Total"
  }
};
