import Admin from "@/views/layout/admin";
import i18n from "@/i18n";

const Student = {
  path: "/student",
  component: Admin,
  alwaysShow: true,
  meta: {
    title: i18n.t("routes.settings"),
    icon: "sidebar-icon-master-data.svg",
    roles: [42]
  },
  children: [
    {
      name: "SchoolYear",
      path: "school-year",
      component: () => import("@/views/admin/master/schoolYear"),
      meta: { roles: [42], title: i18n.t("routes.school_year") }
    }
  ]
};

export default Student;
