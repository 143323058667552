import router from "@/router";
import { api_login, api_login_with_google, user_profile } from "@/api/login";
import i18n from "@/i18n";
import { getToken, setToken, setAppDomain } from "@/utils/storage";
import {
  adminActions,
  teacherActions,
  studentActions
} from "@/utils/privilages";
import { list } from "@/api/admin/settings/mainInfo";

const user = {
  state: {
    roles: [],
    access: [],
    privilagesUser: [],
    token: getToken(),
    isDuhan: false,
    username: "",
    user: {
      email: "",
      fullname: "",
      photo: "",
      role: "",
      gender: "",
      employee_id: "",
      role_type: "",
      remember_token: "",
      student: { class: "", class_name: "", nis: "", student: "" },
      all_unread_messages: 0,
      nis: "",
      nip: "",
      uid: "",
      address: "",
      phone: "",
      person: "",
      userId: ""
    },
    school: {
      name: "SMK Developer",
      logo: "@/assets/users/logo-sekolah.png",
      level: "",
      about: {
        address: "",
        email: "",
        facebook: "",
        foundation: "",
        instagram: "",
        institution: "",
        path_potrait: "",
        path_landscape: "",
        name: "",
        phone: "",
        twitter: "",
        youtube: "",
        whatsapp: ""
      },
      profile: {
        misi_desc: "",
        profile_desc: "",
        profile_path: "",
        purpose_desc: "",
        visi_desc: "",
        visi_misi_path: ""
      },
      slider_photo: [],
      includeVidcon: false
    },
    id: "",
    modal_school_address: localStorage.getItem("modal_school_address") || false
  },

  mutations: {
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_UNREAD_MESSAGE: (state, data) => {
      if (!data.user) {
        state.user.all_unread_messages += 1;
      }
      if (data.user && data.msg.from.person != data.user.person) {
        state.user.all_unread_messages += 1;
      }
    },
    SUBTRACTION_UNREAD_MESSAGE: (state, sub) => {
      state.user.all_unread_messages = state.user.all_unread_messages - sub;
    },
    SET_ACCESS: (state, access) => {
      state.access = access;
    },
    SET_SCHOOL_PROFILE: (state, profielSchool) => {
      if (profielSchool.profile) {
        state.school.profile = profielSchool.profile;
      }
      if (profielSchool.about) {
        state.school.about = profielSchool.about;
      }
      if (profielSchool.slider_photo) {
        state.school.slider_photo = profielSchool.slider_photo;
      }
      if (profielSchool.include_vidcon) {
        state.school.includeVidcon = profielSchool.include_vidcon === "YES";
      }
      if (profielSchool.level) {
        state.school.level = profielSchool.level;
      }
    },
    SET_PRIVILAGES_USER: (state, privilages) => {
      state.privilagesUser = privilages;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    SET_DUHAN: (state, isDuhan) => {
      state.isDuhan = isDuhan;
    },
    SET_USERNAME: (state, username) => {
      state.username = username;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_SCHOOL: (state, school) => {
      state.school = school;
    },
    SET_ID: (state, id) => {
      state.id = id;
    },
    MODAL_SCHOOL_ADDRESS: (state, status) => {
      state.modal_school_address = status;
      localStorage.setItem("modal_school_address", status);
    }
  },
  actions: {
    // School Profile
    SchoolProfile({ commit }) {
      return new Promise((resolve, reject) => {
        list()
          .then(res => {
            if (res.data.code) {
              commit("SET_SCHOOL_PROFILE", res.data.data);
            }
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    // Username login
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        api_login(username, userInfo.password)
          .then(res => {
            if (res.data.code) {
              const data = res.data.data;
              commit("SET_TOKEN", data.token);
              commit("SET_DUHAN", data.is_duhan);
              // commit("MODAL_SCHOOL_ADDRESS", true);
            }
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    LoginWithGoogle({ commit }, token) {
      return new Promise((resolve, reject) => {
        api_login_with_google(token)
          .then(res => {
            if (res.data.code) {
              const { token } = res.data.data;
              console.log("token", token);
              commit("SET_TOKEN", token);
            }
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        user_profile(state.token)
          .then(async res => {
            if (res.data.code) {
              const data = res.data.data;
              let roles = [];

              if (data.roles.length > 0) {
                let accesses = [];
                roles = await data.roles.map(r => r.id);
                switch (data.role_type) {
                  case "TEACHER":
                    commit("SET_PRIVILAGES_USER", teacherActions);
                    break;
                  case "ADMIN":
                    commit("SET_PRIVILAGES_USER", adminActions);
                    break;
                  case "STUDENT":
                    commit("SET_PRIVILAGES_USER", studentActions);
                    break;
                }

                if (roles.length > 0) {
                  commit("SET_ROLES", roles);
                }

                if (data.access.length > 0) {
                  // handle data string
                  accesses = data.access
                    .map(Number)
                    .filter(value => !Number.isNaN(value));
                }
                accesses.push(25); // profile route
                // accesses.push(9); // schedule
                // accesses.push(63); // schedule zoom
                commit("SET_ACCESS", accesses);
              } else {
                router.replace({ path: "/login" });

                commit("CALL_SNACKBAR", {
                  msg: i18n.t("app.no_roles"),
                  color: "error",
                  x: "right",
                  y: "top"
                });
                reject("noroles");
              }

              commit("SET_ID", data.person);
              commit("SET_USERNAME", data.nick_name);
              commit("SET_USER", {
                fullname: data.name ? data.name : "",
                photo: data.photo ? data.photo : "",
                email: data.email,
                role: data.type ? data.type : "",
                gender: data.gender ? data.gender : "L",
                employee_id: data.employee_id ?? "",
                role_type: data.role_type ? data.role_type : "",
                remember_token: data.remember_token ? data.remember_token : "",
                student: data.student || {
                  class: "",
                  class_name: "",
                  nis: "",
                  student: ""
                },
                all_unread_messages: data.all_unread_messages
                  ? data.all_unread_messages
                  : 0,
                nis: data.nis || "",
                nip: data.nip || "",
                uid: data.uid || "",
                address: data.address || "",
                phone: data.phone || "",
                person: data.person || "",
                userId: data.roles.length ? data.roles[0].user : "",
                active_schoolyear: data.school_year_active
              });

              const hostname = window.location.hostname.toLowerCase();
              let APPSDOMAIN = "SISTESI";
              if (hostname.includes("mistar")) {
                APPSDOMAIN = "MISTAR";
              } else if (hostname.includes("localhost")) {
                APPSDOMAIN = "DEV";
              }

              setAppDomain(APPSDOMAIN);
            }
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    // Get Info
    // GetUserInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     user_profile(state.token)
    //       .then(response => {
    //         if (!response.data)
    //           reject("Verification failed, please login again.");

    //         const data = response.data.data;
    //         if (data.credential) commit("SET_ROLES", data.credential);
    //         else reject("getInfo: you have no roles");

    //         const menu = data.menu.split(",");
    //         const newMenu = [];
    //         menu.map(d => newMenu.push(parseInt(d)));

    //         commit("SET_ID", data.id);
    //         commit("SET_ROLES", newMenu);
    //         commit("SET_USERNAME", data.username);
    //         commit("SET_FULLNAME", data.fullname);
    //         resolve(data);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },

    LogOut({ commit }) {
      return new Promise(resolve => {
        commit("TOGGLE_FULL_LOADING");
        commit("SET_TOKEN", "");
        commit("SET_ROLES", "");
        commit("SET_USER", {});
        setTimeout(() => {
          router.replace({ path: "/homepage" });
          commit("TOGGLE_FULL_LOADING");
          resolve();
        }, 2000);
      });
    }

    // // Front end
    // FedLogOut({ commit }) {
    //   return new Promise(resolve => {
    //     commit("SET_TOKEN", "");
    //     commit("SET_ROLES", "");
    //     removeToken();
    //     router.push({ path: "/login", query: { redirect: "dashboard" } });
    //     resolve();
    //   });
    // }
  }
};

export default user;
