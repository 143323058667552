export default {
  table: {
    subject: "Mata Pelajaran",
    kkm: "KKM",
    homeroom: "Guru Pengampu"
  },
  to_detail: "Detail",
  error_update:
    "Gagal menyimpan nilai, silahkan ketik ulang nilainya dan tekan Enter",
  description: "Pilih menu untuk ditampilkan di tabel",
  input_exam: "Input manual",
  download_student: "List Nilai Mata Pelajaran",
  midterm_exam_exist: "Ujian tengah semester sudah ada",
  final_exam_exist: "Ujian akhir semester sudah ada",
  form: {
    title: "Judul",
    type: "Pilih Jenis",
    core_competencies: "Pilih Kompetensi Dasar"
  },
  remove: "Batal Posting",
  confirm:
    "Apakah anda yakin ingin membatalkan posting ulangan yang anda pilih?"
};
