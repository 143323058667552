export default {
  subtitle: "Join Us Soon",
  registration: "Registration",
  new_student: "New student",
  teacher_staff: "Teacher / Staff",
  profile: "Profile",
  purpose: "Purpose",
  vision: "Vision",
  mission: "Mission",
  calendar: "Calendar",
  today_activity: "Today's activity",
  announcement: "Announcement",
  announcement_sub: "Always update the latest announcements",
  news: "News",
  news_sub: "Always Update the Latest News About School",
  posted_on_date: "Posted on date ",
  no_description: "No description",
  read: "Read",
  view_more: "View More"
};
