<template>
  <v-list-group @click="sidebarMini">
    <template slot="prependIcon">
      <img v-if="icon" class="icon-sidebar" :src="iconPath(icon)" />
    </template>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>
          <strong>{{ title }}</strong>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <div v-if="!sidebarMiniFlag">
      <div v-for="(menu, i) in child" :key="i">
        <div v-if="!menu.hidden">
          <Item
            v-if="!menu.children"
            :sidebarMini="sidebarMini"
            :path="`${path}/${menu.path}`"
            :title="menu.meta.title"
            :class="!g_darkmode && 'list-bg-sidebar'"
          />
          <v-list-group v-else sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ menu.meta.title }}</strong>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <div v-for="(menuChild, i) in menu.children" :key="i">
              <Item
                v-if="!menuChild.hidden"
                :sidebarMini="sidebarMini"
                :path="`${path}/${menu.path}/${menuChild.path}`"
                :title="menuChild.meta.title"
                :icon="menuChild.meta.icon"
                :class="!g_darkmode && 'list-bg-sidebar'"
              />
            </div>
          </v-list-group>
        </div>
      </div>
    </div>
  </v-list-group>
</template>

<script>
import Item from "./Item";

export default {
  name: "SidebarItemGroup",
  components: {
    Item
  },
  props: {
    g_darkmode: Boolean,
    sidebarMini: {
      type: Function,
      required: true
    },
    sidebarMiniFlag: {
      type: Boolean,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    child: {
      type: Array,
      required: true
    },
    icon: String
  },
  methods: {
    iconPath(icon) {
      return require(`@/assets/svg/sidebar/${icon}`);
    }
  }
};
</script>
