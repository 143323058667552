export default {
  master_rating: "Master Peringkat",
  create_form: "Tambah Peringkat Nilai",
  update_form: "Ubah Peringkat Nilai",
  success_update: "Berhasil merubah Peringkat Nilai",
  success_create: "Berhasil menambah Peringkat Nilai",
  success_delete: "Berhasil menghapus Peringkat Nilai",
  delete_confirm: "Hapus Peringkat Nilai",
  form: {
    type: "Pilih tipe peringkat",
    min: "Dari Nilai",
    max: "Sampai Nilai",
    code: "Peringkat Nilai",
    alias: "Alias",
    description: "Deskripsi"
  },
  table: {
    rating: "Peringkat Nilai",
    score: "Nilai",
    description: "Deskripsi"
  }
};
