export default {
  dialog_email: "Apakah anda yakin ingin mengirim email?",
  select_position: "Pilih Jabatan",
  input_position: "Masukan Jabatan",
  add_mutation_student: "Tambah mutasi siswa",
  add_mutation_teacher: "Tambah mutasi guru",
  add_mutation_staff: "Tambah mutasi staff",
  mutation: "Mutasi",
  detail: "Detail",
  cancel_mutation: "apa anda yakin akan membatalkan mutasi data ini?",
  delete_mutation_confirm: "apa anda yakin akan menghapus mutasi data ini?",
  delete_mutation: "Hapus Mutasi",
  success_cancel_mutation: "Berhasil membatalkan mutasi",
  success_mutation: "Berhasil memutasi data",
  student_data: "Data Siswa",
  download_student_detail: "Unduh detail siswa",
  download_student_applicant_detail: "Unduh detail calon siswa",
  download_staff_applicant_detail: "Unduh detail calon staff",
  download_teacher_applicant_detail: "Unduh detail calon guru",
  permanent_teacher: "Guru Tetap",
  committee_honor: "Honor Komite",
  gtt_honor: "Honor GTT",
  foundation_permanent_teacher: "Guru Tetap Yayasan",
  position: {
    headmaster: "Kepala Sekolah",
    teacher: "Guru Nilai Sikap",
    treasurer: "Bendahara",
    counselor: "Counselor",
    add_position: "Tambah Jabatan",
    edit_position: "Edit Jabatan",
    table_position: "Jabatan",
    confirm_update_content:
      "Apakah anda yakin ingin mengubah jabatan dan menu akses-aksesnya?",
    response: {
      success_add_position: "Berhasil tambah jabatan",
      success_edit_position: "Berhasil ubah jabatan",
      success_delete_position: "Berhasil hapus jabatan"
    }
  },
  table: {
    name: "Nama",
    date: "Tanggal Keluar",
    description: "Keterangan",
    uid: "No. kartu",
    class: "Kelas",
    phone: "Telepon",
    email: "Email",
    guardian: "Wali Kelas",
    nis: "NIS",
    detail: "Detail",
    position: "Jabatan",
    registration_number: "Nomor Pendaftaran",
    date_register: "Mendaftar pada",
    received_on: "Diterima pada",
    reject_on: "Ditolak pada",
    send_email: "Kirim Email"
  },
  teacher: {
    tab1_title: "Guru",
    tab2_title: "Mutasi Keluar",
    tab3_title: "Calon Guru",
    dialog: {
      content_1: "Apakah Anda yakin menerima guru ini ?",
      content_2: "Apakah Anda yakin tidak menerima guru ini ?",
      content_3: "Apakah Anda yakin membatalkan status ini ?"
    },
    dialog_1: {
      title: "Tambah Guru",
      content_1: "Tambah Guru Secara Manual",
      content_2: "Unggah Data Guru Format Excel"
    },
    mutation: "Mutasi",
    change_type: "Ubah Jenis",
    response: {
      success_accept_teacher: "Berhasil terima guru",
      success_reject_teacher: "Guru telah ditolak",
      success_unprocessed: "Berhasil mengubah status menjadi belum diproses"
    }
  },
  staff: {
    tab1_title: "Staff",
    tab2_title: "Mutasi Keluar",
    tab3_title: "Calon Staff",
    input_status: "Masukkan Status",
    input_nip: "Masukkan NIP",
    select_status: "Pilih Status",
    select_nip: "Pilih NIP",
    dialog: {
      content_1: "Apakah Anda yakin menerima staff ini ?",
      content_2: "Apakah Anda yakin tidak menerima staff ini ?",
      content_3: "Apakah Anda yakin membatalkan status ini ?"
    },
    dialog_1: {
      title: "Tambah Staff",
      content_1: "Tambah Staff Secara Manual",
      content_2: "Unggah Data Staff Format Excel"
    },
    response: {
      success_accept_staff: "Berhasil terima staff",
      success_reject_staff: "Staff telah ditolak",
      success_unprocessed: "Berhasil mengubah status menjadi belum diproses"
    }
  },
  student: {
    change_password: "Ubah Sandi",
    next_year_required: "Tahun ajaran berikutnya belum ada.",
    isAccepted: "Data siswa diterima",
    isUnrejected: "Data siswa belum diproses",
    isRejected: "Data siswa tidak diterima",
    input_nis: "Masukkan NIS",
    school_year: "Tahun Ajaran",
    student: "Siswa",
    action: "Aksi",
    accept: "Terima",
    decline: "Tolak",
    unprocessed: "Belum Diproses",
    accepted: "Diterima",
    not_accepted: "Tidak Diterima",
    go_to_class: "Masuk Kelas",
    edit_student: "Edit Siswa",
    move_class: "Pindah Kelas",
    mutation: "Mutasi Siswa",
    upgrade: "Naik Kelas",
    graduate: "Siswa Lulus",
    downgrade: "Tinggal Kelas",
    tab1_title: "Siswa",
    cancel_mutation: "Batalkan mutasi",
    cancel_selected_mutation: "Batalkan mutasi terpilih",
    delete_selected_mutation: "Hapus mutasi terpilih",
    type: "Jenis",
    nomor: "No. Induk Siswa",
    profile_photo: "Foto Profil",
    value_indicator: "Indikator Nilai",
    average: "Rata-Rata",
    class_avarage: "Rata-Rata Kelas",
    last_point: "Nilai Terakhir",
    value_grafic: "Grafik Nilai",
    select_lesson: "Pilih Mata Pelajaran",
    my_avarage: "Rata-Rata Saya",
    no_class: "Kelas di tahun ajaran berikutnya belum dibuat",
    homeroom_teacher: "Wali Kelas",
    teaching_class: "Kelas Ajar",
    indicator_table: {
      very_good: "Sangat Baik",
      good: "Baik",
      average: "Rata-Rata",
      less: "Kurang",
      very_less: "Sangat Kurang"
    },
    cart: {
      student_average_value: "Nilai Rata-Rata Saya",
      student_average_class: "Nilai Rata-Rata Kelas",
      average_value: "Rata-Rata Nilai",
      best: "Terbaik",
      bad: "Kurang Baik",
      above_average: "Diatas Rata-Rata",
      below_average: "Dibawah Rata-Rata",
      student_value: "Nilai Saya",
      versus: "Rata-Rata Saya VS Rata-Rata Kelas"
    },
    tab1_data: {
      choose_class: "Pilih Kelas",
      name: "Nama",
      phone_student: "Telepon Siswa",
      guardian: "Wali",
      card_number: "No. Kartu"
    },
    tab2_title: "Mutasi Keluar",
    tab3_title: "Calon Siswa",
    tab3_data: {
      added_students: "Tambah Siswa"
    },
    dialog: {
      title_1: "Konfirmasi",
      content_1: "Apakah Anda yakin menerima siswa ini ?",
      content_2: "Apakah Anda yakin tidak menerima siswa ini ?",
      content_3: "Apakah Anda yakin membatalkan status ini ?"
    },
    dialog_1: {
      title: "Tambah Siswa",
      content_1: "Tambah Siswa Secara Manual",
      content_2: "Unggah Data Siswa Format Excel"
    },
    dialog_2: {
      title: "Konfirmasi Masuk Kelas"
    },
    response: {
      success_add_student: "Berhasil menambah siswa",
      success_edit_student: "Berhasil merubah data siswa",
      success_mutation: "Berhasil mutasi siswa",
      success_cancel_mutation: "Berhasil membatalkan mutasi siswa",
      success_delete_mutation: "Berhasil hapus mutasi",
      success_action: "Berhasil merubah data siswa",
      success_upload: "Berhasil unggah data siswa",
      success_accept_student: "Berhasil terima siswa",
      success_reject_student: "Siswa telah ditolak",
      success_unprocessed: "Berhasil mengubah status menjadi belum diproses",
      success_entry_class: "Siswa berhasil masuk kelas",
      done_upload_excel: "Selesai upload data dari Excel"
    },
    actions: {
      select_grade_from: "Dari tingkat",
      select_grade_to: "Ke tingkat",
      select_class_from: "Dari Kelas",
      select_class_to: "Ke Kelas"
    },
    upload_excel: {
      row: "Baris",
      name: "Nama",
      add_format_excel: "Tambah Format Excel",
      pob: "Tempat Lahir",
      dob: "Tanggal Lahir",
      upload_excel_file: "Unggah File Excel",
      upload_image: "Unggah Foto",
      error_mandatory: "Data mandatory belum diisi silahkan periksa file excel",
      error_response: "Unggah data gagal!",
      msg: "Alasan Gagal",
      column: "Kolom",
      error_max_data: "Unggah data maksimal 1000 siswa",
      filename: "Nama File"
    },
    note_major:
      "Catatan: (*) adalah jurusan prioritas yang paling diminati siswa",
    choose_major: "Pilih jurusan yang sesuai",
    filter_major: "Centang jika hanya mencari jurusan utama",
    select_major: "Pilih Jurusan",
    main_major: "Jurusan Utama",
    majors: "Jurusan",
    require_select_major: "Silahkan pilih jurusan terlebih dahulu"
  }
};
