export default {
  form: {
    title: "Judul",
    shown_for: "Ditujukan untuk",
    class: "Kelas"
  },
  filter: {
    newest: "Terbaru",
    oldest: "Terlama",
    most_viewed: "Paling Banyak Dilihat",
    most_liked: "Paling Banyak Disukai"
  },
  table: {
    date: "Tanggal",
    title: "Judul",
    shown_for: "Ditujukan untuk",
    content: "Isi",
    total: "Jumlah"
  }
};
