export default {
  weekly_report_detail: "Weekly Report Detail",
  submit_from_teacher: "Submit dari guru",
  input_reason_reject: "Input alasan kenapa ditolak atau tidak diterbitkan",
  name: "Nama",
  registration_number: "No. Induk",
  class: "Kelas",
  type: "Jenis",
  table: {
    name: "Name",
    approved: "Disetujui",
    shared: "Dibagikan",
    rejected: "Ditolak",
    deleted: "Dihapus",
    month: "Bulan",
    title: "Judul",
    status: "Status",
    received: "Diterima",
    canceled: "Dibatalkan",
    information: "Informasi"
  }
};
