export default {
  content: "Konten",
  copy_for_grade: "Salin e-learning dari tingkatan / kelas",
  copy_elearning: "Salin e-learning",
  add_elearning: "Tambah E-Learning",
  edit_elearning: "Ubah E-Learning",
  add_elearning_content: "Tambahkan Konten E-Learning",
  edit_elearning_content: "Ubah Konten E-Learning",
  subject_not_found: "Mata Pelajaran dikelas terpilih tidak ditemukan",
  content_not_found: "Materi di E-Learning terpilih tidak ditemukan",
  elearning_not_found: "E-Learning tidak ditemukan",
  title: "Judul",
  basic_competencies: "Kompetensi Dasar",
  select_elearning: "Pilih e-learning yang akan disalin",
  copy_content_from: "Salin konten dari",
  choose_file_type: "Pilih Tipe File",
  choose_file: "Choose File",
  document: "Dokumen",
  save_unpublised: "Simpan sebagai draf",
  save_published: "Simpan dan terbitkan",
  cancel_publish: "Batal Terbitkan",
  view_others: "E-learning lainnya",
  view_previous_comment: "Lihat komentar sebelumnya",
  comment: "Komentar",
  attention: "Perhatian",
  my_elearning: "Elearning Saya",
  attention_text_1:
    ": Jika tidak bisa memutar video di browser / ingin mendownload video, silahkan download video menggunakan icon ",
  attention_text_2: "di ujung kanan bawah di video.",
  like: {
    you: "Anda menyukai Konten ini",
    you_and: "Anda dan",
    others_people: "orang lainnya",
    and: "dan",
    others: "lainnya"
  },
  confirm: {
    publish: "Terbitkan E-Learning",
    publish_text: "Apakah anda yakin akan menerbitkan konten ini?",
    unpublish_text: "Apakah anda yakin akan membatalkan penerbitan konten ini?",
    publish_text_1:
      "Apakah anda yakin akan menerbitkan E-Learning dengan judul",
    publish_text_1_bulk:
      "Apakah anda yakin akan menerbitkan E-Learning terpilih?",
    publish_text_2:
      "Semua konten elearning tersebut akan berubah menjadi diterbitkan.",
    publish_text_3:
      "Jika ada konten tertentu yang belum siap diterbitkan, silahkan klik aksi->lihat kemudian di pilih yang belum siap diterbitkan",
    unpublish: "Batalkan Penerbitan E-Learning",
    unpublish_text_1:
      "Apakah anda yakin akan membatalkan penerbitan E-Learning dengan judul",
    unpublish_text_1_bulk:
      "Apakah anda yakin akan membatalkan penerbitan E-Learning terpilih",
    unpublish_text_2:
      "Semua konten elearning tersebut akan berubah menjadi belum diterbitkan",
    delete: "Hapus E-Learning",
    delete_text: "Apakah anda yakin akan menghapus E-Learning ini?"
  },
  table: {
    subject: "Mata Pelajaran",
    total: "Jumlah",
    created_at: "Tanggal Dibuat",
    title: "Judul",
    grade: "Tingkatan",
    class: "Nama Kelas",
    kd: "Kompetensi Dasar",
    status: "Status",
    description: "Deskripsi",
    file_type: "Tipe File",
    published: "Diterbitkan",
    unpublish: "Belum Diterbitkan"
  }
};
