export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function isImageFile(name) {
  return /\.(jpe?g|png|gif|bmp|webp|svg)$/i.test(name);
}

export function priceFormat(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts[0];
}

export function priceFormatFloat(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function isFormValid(text, type) {
  const phoneRegex = /^(\()?(\+62|62|0)(\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}$/;
  switch (type) {
    case "url":
      return [v => !v || /^(http|https):\/\/[^ "]+$/.test(v) || text];
    case "number":
      return [v => !v || /[0-9]$/.test(v) || text];
    case "email":
      return [v => !!v || text.a, v => /.+@.+\..+/.test(v) || text.b];
    case "emailnorequired":
      return [v => !v || /.+@.+\..+/.test(v) || text];
    case "niknumberrequired":
      return [
        v => !!v || text.a,
        v => (v.length === 16 && /[0-9]$/.test(v)) || text.b
      ];
    case "nisnnumberrequired":
      return [
        v => !!v || text.a,
        v => (v.length === 10 && /[0-9]$/.test(v)) || text.b
      ];
    case "phonenumberrequired":
      return [v => !!v || text.a, v => phoneRegex.test(v) || text.b];
    case "phonenumber":
      return [v => !v || phoneRegex.test(v) || text];
    case "gpa":
      return [v => !v || /^[0-9](\.[0-9]+)?$/.test(v) || text];
    case "upload":
      return [
        v => (v && v.length < 6) || text[0],
        v => {
          if (v) {
            const pattern = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
            const data = [];
            v.map(d => data.push(pattern.test(d.name)));
            return !data.includes(false) || text[1];
          } else {
            return true;
          }
        }
      ];
    default:
      return [value => !!value || text];
  }
}

export function isNumberOnly(evt) {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (
    charCode > 31 &&
    charCode !== 43 &&
    (charCode < 48 || charCode > 57) &&
    charCode !== 190
  ) {
    evt.preventDefault();
  } else {
    return true;
  }
}

export function isNumberFloat(evt) {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    charCode !== 46 &&
    charCode !== 190
  ) {
    evt.preventDefault();
  } else {
    return true;
  }
}

export function isURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
