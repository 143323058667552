export default {
  title: "Judul",
  subtitle: "Isi Slide",
  slider_upload: "Deskripsi",
  msg: {
    logo: "Logo harus diisi",
    profile: "Profile harus diisi",
    purpose: "Tujuan harus diisi",
    vision_img: "Visi Misi Gambar harus diisi",
    vision: "Visi harus diisi",
    mission: "Misi harus diisi",
    slider_img: "Gambar harus diisi"
  }
};
