import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import store from "@/store";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: store.getters.g_darkmode,
    themes: {
      light: {
        primary: "#27ace2",
        secondary: "#3E4295",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFA000"
      },
      dark: {
        primary: "#27ace2",
        secondary: "#3E4295",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFA000"
      }
    }
  }
});
