export default {
  assign_student: "Assign Student",
  new_member: "New Member",
  join: "Join",
  have_joined: "Have Joined",
  waiting_approval: "Waiting for Approval",
  total_student: "Total Student",
  join_extracurricular: "Join Extracurricular",
  description: "Description",
  reason_refusal: "Reason for Refusal",
  day: "Day",
  hours: "Hours",
  place: "Place",
  input_member: "Add Members",
  delete_member: "Delete Member",
  by_class: "By Class",
  filter: {
    new: "New",
    old: "Old",
    most_interest: "Most Interest",
    least_desirable: "Least Desirable"
  },
  msg: {
    member_request: "Request to join extracurricular been sent",
    accept_member: "Successfully accepted members",
    reject_member: "Successfully rejected members",
    input_member: "Successfully added members"
  },
  dialog: {
    confirm_request: "Are you sure you want to join?",
    confirm_decline: "Are you sure you reject this student?",
    hint_reject: "Write down the reasons why this student was rejected",
    master_extracurricular: "Master Extracurricular",
    new_extracurricular: "New Extracurricular Names",
    extracurricular_available: "Extracurricular Available",
    extracurricular_add: "Add Extracurricular",
    extracurricular_update: "Update Extracurricular",
    extracurricular_select: "Select Extracurricular",
    select_day: "Select Day",
    select_place: "Choose Place",
    select_start_time: "Start Time",
    select_end_time: "End Time",
    select_teacher: "Extracurricular Teacher",
    tuition: "Tuition",
    class: "Grade",
    add_new_schedule: "Add a new Schedule"
  }
};
