export default {
  rules: {
    address_rules: "Address is required",
    address_valid_rules: "Address must be valid",
    fullname_rules: "Fullname is required",
    gender_rules: "Gender is required",
    religion_rules: "Religion is required",
    pob_rules: "Place of Birth is required",
    nik_rules: "Resident Registration Number must be filled",
    nis_rules: "Student ID number is required",
    nis_valid_rules: "Student ID number must be valid",
    dob_rules: "Date of Birth is required",
    guardian_name_rules: "Guardian name is required",
    email_rules: "Email is required",
    email_valid_rules: "Email must be valid",
    phone_rules: "Phone is required",
    phone_valid_rules: "Phone must be valid",
    number_rules: "Input must be a number",
    strata_rules: "College is required",
    origin_of_the_institution_rules: "Origin of the Institution is required",
    accreditation_rules: "Accreditation is required",
    diploma_number_rules: "Diploma Number is required",
    company_name_rules: "Company Name is required",
    last_position_rules: "Last Position is required",
    year_of_entry_rules: "Year of Entry is required",
    year_of_entry_valid_rules: "Year of Entry must be valid",
    year_of_entry_greater_than_rules:
      "Entry year must not be more than the year of graduation",
    graduation_year_rules: "Graduation year is required",
    graduation_year_valid_rules: "Graduation year must be valid",
    graduation_year_less_than_rules:
      "The year of graduation must not be less than the year of entry",
    year_out_rules: "Year out is required",
    year_out_less_than_rules:
      "The year out cannot be less than the year entered",
    year_out_greater_than_rules: "Entry year cannot be more than exit year",
    school_name_rules: "School Name is required",
    gpa_value_rules: "GPA Value is required",
    gpa_value_valid_rules: "GPA Value must be valid",
    upload_documents_rules: "Upload Documents up to 5 files",
    upload_documents_type_rules: "Only upload .png .jpg .pdf files",
    nik_not_valid: "ID Number must be valid",
    nik_not_valid_length: "NIK must be 16 characters",
    family_card_number_not_valid: "Family Card Number must be valid",
    major_primary_rules: "Major First is required"
  },
  personal: {
    pob: "Place of Birth",
    dob: "Date of Birth",
    nationality: "Nationality",
    last_education: "Last Education",
    profession: "Profession",
    monthly_income: "Monthly Income",
    phone_number: "Phone Number",
    email: "Email",
    relation: "Relationship with Children",
    religion: "Religion",
    others_information: "Others Information",
    list_gender: [
      {
        id: "L",
        name: "Female"
      },
      {
        id: "P",
        name: "Male"
      }
    ]
  },
  address: {
    address: "Address",
    province: "Province",
    city: "City",
    sub_district: "Subdistrict",
    village: "Village",
    rt: "RT",
    rw: "RW",
    postal_code: "Postal Code",
    child_to: "Child number",
    telephone: "Home Telephone",
    number_of_siblings: "Number of Siblings",
    live_with: "Live with",
    country: "Country"
  },
  student: {
    title: "New Student Registration",
    tab1: "Student",
    tab2: "Parents",
    tab3: "Guardian",
    tab4: "Statement",
    personal_data_sub: "Personal Data",
    add_manual: "Add Student Manual",
    add_students_excel: "Add Students with Excel",
    personal_data: {
      fullname: "Fullname",
      nickname: "Nickname",
      gender: "Gender",
      language: "Daily Language",
      nik: "ID Number",
      family_card_number: "Family Card Number"
    },
    major_sub: "Major",
    choose_major: "Choose Major",
    preferred_major: "Preferred Majors",
    optional_major: "Optional Majors",
    notes_major: {
      notes: "Notes :",
      first: "Make sure the major you choose matches your interests.",
      two:
        "Optional majors choose a maximum of 2, as an option if you are not accepted in your main major."
    },
    address_sub: "Student Address",
    family_data_sub: "Family Data",
    family_data: {
      child_to: "Child number",
      child_name: "Child Name",
      semester: "School / Work at",
      school: "School Name / Company Name",
      add: "Add"
    },
    health_data_sub: "Health Data",
    health_data: {
      weight: "Weight (kg)",
      height: "Height (cm)",
      blood: "Blood",
      medical_history: "Medical History",
      distance: "Home Distance from School",
      health_record: "Health Record"
    },
    previous_education_sub: "Previous Education",
    previous_education: {
      school_origin: "School Origin",
      national_school_principal: "National School Principal Number",
      school_address: "School Address",
      national_school_parent: "National School Parent Number",
      year_graduated: "Year Graduate (Previous Level)",
      diploma_number: "Diploma Number (Previous Level)"
    }
  },
  parents: {
    fathers_sub: "Father's Data",
    fathers_address: "Father's Address",
    mothers_sub: "Mother's Data",
    mothers_address: "Mother's Address",
    copy_address: "Staying at home with Father",
    copy_address_student: "Copy from student address",
    personal: {
      fathers_name: "Father's name",
      mothers_name: "Mothers's name"
    }
  },
  guardian: {
    question1: "Do your parents also play a role as your guardian?",
    question2: "Who has the role of your guardian",
    father: "Father",
    mother: "Mother",
    guardian_data: "Guardian Data",
    guardian_address: "Guardian Address",
    personal: {
      name: "Guardian name"
    }
  },
  statement: {
    title: "STATEMENT LETTER",
    section1: "Named and signed below:",
    section2:
      "We as parents / guardians declare with all our heart a willingness for :",
    section3:
      "We have read, understood and agreed to the contents of the LETTER STATEMENT this, thus we sign with all our hearts, without coercion from any party.",
    child_name: "Child Name"
  },
  teacher: {
    title: "New Teacher Registration",
    switch: "Staff",
    tab1: "Teacher",
    tab2: "Educational History",
    teacher_data_sub: "Teacher Data",
    staff_data_sub: "Staff Data",
    add_manual: "Add Teacher Manual",
    edit_manual: "Edit Teacher Manual",
    add_teachers_excel: "Add Teacher with Excel",
    add_manual_staff: "Add Staff Manual",
    add_staff_excel: "Add Teacher with Excel",
    edit_manual_staff: "Edit Data Staff",
    edit_staff_excel: "Edit Teacher with Excel",
    teacher_data: {
      fullname: "Fullname",
      gender: "Gender"
    },
    address_sub: "Teacher Address",
    address_staff: "Staff Address",
    snackbar_msg_teacher: "You Choose Teachers",
    snackbar_msg_staff: "You Choose Staff",
    info_switch: "Make sure you choose the Teacher or Staff type"
  },
  educational_history: {
    school_name: "School Name",
    year_of_entry: "Year of Entry",
    year_out: "Year Out",
    year_out_hint: "Leave blank if it's still working",
    graduation_year: "Graduation Year",
    graduation_year_hint: "Leave blank if you haven't finished the level",
    upload_document: "Upload Document",
    upload_certificate: "Upload Certificate",
    elementary_school_level_sub: "Elementary School Level",
    junior_high_school_level_sub: "Junior High School Level",
    senior_high_school_level_sub: "Senior High School Level",
    strata_level_sub: "College Level",
    strata_level_data: {
      select_strata_level: "Select College Level",
      origin_of_the_institutions: "Origin of Educational Institutions",
      accreditation: "Accreditation",
      diploma_number: "Diploma Number",
      gpa_value: "GPA Value",
      add: "Add",
      list_accreditation: [
        {
          id: "A",
          name: "A"
        },
        {
          id: "B",
          name: "B"
        },
        {
          id: "C",
          name: "C"
        }
      ]
    },
    work_experience_sub: "Work Experience",
    work_experience_data: {
      company_name: "Company Name",
      last_position: "Last Position",
      year_out: "Year Out",
      tell_me_about_yourself: "Tell me about yourself",
      fresh_graduate: "I have work experience"
    }
  },
  register_done: {
    registration_complete: "Registration is Complete",
    thanks: "Thank You for Registering",
    please_check: "Please check your email ",
    to_see: "to see",
    username_password: "username and password or",
    please_note: "please note the following username and password:",
    please_contact: "Please contact the school admin, if you forget",
    not_received_email: "Not received Email",
    resend_email: "Resend Email"
  }
};
