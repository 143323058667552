import axios from "axios";
import store from "@/store";
import i18n from "@/i18n";
import { getToken } from "@/utils/storage";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api of base_url
  timeout: 10000000 // 60000
});

// request interceptor
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    config.headers["X-Lang"] = store.getters.g_language;
    // config.headers["X-Institution"] = "00000001";
    // config.headers["X-Institution"] = window.id_school;
    config.headers["X-Institution"] = store.getters.g_version.institution_id;
    // window.id_school;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const e = error;
    console.log(e);
    if (e && e.response) {
      const r = e.response;
      if (r.data.error === "Unauthorized") {
        store.dispatch("LogOut");
        store.commit("CALL_SNACKBAR", {
          msg: i18n.t("app.unauthorized"),
          color: "error",
          timeout: "12000"
        });
      } else {
        store.commit("CALL_SNACKBAR", {
          msg: `${r.data.message}`,
          color: "error"
        });
      }
      return Promise.reject(e);
    } else {
      let msg = e;
      if (e.code == "ECONNABORTED") {
        msg = i18n.t("app.timeout");
      } else {
        msg = i18n.t("app.no_message_error");
      }

      store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: "error"
      });
    }
  }
);

export default service;
