export default {
  title_update: "Update Nomor Kartu",
  uid: "Nomor Kartu",
  input_card_number: "Input Nomor Kartu",
  old_uid: "Nomor Kartu Lama",
  edit_uid: "Edit Nomor Kartu",
  uid_already_registered: "Nomor Kartu Sudah Terdaftar",
  all_type: "Semua Jenis",
  table: {
    nis: "NIS",
    nip: "NIP",
    name: "Nama",
    class: "Kelas",
    uid: "Nomor Kartu",
    action: "Aksi"
  },
  msg: {
    confirm_uid: {
      first: "sudah digunakan oleh",
      second: "Apakah anda yakin tetap ingin mengganti nomor kartu",
      three: "menjadi nomornya",
      four: "sekarang?",
      five: "Jika ya, maka anda harus mengisi ulang nomor kartu",
      six: "juga."
    },
    note:
      "Catatan: Dengan Mengubah Nomor Kartu, Nomor Kartu Pengguna Sebelumnya Akan Dihapus"
  }
};
