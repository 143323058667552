export default {
  msg: {
    success: {
      document: {
        add: "Berhasil tambah Dokumen",
        update: "Berhasil ubah Dokumen"
      },
      silabus: {
        add: "Berhasil tambah Silabus",
        update: "Berhasil ubah Silabus"
      },
      rpp: {
        add: "Berhasil tambah RPP",
        update: "Berhasil ubah RPP"
      }
    },
    error: {
      rule_upload: "Format file tidak valid"
    }
  },
  file_supported: "File yang didukung",
  dialog_confirm_title: "Konfirmasi File",
  dialog_confirm_info:
    "Kelas di bawah sudah tersedia file, apakah anda yakin ingin menimpa file berikut?"
};
