import {
  getListNotification,
  getListNotificationCount
} from "@/api/notification.js";

const notification = {
  state: {
    notificationInformation: [],
    notificationPromo: [],
    notificationCount: 0,
    notificationInformationCount: 0,
    notificationPromoCount: 0,
    notificationCheckpoint: false,
    notificationQuery: {
      last_page: 0,
      page: 1,
      limit: 5
    }
  },
  mutations: {
    CHECKPOINT_NOTIF: (state, value) => {
      state.notificationCheckpoint = value;
    },
    DECREMENT_NOTIFICATION: (state, category) => {
      switch (category) {
        case "information":
          state.notificationInformationCount =
            state.notificationInformationCount - 1;
          break;
        default:
          state.notificationPromoCount = state.notificationPromoCount - 1;
          break;
      }
      state.notificationCount = state.notificationCount - 1;
    },
    SET_NOTIFICATION: (state, data) => {
      let notif = {
        id: data.id,
        title: data.title,
        description: data.description,
        thumbnail: data.thumbnail,
        category: data.category,
        created_at: data.created_at,
        unread: data.fromApi ? data.unread : true,
        payload: data.payload,
        notif_participant_id: data.notif_participant_id
      };

      if (data.fromApi) {
        switch (data.category) {
          case "news":
            state.notificationInformation.push({
              ...notif,
              icon: "mdi-newspaper",
              color: "gradient-primary"
            });
            break;
          case "announcement":
            state.notificationInformation.push({
              ...notif,
              icon: "mdi-clipboard-text",
              color: "gradient-primary-dark"
            });
            break;
          case "elearning":
            state.notificationInformation.push({
              ...notif,
              icon: "mdi-book-open",
              color: "primary"
            });
            break;
          case "promo":
            state.notificationPromo.push(notif);
            break;
          default:
            state.notificationInformation.push({
              ...notif,
              icon: "mdi-bell",
              color: "warning"
            });
            break;
        }
      } else {
        switch (data.category) {
          case "news":
            state.notificationInformation.unshift({
              ...notif,
              icon: "mdi-newspaper",
              color: "gradient-primary"
            });
            break;
          case "announcement":
            state.notificationInformation.unshift({
              ...notif,
              icon: "mdi-clipboard-text",
              color: "gradient-primary-dark"
            });
            break;
          case "elearning":
            state.notificationInformation.unshift({
              ...notif,
              icon: "mdi-book-open",
              color: "primary"
            });
            break;
          case "promo":
            state.notificationPromo.unshift(notif);
            break;
          default:
            state.notificationInformation.unshift({
              ...notif,
              icon: "mdi-bell",
              color: "warning"
            });
            break;
        }
      }
    },
    SET_NOTIFICATION_COUNT: (state, data) => {
      if (data.init) {
        state.notificationInformationCount = data.data.general;
        state.notificationPromoCount = data.data.promo;
      } else {
        switch (data.category) {
          case "announcement":
          case "news":
          case "elearning":
          case "conseling":
          case "permission":
          case "etest":
            state.notificationInformationCount += 1;
            break;
          case "promo":
            state.notificationPromoCount += 1;
            break;

          default:
            break;
        }
      }

      state.notificationCount =
        state.notificationInformationCount + state.notificationPromoCount;
    }
  },
  actions: {
    GetListNotification({ commit, state }, loadmore) {
      if (!state.notificationCheckpoint) {
        return new Promise(resolve => {
          if (loadmore) state.notificationQuery.page += 1;

          getListNotification(state.notificationQuery).then(res => {
            if (res.data.code) {
              const { data, last_page, current_page, per_page } = res.data.data;
              state.notificationQuery = {
                last_page: last_page,
                page: current_page,
                limit: per_page
              };

              if (data.length > 0) {
                const setData = JSON.parse(JSON.stringify(data));
                setData.map(r => {
                  r.fromApi = true;
                  commit("SET_NOTIFICATION", r);
                });
              }
            }
            resolve();
          });
        });
      }
    },
    getListNotificationCount({ commit, state }) {
      if (!state.notificationCheckpoint) {
        return new Promise(resolve => {
          getListNotificationCount().then(res => {
            if (res.data.code) {
              const { data } = res.data;
              commit("SET_NOTIFICATION_COUNT", { data: data, init: true });
            }
            resolve();
          });
        });
      }
    }
  }
};

export default notification;
