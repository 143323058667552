export default {
  absent_type: "Jenis Absen",
  clock: "Jam",
  added_manual: "Tambah Absen Manual",
  delete_manual: "Hapus Absen Manual",
  total_students: "Jumlah Siswa",
  time_attendance: "Waktu Absen",
  not_yet_absent: "Belum Absen / Tidak Masuk",
  freeday: "Libur",
  absent_in: "Absen Masuk",
  manual_absent: "Absen Manual",
  uid_absent: "Absen UID",
  absent_out: "Absen Pulang",
  hour_of_entry: "Jam Masuk",
  hour_out: "Jam Pulang",
  absent_report: "Laporan Absensi",
  absent_student: "Absensi Siswa",
  absent_teacher: "Absensi Guru",
  absent_staff: "Absensi Staff",
  added_absent: "Tambah Absen",
  remove_absent: "Hapus Absen",
  search_valid_rules: "Kelas harus diisi",
  type_absent: "Jenis Absen",
  late: "Terlambat",
  student: "Siswa",
  delete_absent_in: "Hapus Absen Masuk",
  delete_absent_out: "Hapus Absen Pulang",
  please_add_absent_present: "Anda belum absen masuk",
  teacher: "Guru",
  staff: "Staff",
  select_teacher: "Pilih Guru",
  confirm_delete: "Apakah anda yakin ingin menghapus absen?",
  absent_per_day: "Absen Per Hari",
  absent_per_subjects: "Absen Per Mapel",
  absent_date: "Tanggal Absen",
  approved: "Disetujui",
  unapproved: "Tidak Disetujui",
  approved_absent: "Setujui Absen",
  unapproved_absent: "Tidak Setujui Absen",
  teacher_me: "Guru (Saya)",
  absent_has_not_been_approved: "Absen belum disetujui",
  msg: {
    error: {
      room_code_invalid: "Kelas ini belum pernah mengadakan video conference"
    },
    success: {
      refresh_absent: "Berhasil Refresh Absen",
      update_nis: "Berhasil Edit NIS",
      approved_absent: "Berhasil Menyetujui Absen",
      unapproved_absent: "Berhasil Tidak Menyetujui Absen"
    },
    required_set_hours:
      "Silahkan atur jam masuk dulu di menu Master -> Atur Jam",
    unapproved_absent: "Apakah anda yakin tidak menyetujui absen ini?",
    approved_absent: "Apakah anda yakin menyetujui absen ini?"
  },
  month: [
    {
      id: "1",
      value: "Januari"
    },
    {
      id: "2",
      value: "Februari"
    },
    {
      id: "3",
      value: "Maret"
    },
    {
      id: "4",
      value: "April"
    },
    {
      id: "5",
      value: "Mei"
    },
    {
      id: "6",
      value: "Juni"
    },
    {
      id: "7",
      value: "Juli"
    },
    {
      id: "8",
      value: "Agustus"
    },
    {
      id: "9",
      value: "September"
    },
    {
      id: "10",
      value: "Oktober"
    },
    {
      id: "11",
      value: "November"
    },
    {
      id: "12",
      value: "Desember"
    }
  ],
  response: {
    success: "Berhasil tambah absen",
    success_edit: "Berhasil ubah absen",
    success_delete: "Berhasil hapus absen",
    failed: "Gagal tambah absen",
    failed_delete: "Berhasil hapus absen",
    failed_approve: "Gagal menyetujui absen"
  },
  table: {
    action: "Aksi",
    uid: "Nomor Kartu"
  }
};
