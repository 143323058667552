export default {
  table: {
    class: "Class",
    nip_homeroom_teacher: "Homeroom ID",
    name_homeroom_teacher: "Homeroom",
    nip_conselor: "Conselor ID",
    name_conselor: "Conselor",
    total_student: "Total Student",
    action: "Action"
  },
  delete_force: "Delete class ",
  confirm_password: "Enter your password to confirm deleting this class",
  master_class: "Master Class",
  delete: "Delete",
  edit: "Edit",
  add: "Add",
  select_grade: "Select Grade",
  class_name: "Class Name",
  school_year: "School Year",
  name_file: "Class List.xls",
  choose_class: "Choose Class",
  choose_guardian: "Choose Guardian",
  choose_conselor: "Choose Conselor",
  others: "Others",
  response: {
    success: "Successful add Class",
    success_edit: "Successful edit Class",
    success_delete: "Successful delete Class"
  },
  excel: {
    class: "Class",
    nip_homeroom: "Homeroom ID",
    name_homeroom: "Name Homeroom",
    nip_conselor: "Conselor ID",
    name_conselor: "Name Conselor",
    total_student: "Total Student"
  }
};
