export default {
  title: "Select School Location",
  title2: "Add School Year",
  title3: "Edit School Year",
  school_address: "School Address",
  select_active_days: "Select Active Days",
  select_school_year: "Select School Year",
  semester: "Semester",
  start_semester: "Start of semester",
  end_semester: "End of Semester",
  start_hour: "Select Start Hour",
  end_hour: "Select End Hour",
  add_event: "Add Event or Holiday",
  edit_event: "Edit Event or Holiday",
  select_one: "Select One",
  school_year_list: "School Year List",
  information: "Information",
  select_one_type: "Select type of event",
  confirm_delete_not_lastid:
    "Please delete the school year according to the latest order",
  response: {
    success: "Successful add school year",
    success_edit: "Successful edit school year",
    success_delete: "Successful delete school year",
    success_edit_event: "Successful edit event",
    success_add_event: "Successful add event",
    success_delete_event: "Successful delete event"
  },
  header: {
    school_year: "School Year",
    select_class: "Select Grade",
    class_type: "Class Type",
    select_month: "Select Month",
    select_elearning: "Select Elearning",
    select_type: "Select File Type"
  },
  event: {
    date_from: "Date From",
    date_to: "Date To",
    add_holiday: "Add Holiday",
    add_event: "Add Event",
    random_color: "Random Color",
    select_color: "Select Color",
    select_grade: "Select Grade",
    class_type: "Class Type",
    no_event: "There are no events on the selected date"
  },
  table_header: {
    start_year: "Early Year",
    end_year: "Final Year",
    semester: "Semester",
    start_date: "Start",
    end_date: "Finish",
    action: "Action"
  },
  upcoming_school_year: "Upcoming School Year",
  current_school_year: "Current School Year"
};
