import request from "@/utils/request";

export function getListAdminChat(data) {
  return request({
    method: "post",
    url: "admin/list",
    data: data
  });
}

export function getListEmployeeChat(data) {
  return request({
    method: "post",
    url: "employee/simple_list",
    data: data
  });
}

export function getListStudentChat(data) {
  return request({
    method: "post",
    url: "class/list_detail_with_students",
    data: data
  });
}

export function getPersonalList(data) {
  return request({
    method: "post",
    url: "chat_personal/personal_list",
    data: {
      ...data,
      order: {
        id: "desc"
      }
    }
  });
}

export function sendPersonal(data) {
  return request({
    method: "post",
    url: "chat_personal_new/send",
    data: data
  });
}

export function sendClassGroup(data) {
  return request({
    method: "post",
    url: "chat_blast_new/class",
    data: data
  });
}

export function sendTeacherGroup(data) {
  return request({
    method: "post",
    url: "chat_blast_new/teachers",
    data: data
  });
}
