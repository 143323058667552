export default {
  schedule_entry_per_level: "Jadwal Masuk per Tingkatan",
  enter_school_schedule: "Jadwal Masuk Sekolah",
  entry_per_class_schedule: "Jadwal Masuk per Kelas",
  general: "Umum",
  tiers: "Tingkatan",
  all_grade: "Semua Tingkat",
  class: "Kelas",
  day_in: "Hari Masuk",
  day_off: "Hari Libur",
  save: "Simpan Jadwal",
  active: "Aktif",
  no_active: "Tidak Aktif",
  for_student: "<b>seluruh tingkatan dan kelas</b>",
  for_staff: "<b>seluruh staff</b>",
  for_teacher: "<b>seluruh guru</b>",
  confirm_update_1: "Apakah anda yakin akan mengatur jadwal untuk {typeAbsent}",
  confirm_update_2:
    "Apakah anda yakin akan mengatur jadwal untuk seluruh tingkatan",
  confirm_update_3: "apakah anda yakin akan mengatur jadwal untuk kelas",
  confirm_update_4: "Apakah anda yakin akan mengatur jadwal untuk hari",
  response: {
    success: "Berhasil tambah absen",
    success_edit: "Berhasil ubah jadwal",
    success_delete: "Berhasil hapus absen"
  },
  table: {
    class: "Kelas",
    day: "Hari",
    lesson_hours: "Jam Pelajaran",
    action: "Aksi",
    class_type: "Jenis Kelas",
    status: "Status",
    in: "Masuk",
    out: "Pulang",
    class_name: "Nama Kelas",
    time_off_attendance: "Absen Masuk",
    time_away_home: "Absen Pulang"
  }
};
